import { Spacer } from '@chakra-ui/react';
import { Image, Loader } from '@mantine/core';
import { Divider } from '@mui/material';
import image from 'assets/images/home-images/img-2.png';
import LOCALSTORAGE_KEYS from 'constants/localStorage';
import QUERY_KEYS from 'network/config/queryKeys';
import { clearCart, deleteOrder } from 'network/services/goods';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { BiTrash } from 'react-icons/bi';
import { IoArrowBackCircle } from 'react-icons/io5';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { userIsLoggedIn, userRoleAtom } from 'store/atoms/userAtom';
import { formatCurrency } from 'utils/currencyFormater';
import { CartIcon } from '../../../assets/Icons';
import { CHECKOUT } from '../../../navigation/routes';
import { isCartOpen } from '../../../store/atoms/cartAtom';
import { cartTotalAmount } from '../../../store/selectors/cartSelector';
import { EmptyCon } from '../../atoms';
import { Modal } from '../Modal/Modal';
import { SideModalContentStyle, SideModalStyle } from '../Modal/style';
import CartOrders from './CartOrders';
import { CartProduct } from './CartProduct';
import { NewUseUpdatedCart } from './hooks';
import { CartCon, CheckoutBottom, SubTotal } from './style';

const initial = {
  x: '100%',
  height: 'auto'
};
const animate = {
  x: '0'
};

export const Cart = () => {
  const setIsModalOpen = useSetRecoilState(isCartOpen);
  const [selectedVendorId, setSelectedVendorId] = useState<number | null>(null);
  const [deletingOrderId, setDeletingOrderId] = useState<number | null>(null); // State to track which order is being deleted
  const isLogged = useRecoilValue(userIsLoggedIn);
  const serverCart = NewUseUpdatedCart();
  const userRole = useRecoilValue(userRoleAtom);
  const cartData = serverCart?.data;
  const cartOrder = cartData?.orders?.find(order => order?.vendorId === selectedVendorId);
  const subTotal = cartOrder?.totalAmount || 0;
  const totals = useRecoilValue(cartTotalAmount);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const currentOrder = cartData?.orders?.find(order => order?.vendorId === selectedVendorId);

  useEffect(() => {
    if (!currentOrder) {
      setSelectedVendorId(null);
    }
  }, [currentOrder]); // React to changes in currentOrder

  const mutation = useMutation({
    mutationFn: clearCart,
    onSuccess: () => {
      enqueueSnackbar('Cart Cleared Successfully', { variant: 'success' });
      queryClient.invalidateQueries(QUERY_KEYS.CART);
    },
    onError: () => {
      enqueueSnackbar('An Error Occurred', { variant: 'error' });
    }
  });

  const { mutateAsync: removeOrder, isLoading: deletingOrder } = useMutation<
    any,
    unknown,
    { userRole: string; orderId: number }
  >({
    mutationFn: ({ userRole, orderId }) => deleteOrder(userRole, orderId),
    onMutate: ({ orderId }) => {
      setDeletingOrderId(orderId); // Set the order ID that is being deleted
    },
    onSuccess: () => {
      setDeletingOrderId(null); // Reset the deleting order ID after success
      queryClient.invalidateQueries(QUERY_KEYS.CART);
      // If no products remain, return to the list of orders by setting selectedVendorId to null
      setSelectedVendorId(null);
      enqueueSnackbar('Order removed successfully', { variant: 'success' });
    },
    onError: err => {
      setDeletingOrderId(null); // Reset the deleting order ID on error
      enqueueSnackbar('Failed to remove order', { variant: 'error' });
    }
  });

  const gotoCheckout = () => {
    setIsModalOpen({ onCartOpen: false });
    navigate(`${CHECKOUT}/${selectedVendorId}`);
  };

  const handleDelete = () => {
    localStorage.removeItem(LOCALSTORAGE_KEYS.CART);
  };

  const handleCheckout = (orderId: number) => {
    setSelectedVendorId(orderId);
  };

  const handleBack = () => {
    setSelectedVendorId(null);
  };

  // Check if any product's quantity is lower than the minimumOrderQuantity
  const isCheckoutDisabled =
    userRole === 'BUSINESS' && currentOrder?.products?.some(product => product.quantity < product.minimumOrderQuantity);

  return (
    <Modal
      onModalClose={() => {
        setIsModalOpen({ onCartOpen: false });
      }}
      extraModalStyle={SideModalStyle}
      extraModalContentStyle={SideModalContentStyle}
      initial={initial}
      animate={animate}>
      <CartCon>
        {selectedVendorId === null && !currentOrder ? (
          <h1 className="text-xl font-semibold mb-3">My Orders</h1>
        ) : (
          <>
            {currentOrder && (
              <>
                <div className="flex gap-3 items-center mb-5">
                  <IoArrowBackCircle className="text-3xl text-secondary cursor-pointer" onClick={handleBack} />
                  <h2 className="text-xl text-semibold text-[#444444]">Order Details</h2>
                </div>
                <div className="flex gap-3 items-center">
                  <div className="relative w-10 h-10 rounded-full border overflow-hidden">
                    <Image
                      src={cartOrder?.vendorLogo || image}
                      alt="Vendor Logo"
                      className="object-cover w-full h-full"
                      onError={e => {
                        e.currentTarget.src = image;
                      }}
                    />
                  </div>

                  <div className="grid items-start">
                    <h2 className="text-[#1E223E] text-left text-lg font-medium">{cartOrder?.vendorName}</h2>
                    <h5 className="flex gap-2 items-center text-[#616161] text-sm">
                      Order {cartOrder?.orderRef} <span className="text-2xl">•</span> {cartOrder?.totalItems} item(s)
                    </h5>
                  </div>
                </div>
              </>
            )}
          </>
        )}
        {cartData?.orders?.length !== 0 ? (
          <>
            {selectedVendorId === null ? (
              <div className="border  border-[#D3D3D3] mb-4 rounded-[10px] md:rounded-[20px] px-6 pb-4">
                {cartData?.orders?.map((order: any) => (
                  <div key={order?.id}>
                    <CartOrders
                      vendorName={order?.vendorName}
                      vendorLogo={order?.vendorLogo}
                      orderRef={order?.orderRef}
                      totalAmount={order?.totalAmount}
                      handleCheckout={() => handleCheckout(order?.vendorId)}
                      orderId={order?.id}
                      deleteOrder={() => {
                        removeOrder({ userRole, orderId: order.id });
                      }}
                      totalItems={order?.totalItems}
                      deletingOrder={deletingOrderId === order.id} // Check if this order is the one being deleted
                    />
                  </div>
                ))}
                <CheckoutBottom>
                  <button
                    className="text-red-600 py-3 gap-3 bg-red-100 w-full rounded-md"
                    onClick={() => {
                      if (!isLogged) {
                        handleDelete();
                      } else {
                        mutation.mutate();
                      }
                    }}>
                    {mutation.isLoading ? <Loader color="white" size="sm" /> : 'CLEAR CART'}
                  </button>
                </CheckoutBottom>
              </div>
            ) : (
              <>
                {currentOrder && (
                  <div className="h-full relative">
                    {cartData?.orders
                      ?.find(order => order?.vendorId === selectedVendorId)
                      ?.products?.map((product, index) => (
                        <div
                          key={product?.productId}
                          className="flex justify-between py-4 border-b-[0.2px] border-[#D3D3D3]">
                          <CartProduct
                            item={product}
                            index={index}
                            vendorId={selectedVendorId}
                            orderId={cartData?.orders?.find(order => order?.vendorId === selectedVendorId)?.id || 0}
                            customerType={userRole}
                          />
                        </div>
                      ))}

                    <CheckoutBottom>
                      <Divider />
                      <SubTotal>
                        <h2>Subtotal</h2>
                        <h4>{formatCurrency(subTotal)}</h4>
                      </SubTotal>
                      <Divider />
                      <Spacer height={50} />
                      <div className="flex w-full items-center gap-2">
                        <button
                          className={`text-white py-4  w-full rounded-md ${
                            isCheckoutDisabled ? 'bg-secondary/50' : 'bg-secondary'
                          } `}
                          onClick={gotoCheckout}
                          disabled={isCheckoutDisabled} // Disable the button if any product's quantity is lower than the minimumOrderQuantity
                        >
                          CHECKOUT
                        </button>
                        <button
                          className="text-red-600 bg-red-100 h-full aspect-square p-5 shadow-sm rounded-md"
                          onClick={() => {
                            if (currentOrder !== undefined) {
                              removeOrder({ userRole, orderId: currentOrder.id });
                            }
                          }}>
                          {deletingOrder ? <Loader color="white" size="sm" /> : <BiTrash />}
                        </button>
                      </div>
                    </CheckoutBottom>
                  </div>
                )}
              </>
            )}
          </>
        ) : (
          <EmptyCon>
            <CartIcon />
            No Orders in Cart
          </EmptyCon>
        )}
      </CartCon>
    </Modal>
  );
};
