import { useGoogleLogin } from '@react-oauth/google';
import { AuthSuccess } from 'TYPES/api.body';
import { HidePassword, ShowPassword } from 'assets/Icons';
import { Button, Spacer } from 'components/atoms';
import { InputField } from 'components/atoms/InputField/InputField';
import SEARCH_PARAMS from 'constants/searchParams';
import { FORGOT_PASSWORD, SIGN_UP } from 'navigation/routes';
import { useMutation } from 'network/mutations/useMutationHook';
import { googleLoginCallback, login, requestOtp } from 'network/services/auth';
import { enqueueSnackbar } from 'notistack';
import { ChangeEvent, useState } from 'react';
import { FcGoogle } from 'react-icons/fc';
import { SiFacebook } from 'react-icons/si';
import 'react-phone-input-2/lib/style.css';
import { useMutation as rUseMutation } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LoginSocialFacebook } from 'reactjs-social-login';
import { useSetRecoilState } from 'recoil';
import { userIsLoggedIn, userRoleAtom } from 'store/atoms/userAtom';
import { validators } from 'utils/validator';
import { AuthContainer, InputCon, TogglePassword, extraStylesInput } from './styles';
const Login = () => {
  const [searchParams] = useSearchParams();
  const setUserRole = useSetRecoilState(userRoleAtom);
  const setUserStatus = useSetRecoilState(userIsLoggedIn);
  const [formData, setFormData] = useState({ identifier: '', password: '' });
  const [formError, setFormError] = useState({
    identifier: '',
    password: ''
  });
  const { mutateAsync: googleLoginMutate } = rUseMutation({
    mutationFn: googleLoginCallback
  });
  const googleLogin = useGoogleLogin({
    onSuccess: res => {
      googleLoginMutate(res.access_token)
        .then(res => {
          if (res.data.status === 'success') {
            handleAuthSuccess(res.data);
          }
        })
        .catch(error => {
          console.log(error);
          enqueueSnackbar('Google Auth Failed', { variant: 'error' });
        });
    }
  });

  const handleAuthSuccess = (data: AuthSuccess) => {
    sessionStorage.setItem('access_token', data.access_token);
    sessionStorage.setItem('refresh_token', data.refresh_token);
    sessionStorage.setItem('is_login', 'true');
    sessionStorage.setItem('newuser', String(data.data.referredCustomerAndNoPurchase));
    setUserRole(data?.data.customer_type);
    setUserStatus(true);
    const reroute = searchParams.get(SEARCH_PARAMS.LOGIN_SUCCESS_REROUTE);

    if (reroute !== null) {
      navigate(`${reroute}?refresh=True`);
    } else {
      navigate('/');
    }
  };

  const onUpdateFormData = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    const error = validators[name](value);
    setFormData(formData => ({ ...formData, [name]: value }));
    setFormError({
      ...formError,
      [name]: error
    });
  };

  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState<'text' | 'password'>('password');

  const onTogglePassword = () => {
    if (showPassword === 'text') return setShowPassword('password');
    setShowPassword('text');
  };

  const { mutateAsync, isLoading } = useMutation(login, 'LOGIN');

  const _login = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    let formattedValue = formData.identifier;

    // Check if the entered value is a phone number in the specific format
    if (formData.identifier.length === 11 && /^\d+$/.test(formData.identifier)) {
      // If the value is '09029409151', transform it to '+2349029409151'
      formattedValue = `234${formData.identifier.slice(1)}`;
    }
    const credentials = {
      identifier: formattedValue.toLowerCase(),
      password: formData.password
    };

    try {
      const { data } = await mutateAsync(credentials);
      if (data.description === 'Account not verified') {
        navigate(`/otp/${credentials.identifier}`);
        await requestOtp({ email: credentials.identifier });
      }

      if (data.status === 'success') {
        handleAuthSuccess(data);
      }
    } catch (error: any) {
      if (error?.response?.data?.description === 'Account not verified') {
        navigate(`/otp/${credentials.identifier}`);
        const { data } = await requestOtp({ email: formData.identifier });
        enqueueSnackbar(`Login Error: ${data.message}`, { variant: 'error' });
      }
    }
  };

  const disabled = !formData.identifier || !formData.password || !!validators.identifier(formData.identifier);

  return (
    <AuthContainer>
      <h1 className="text-center">Login to your account</h1>
      <p className="option text-center">
        Don't have an account? <span onClick={() => navigate(SIGN_UP)}>Create an Account</span>
      </p>
      {/* <div className="flex w-full flex-row items-center gap-4">
        <LoginSocialFacebook
          onReject={() => { }}
          onResolve={() => { }}
          appId={String(process.env.REACT_APP_FACEBOOK_APP_ID)}
        >
          <button className="disabled:opacity-60 disabled:cursor-not-allowed flex items-center gap-3 p-4 flex-1 justify-center px-6 bg-[#3B5998] rounded-xl md:rounded-md text-white font-medium">
            <ImFacebook2 />
            <span>
              <span className="hidden md:inline">Continue With</span> Facebook
            </span>
          </button>
        </LoginSocialFacebook>
        <button
          type="button"
          onClick={() => googleLogin()}
          className="flex border items-center gap-3 flex-1 justify-center p-4 px-6 rounded-xl md:rounded-md font-medium"
        >
          <FcGoogle />
          <span>
            <span className="hidden md:inline">Continue With</span> Google
          </span>
        </button>
      </div> */}

      <form className="">
        <InputCon>
          <span>Email Address or Phone number</span>
          <InputField
            type="email"
            name="identifier"
            value={formData.identifier}
            onChange={onUpdateFormData}
            placeholder="email address or phone number"
            extraStyles={extraStylesInput}
            required={true}
            error={formError.identifier}
            autoComplete="username"
          />
        </InputCon>
        <Spacer height="1.5rem" />
        <InputCon>
          <span>Password</span>
          <InputField
            type={showPassword}
            name="password"
            value={formData.password}
            onChange={onUpdateFormData}
            placeholder="password "
            extraStyles={extraStylesInput}
            action={
              <TogglePassword onClick={onTogglePassword}>
                {showPassword !== 'text' ? <ShowPassword /> : <HidePassword />}
              </TogglePassword>
            }
            autoComplete="current-password"
          />
        </InputCon>
        <Spacer height="4rem" />
        <div className="buttonContainer">
          <Button isLoading={isLoading} type="submit" onClick={_login} disabled={isLoading || disabled}>
            Log in
          </Button>
        </div>
        <div className="forgot">
          <p className="mt-3 tracking-wide text-[#454545] text-base md:text-lg cursor-pointer">
            <span onClick={() => navigate(FORGOT_PASSWORD)}>Forgot password ?</span>
          </p>
        </div>
      </form>

      <div className="flex items-center gap-3 w-[50%] mb-4 m-auto">
        <div className="w-full h-[1px] rounded-sm bg-[#D9D9D9]"></div>
        <p>Or</p>
        <div className="w-full h-[1px] rounded-sm bg-[#D9D9D9]"></div>
      </div>
      <div className="flex gap-2 justify-center w-full">
        <div className="border border-[#D9D9D9] rounded-full p-4 cursor-pointer " onClick={() => googleLogin()}>
          <FcGoogle className="h-6 w-6 00 m-auto" />
        </div>
        <LoginSocialFacebook
          onReject={() => {}}
          onResolve={() => {}}
          appId={String(process.env.REACT_APP_FACEBOOK_APP_ID)}>
          <div className="border border-[#D9D9D9] rounded-full p-4 cursor-pointer ">
            <SiFacebook className="h-6 w-6 00 text-[#1877F2] m-auto" />
          </div>
        </LoginSocialFacebook>
      </div>
      {/* <div className="flex w-full flex-row items-center gap-4">
        <LoginSocialFacebook
          onReject={() => {}}
          onResolve={() => {}}
          appId={String(process.env.REACT_APP_FACEBOOK_APP_ID)}>
          <button className="disabled:opacity-60 disabled:cursor-not-allowed flex items-center gap-3 p-4 flex-1 justify-center px-6 bg-[#3B5998] rounded-xl md:rounded-md text-white font-medium">
            <ImFacebook2 />
            <span>
              <span className="hidden md:inline">Continue With</span> Facebook
            </span>
          </button>
        </LoginSocialFacebook>
        <button
          type="button"
          onClick={() => googleLogin()}
          className="flex border items-center gap-3 flex-1 justify-center p-4 px-6 rounded-xl md:rounded-md font-medium">
          <FcGoogle />
          <span>
            <span className="hidden md:inline">Continue With</span> Google
          </span>
        </button>
      </div> */}
    </AuthContainer>
  );
};

export default Login;
