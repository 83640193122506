import { Avatar, Theme, useMediaQuery } from '@mui/material';
import { AvatarIcon, CartIcon, HamBurger, Search2Icon } from 'assets/Icons';
import { ACCOUNT, CATEGORIES, LOGIN, SEARCH } from 'navigation/routes';
import QUERY_KEYS from 'network/config/queryKeys';
import { getCustomer } from 'network/services/profile';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { isCartOpen } from 'store/atoms/cartAtom';
import { profileAtom } from 'store/atoms/profileAtom';
import { userIsLoggedIn } from 'store/atoms/userAtom';
import { COLORS } from 'theme/color';
import { Button, Logo, Spacer } from '../../atoms';
import { AddressDropDown } from '../Address/AddressDropDown';
import { Cart } from '../Cart/Cart';
import { NewUseUpdatedCart, useUpdatedCart } from '../Cart/hooks';
import { Search } from '../SearchBox';
import { SideBar } from '../SideBar/SideBar';
import { StickyNotification, StyledBadge, TopNavBarCon } from './styles';

export const TopNavBar = () => {
  useUpdatedCart();
  const [searchParams, setSearchParams] = useSearchParams();

  const isLogged = useRecoilValue(userIsLoggedIn);
  const isProductCartOpen = useRecoilValue(isCartOpen);
  const [side, setSide] = React.useState(false);
  const [profile, setProfile] = useRecoilState(profileAtom);
  const { data: profileData } = useQuery({
    queryFn: getCustomer,
    queryKey: [QUERY_KEYS.CUSTOMER_PROFILE]
  });

  const navigate = useNavigate();

  const md = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const setIsModalOpen = useSetRecoilState(isCartOpen);
  const [unauthcart, setunauthcart] = useState('');
  const { pathname } = useLocation();
  const serverCart = NewUseUpdatedCart();
  React.useEffect(() => {
    if (profileData?.data !== undefined) setProfile(profileData.data);
    let cart = localStorage.getItem('recoil-persist');
    setunauthcart(cart ? JSON.parse(cart) : '');
  }, [profileData, setProfile]);

  const handleSearchFocus = () => {
    const searchUrlPath = `${CATEGORIES}${SEARCH}`;
    if (pathname !== searchUrlPath) {
      navigate(searchUrlPath);
    }
  };

  const handleOnSearch = () => {
    if (pathname === '/categories/search') {
      navigate(`?query=${searchParams.get('query')}`);
    } else {
      navigate(`/categories/search?query=${searchParams.get('query')}`);
    }
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setSearchParams({ query: inputValue });
  };
  // Safely access the length of the orders array
  const numberOfOrders = serverCart?.data?.orders?.length ?? 0;
  return (
    <>
      {isProductCartOpen.onCartOpen && <Cart />}
      <TopNavBarCon>
        <div className="content">
          <div className="left">
            {md && (
              <>
                <HamBurger onClick={() => setSide(true)} />
                <Spacer width={8} />
              </>
            )}
            <Logo noFill={false} />
            <Spacer width="32px" />
          </div>
          {!md && (
            <Search
              placeholder="Search for items or products"
              onSearch={handleOnSearch}
              onFocus={handleSearchFocus}
              value={searchParams.get('query') ?? undefined}
              onChange={handleOnChange}
              name={''}
            />
          )}
          <div className="right">
            {isLogged && !md && <AddressDropDown />}
            <Spacer width={25} />
            {/* <StyledBadge badgeContent={!isLogged ? cartLength : unauthcart.length} max={99}> */}
            <StyledBadge badgeContent={numberOfOrders} max={99}>
              <CartIcon
                onClick={e => {
                  e.stopPropagation();
                  if (profile === undefined) {
                    navigate('/login');
                    return;
                  }
                  setIsModalOpen({ onCartOpen: true });
                }}
                style={{ cursor: 'pointer' }}
                className="cart"
              />
            </StyledBadge>
            <Spacer width={md ? 25 : 30} />
            {md && <Search2Icon />}
            {<Spacer width={md ? 25 : 0} />}
            {md && (
              <Link to={isLogged ? `${ACCOUNT}` : LOGIN} className="link">
                {profile?.profilePicture ? (
                  <Avatar src={profile.profilePicture} />
                ) : (
                  <AvatarIcon className="avatar-mobile" />
                )}
              </Link>
            )}
            {!md && isLogged && (
              <Link to={isLogged ? `${ACCOUNT}` : LOGIN} className="link">
                {profile?.profilePicture ? (
                  <Avatar src={profile.profilePicture} />
                ) : (
                  <AvatarIcon className="avatar-mobile" />
                )}
              </Link>
            )}
            {!isLogged && !md && (
              <>
                <Spacer width={10} />
                <Button
                  onClick={() => navigate(LOGIN)}
                  extraStyles={{
                    width: '130px',
                    height: '40px',
                    borderRadius: '4px',
                    backgroundColor: 'transparent',
                    color: COLORS.MainBlue,
                    border: `1px solid ${COLORS.MainBlue}`,
                    fontWeight: '500'
                  }}>
                  Login
                </Button>
              </>
            )}
          </div>
        </div>
      </TopNavBarCon>
      {profile?.customer_type === 'BUSINESS' && profile?.business_approval === 'PENDING' && (
        <StickyNotification className="">Your Business Account is yet to be verified by Madina</StickyNotification>
      )}

      {md && <SideBar side={side} setSide={setSide} />}
    </>
  );
};
