import { PaystackSuccess, SuccessPaymentArgs } from 'TYPES/api.body';
import { NewUseUpdatedCart } from 'components/molecules/Cart/hooks';
import QUERY_KEYS from 'network/config/queryKeys';
import { getAllAddress } from 'network/services/address';
import { assignLogisticsShipment, bookLogisticsShipment, createChowDeckDelivery } from 'network/services/logistics';
import { submitOrder } from 'network/services/order';
import { getCustomer } from 'network/services/profile';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { cartListAtom } from 'store/atoms/cartAtom';
import { DEFAULT_CHECKOUT_DETAILS, checkoutDetailsAtom } from 'store/atoms/checkoutAtom';
import checkoutVariablesAtom from 'store/atoms/checkoutVariables';
import modalAtom from 'store/atoms/modals';
import { profileAtom } from 'store/atoms/profileAtom';
import { userRoleAtom } from 'store/atoms/userAtom';
import { v4 as uuidv4 } from 'uuid';

export const useLogisticsShipment = () => {
  const chowdeckMutation = useMutation({
    mutationFn: createChowDeckDelivery,
    onSuccess: () => {
      enqueueSnackbar({
        message: 'Chowdeck delivery created successfully',
        variant: 'success'
      });
    },
    onError: err => {
      console.log(err);
      enqueueSnackbar({
        message: 'Failed to create Chowdeck Delivery',
        variant: 'error'
      });
    }
  });
  const assignmentMutation = useMutation({
    mutationFn: assignLogisticsShipment,
    onSuccess: () => {
      enqueueSnackbar({
        message: 'Logistics shipment assigned successfully',
        variant: 'success'
      });
    },
    onError: err => {
      console.log(err);
      enqueueSnackbar({
        message: 'Failed to assign logistics shipment',
        variant: 'error'
      });
    }
  });
  const bookingMutation = useMutation({
    mutationFn: bookLogisticsShipment,
    onSuccess: data => {
      enqueueSnackbar({
        message: 'Shipment Booked successfully',
        variant: 'success'
      });
      assignmentMutation.mutate(data.data.shipmentId);
    },
    onError: err => {
      console.log(err);
      enqueueSnackbar({
        message: 'Failed to book logistics shipment',
        variant: 'error'
      });
    }
  });

  return { bookingMutation, chowdeckMutation };
};

export default function usePayment() {
  const { orderId } = useParams();
  const [user, checkNewUser] = useState('');
  const serverCart = NewUseUpdatedCart()?.data;
  const cartOrder = serverCart?.orders?.find(order => order?.vendorId === Number(orderId));
  const subTotal = cartOrder?.totalAmount || 0;
  useEffect(() => {
    return checkNewUser(sessionStorage.getItem('newuser') ?? 'false');
  }, []);
  const go = useNavigate();
  const queryClient = useQueryClient();
  const profile = useRecoilValue(profileAtom);
  const userRole = useRecoilValue(userRoleAtom);
  const { bookingMutation, chowdeckMutation } = useLogisticsShipment();
  // const subTotal = useRecoilValue(cartTotalAmount);
  const { data: addressData, isLoading: addressLoading } = useQuery({
    queryFn: getAllAddress,
    queryKey: QUERY_KEYS.CUSTOMER_ADDRESSES
  });
  const defaultAddress = React.useMemo(() => {
    const addresses = addressData?.data ? addressData.data : [];
    const defAddr = addresses.find(item => item.status === true);
    if (!defAddr && addresses.length > 0) {
      return addresses[0];
    }
    return defAddr;
  }, [addressData]);

  const setModalState = useSetRecoilState(modalAtom);
  const setCartList = useSetRecoilState(cartListAtom);
  const [checkoutDetails, setCheckoutDetails] = useRecoilState(checkoutDetailsAtom);
  const [checkoutVariables, setCheckoutVariables] = useRecoilState(checkoutVariablesAtom);
  const { mutateAsync: _submitOrder, isLoading: isSubmitOrderLoading } = useMutation({ mutationFn: submitOrder });
  const { data: customerProfile } = useQuery({
    queryFn: getCustomer,
    queryKey: QUERY_KEYS.CUSTOMER_PROFILE
  });

  const handleOrderSuccess = async (orderId?: string) => {
    // if (checkoutDetails.deliveryMethod === 'homedelivery' && checkoutDetails.logistic !== null) {
    //   if (checkoutDetails.logistic.carrierName === 'Chowdeck') {
    //     try {
    //       await chowdeckMutation.mutateAsync({
    //         reference: uuidv4(),
    //         fee_id: checkoutDetails.logistic.carrierId,
    //         item_type: 'Composite',
    //         user_action: 'sending',
    //         source_contact: {
    //           phone: MADINA_INFO.phone.replace('+234', '0'),
    //           email: MADINA_INFO.email,
    //           name: MADINA_INFO.name,
    //           country_code: 'NG'
    //         },
    //         destination_contact: {
    //           country_code: 'NG',
    //           email: profile?.email ?? '',
    //           name: `${profile?.firstname} ${profile?.lastname}`,
    //           phone: profile?.phoneNumber.replace('+234', '0') ?? ''
    //         },
    //         customer_delivery_note:
    //           checkoutDetails.driverNote && checkoutDetails.driverNote !== '' ? checkoutDetails.driverNote : 'None'
    //       });
    //     } catch (error) {
    //       console.log(error);
    //     }
    //   } else {
    //     try {
    //       await bookingMutation.mutateAsync({
    //         rate_id: checkoutDetails.logistic.carrierId,
    //         redis_key: checkoutDetails.logistic.redisKey,
    //         user_id: parseInt(getEnvValue('REACT_APP_GOSHIP_USER_ID')),
    //         platform: getEnvValue('REACT_APP_GOSHIP_PLATFORM'),
    //         orderId: orderId
    //       });
    //     } catch (error) {
    //       enqueueSnackbar('Failed to Book Shipment', { variant: 'error' });
    //     }
    //   }
    // }
    enqueueSnackbar('Order Placed Successfully', {
      variant: 'success'
    });
    localStorage.removeItem('cart');
    setCartList({});
    setCheckoutVariables(curr => ({
      ...curr,
      checkoutLoading: isSubmitOrderLoading
    }));
    setCheckoutVariables(curr => ({
      ...curr,
      pointsUsed: {
        points: 0,
        pointNairaEquivalent: 0
      }
    }));
    go('/account/orders', { replace: true });
    setCheckoutDetails(DEFAULT_CHECKOUT_DETAILS);
    setCheckoutVariables(curr => ({
      ...curr,
      deliveryOccurrence: 'None'
    }));
    queryClient.invalidateQueries([QUERY_KEYS.CART]);
    queryClient.invalidateQueries([QUERY_KEYS.ALL_ORDERS]);
    queryClient.invalidateQueries([QUERY_KEYS.COMPLETE_ORDERS]);
    queryClient.invalidateQueries([QUERY_KEYS.ONGOING_ORDERS]);
    queryClient.invalidateQueries([QUERY_KEYS.CANCELED_ORDERS]);
    setModalState(curr => ({
      ...curr,
      paymentModalOpen: false,
      paymentSuccessModalOpen: true,
      paymentFailureModalOpen: false,
      transactionInProgressModalOpen: false
    }));
  };

  const handleOrderFailure = (error: Error) => {
    enqueueSnackbar(`Something went wrong: ${error.message}`, {
      variant: 'error'
    });
    setModalState(curr => ({
      ...curr,
      paymentModalOpen: false,
      paymentSuccessModalOpen: false,
      paymentFailureModalOpen: true,
      transactionInProgressModalOpen: false
    }));
    setCheckoutVariables(curr => ({
      ...curr,
      pointsUsed: {
        points: 0,
        pointNairaEquivalent: 0
      }
    }));
  };

  const initiateBusinessPostPayment = () => {
    if (checkoutDetails.deliveryMethod === 'homedelivery' && checkoutVariables.selectedCarrier === undefined) {
      enqueueSnackbar('Please select a carrier', { variant: 'error' });
      return;
    }

    setModalState(c => ({ ...c, transactionInProgressModalOpen: true }));

    let grandTotal = subTotal + (checkoutDetails.shippingFee ?? 0) - checkoutVariables.pointsUsed.pointNairaEquivalent;

    _submitOrder({
      ...checkoutDetails,
      orderId: cartOrder?.id,
      addressId: defaultAddress?.id,
      has10PercentDiscount: user === 'true' ? true : false,
      deliveryAgentId: checkoutVariables.selectedCarrier?.carrierId
        ? parseInt(checkoutVariables.selectedCarrier.carrierId)
        : undefined,
      logistic:
        checkoutDetails.deliveryMethod === 'homedelivery' && checkoutVariables.selectedCarrier !== undefined
          ? {
              carrierId: checkoutVariables.selectedCarrier.courier_id,
              carrierName: checkoutVariables.selectedCarrier.courier_name,
              carrierLogo: checkoutVariables.selectedCarrier.courier_logo,
              amount: checkoutVariables.selectedCarrier.total,
              estimatedDaysOfDelivery: checkoutVariables.selectedCarrier.delivery_eta
              // eta: checkoutVariables.selectedCarrier.delivery_eta_time
            }
          : null,
      points: checkoutVariables.pointsUsed.points,
      customerId: customerProfile?.data.id,
      grandTotal,
      total: grandTotal,
      subAmount: user === 'true' ? subTotal - subTotal * 0.1 : subTotal,
      driverNote: checkoutDetails.driverNote && checkoutDetails.driverNote !== '' ? checkoutDetails.driverNote : 'null',
      paymentType: 'Post Payment',
      paymentTrxRef: uuidv4(),
      paymentMode: 'post_payment',
      customerType: userRole === 'BUSINESS' ? 'BUSINESS' : 'REGULAR',
      request_token: checkoutDetails.request_token
    })
      .then((data: any) => {
        console.log(data);
        if (data.data.paymentStatus === 'Success' || data.data.paymentStatus === 'success') {
          handleOrderSuccess(data.data.id);
        } else {
          handleOrderFailure(new Error('Payment not successful'));
        }
      })
      .catch((err: any) => handleOrderFailure(err));
  };

  const handlePaymentSuccess = (args: SuccessPaymentArgs) => {
    _submitOrder({
      ...checkoutDetails,
      request_token: checkoutVariables?.request_token || '',
      logistic:
        checkoutDetails.deliveryMethod === 'homedelivery' && checkoutVariables.selectedCarrier !== undefined
          ? {
              carrierId: checkoutVariables.selectedCarrier.courier_id,
              carrierName: checkoutVariables.selectedCarrier.courier_name,
              carrierLogo: checkoutVariables.selectedCarrier.courier_logo,
              amount: checkoutVariables.selectedCarrier.total,
              estimatedDaysOfDelivery: checkoutVariables.selectedCarrier.delivery_eta,
              request_token: checkoutVariables.request_token
              // eta: checkoutVariables.selectedCarrier.delivery_eta_time
            }
          : null,
      has10PercentDiscount: user === 'true' ? true : false,
      customerType: userRole === 'BUSINESS' ? 'BUSINESS' : 'REGULAR',
      recurringDelivery: !(checkoutVariables.deliveryOccurrence === 'None'),
      recurringDeliveryEndDate:
        checkoutVariables.deliveryOccurrence === 'None' ? undefined : checkoutDetails.recurringDeliveryEndDate,
      deliverySchedule: checkoutDetails.deliveryMethod === 'pickup' ? 'Immediately' : checkoutDetails.deliverySchedule,
      status: args.status,
      paymentTrxRef: args.reference ?? uuidv4(),
      paymentMode: args.paymentMode,
      paymentType: args.paymentType,
      paymentStatus: args.status as any,
      orderId: cartOrder?.id,
      addressId: defaultAddress?.id
    })
      .then((data: any) => {
        if (data.data.paymentStatus === 'SUCCESS' || data.data.paymentStatus === 'Success') {
          handleOrderSuccess(data.data.id);
        } else {
          handleOrderFailure(new Error('Payment not successful'));
        }
      })
      .catch((err: any) => handleOrderFailure(err));
  };
  const onWalletSuccess = () => {
    setModalState(c => ({
      ...c,
      transactionInProgressModalOpen: true,
      paymentModalOpen: false
    }));
    handlePaymentSuccess({
      status: 'Success',
      paymentMode: 'Wallet',
      paymentType: 'Madina Wallet'
    });
  };

  const onSuccess = (reference?: PaystackSuccess) => {
    setModalState(c => ({
      ...c,
      transactionInProgressModalOpen: true,
      paymentModalOpen: false
    }));
    if (reference !== undefined) {
      handlePaymentSuccess({
        paymentMode: 'PayStack',
        reference: reference.reference,
        status: reference.status,
        paymentType: 'Credit Card'
      });
    } else {
      handleOrderFailure(new Error('No reference found'));
    }
  };

  const onClose = () => {
    setModalState(curr => ({
      ...curr,
      paymentModalOpen: false,
      paymentSuccessModalOpen: false,
      paymentFailureModalOpen: false,
      transactionInProgressModalOpen: false
    }));
  };

  const initiatePayment = () => {
    if (checkoutDetails.deliveryMethod === 'homedelivery' && checkoutVariables.selectedCarrier === undefined) {
      enqueueSnackbar('Please select a carrier', { variant: 'error' });
      return;
    }

    let grandTotal = subTotal + (checkoutDetails.shippingFee ?? 0) - checkoutVariables.pointsUsed.pointNairaEquivalent;
    setCheckoutDetails(curr => ({ ...curr, grandTotal }));
    setCheckoutDetails(prev => ({
      ...prev,
      request_token: checkoutVariables.request_token || '',
      deliveryAgentId: checkoutVariables.selectedCarrier?.courier_id
        ? parseInt(checkoutVariables.selectedCarrier.courier_id)
        : undefined,
      logistic:
        checkoutDetails.deliveryMethod === 'homedelivery' && checkoutVariables.selectedCarrier !== undefined
          ? {
              carrierId: checkoutVariables.selectedCarrier.courier_id,
              carrierName: checkoutVariables.selectedCarrier.courier_name,
              carrierLogo: checkoutVariables.selectedCarrier.courier_logo,
              amount: checkoutVariables.selectedCarrier.total,
              estimatedDaysOfDelivery: checkoutVariables.selectedCarrier.delivery_eta,
              request_token: checkoutVariables.request_token
              // eta: checkoutVariables.selectedCarrier.delivery_eta_time
            }
          : null,
      points: checkoutVariables.pointsUsed.points,
      customerId: customerProfile?.data.id,
      grandTotal,
      total: grandTotal,
      subAmount: user === 'true' ? subTotal - subTotal * 0.1 : subTotal,
      orderId: cartOrder?.id,
      addressId: defaultAddress?.id
    }));
    setModalState(curr => ({ ...curr, paymentModalOpen: true }));
  };

  return {
    onClose,
    onSuccess,
    initiatePayment,
    onWalletSuccess,
    handleOrderSuccess,
    handleOrderFailure,
    initiateBusinessPostPayment
  };
}
