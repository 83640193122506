import React from "react";
import { TopNavBar } from "../molecules";
import { Outlet } from "react-router-dom";
import { GoodsDetailModal } from "components/molecules";
import { useSearchParams } from "react-router-dom";
import ShareModal from "components/atoms/a-share-modal";
import SEARCH_PARAMS from "constants/searchParams";

type PageLayoutProps = {
	children?: React.ReactNode;
	out?: boolean;
};

const PageLayout = ({ children, out }: PageLayoutProps) => {
	const [searchParams] = useSearchParams();
	const prodId = searchParams.get("productId");
	const shareUrl = searchParams.get(SEARCH_PARAMS.SHARE_URL);
	return (
		<>
			<TopNavBar />
			<div className="">{out ? <Outlet /> : children}</div>
			{prodId !== null && <GoodsDetailModal />}
			{shareUrl !== null && <ShareModal />}
		</>
	);
};

export default PageLayout;
