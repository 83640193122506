import type { AddToCartArgs, RemoveFromCartArgs, UpdateCartItemQuantityArgs } from 'TYPES/api.body';
import {
  ADD_TO_CART,
  BUSINESS_ADD_TO_CART,
  BUSINESS_REMOVE_FROM_CART,
  LIKE_PRODUCT,
  REMOVE_FROM_CART,
  UPDATE_BUSINESS_CART_ITEM_QUANTITY,
  UPDATE_CART_ITEM_QUANTITY
} from '../config/endpoints';
import api from '../config/setAuthHeader';

export const addProductToCart = async (opts: AddToCartArgs) => {
  const query = opts.items.map(item => `product_id=${item.product_id}&product_quantity=${item.quantity}`).join('&');
  const businessQuery = opts.items.map(item => `/${item.product_id}/?quantity=${item.quantity}`);
  if (opts.customerType === 'BUSINESS' && opts.items.length > 0) {
    const { data } = await api.post(BUSINESS_ADD_TO_CART + businessQuery);
    // const { data } = await api.post(
    //   BUSINESS_ADD_TO_CART.concat(`${opts.items[0].product_id}?quantity=${opts.items[0].quantity}`)
    // );
    return data;
  }

  const { data } = await api.post(ADD_TO_CART + '?' + query);
  return data;
};

export const removeProductFromCart = async (opts: RemoveFromCartArgs) => {
  if (opts.customerType === 'BUSINESS') {
    const { data } = await api.delete(`${BUSINESS_REMOVE_FROM_CART}${opts.id}?order_id=${opts.orderId}`);
    return data;
  } else {
    const { data } = await api.delete(`${REMOVE_FROM_CART}${opts.id}?order_id=${opts.orderId}`);
    return data;
  }
};

export const likeProduct = async (id: number) => {
  const { data } = await api.post(`${LIKE_PRODUCT}${id}/like-product`);
  return data;
};

export const unlikeProduct = async (id: number) => {
  const { data } = await api.delete(`${LIKE_PRODUCT}${id}/remove-from-favourite`);
  return data;
};

export const updateCartItemQty = async ({ orderId, qty, productId, customerType }: UpdateCartItemQuantityArgs) => {
  if (customerType === 'BUSINESS') {
    const { data } =
      await api.put(`${UPDATE_BUSINESS_CART_ITEM_QUANTITY}${orderId}/edit-quantity?quantity=${qty}&productId=${productId}
`);
  
    return data;
  } else {
    const { data } =
      await api.put(`${UPDATE_CART_ITEM_QUANTITY}${orderId}/edit-quantity?quantity=${qty}&productId=${productId}
`);

    return data;
  }
};
