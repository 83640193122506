import { styled } from '@mui/material/styles';
import { COLORS } from '../../../theme/color';

export const NavCon = styled('div')`
  // background-color: ${COLORS.White};
  padding: 0;
  width: 100%;
  position: sticky;
  top: 0.5rem;
  .menu {
    padding: 0 1.5rem 1rem 1.5rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    h2 {
      font-weight: 600;
      font-size: 20px;
    }
  }
  .logout {
    width: 100%;
    height: 40px;
    background-color: ${COLORS.White};
    padding: 0.5rem 2rem 0.5rem 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    transition: background-color 0.3s ease-in-out;
    text-decoration: none;
    color: ${COLORS.MainBlack};
    font-weight: 400;
    font-size: 0.875rem;
    margin-bottom: 1rem;
    text-transform: capitalize;
    cursor: pointer;
    &:hover {
      color: ${COLORS.MainBlue};
      font-weight: 500;
    }
  }
`;

export const SkeletonCon = styled('div')`
  padding: 0px 20px;
`;
