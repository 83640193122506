import { atom } from 'recoil';
import { BusinessRegisterRequest } from 'TYPES/api.body';
import { STORE_KEYS } from '../keys';

export const businessRegDetails = atom<{ request: BusinessRegisterRequest; step: number }>({
  key: STORE_KEYS.BUSINESS_KYB,
  default: {
    request: {
      password: '',
      businessName: '',
      country: '',
      street: '',
      cacRegNo: '',
      state: '',
      websiteUrl: '',
      businessEmail: '',
      businessPhoneNumber: '',
      representativeInfo: {
        name: '',
        email: '',
        phoneNumber: ''
      }
    },
    step: 1
  }
});
