import { Image } from '@mantine/core';
import { LogoIcon } from 'assets/Icons';
import { Empty } from 'components/atoms';
import { ProductSkeletons } from 'pages/Products';
import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

interface Props {
  vendors: any[];
  diva?: any;
  isLoading: boolean;
  skeletonCount?: number;
  emptyMessage?: string;
  showEmptyPrompt?: boolean;
  emptyText?: string;
}

const VendorsGrid: React.FC<Props> = ({
  diva,
  vendors,
  isLoading,
  skeletonCount,
  showEmptyPrompt = true,
  emptyText
}) => {
  const goto = useNavigate();
  const location = useLocation();

  const navigateVendorProducts = ({ vendor }: any) => {
    const search = new URLSearchParams(location.search);
    search.delete('vendorId');
    search.append('vendorId', vendor.seller?.id.toString());
    goto(`${location.pathname}/${vendor?.seller?.businessName}`, { state: vendor });
  };

  return (
    <>
      <div className="grid grid-cols-auto-fill-15rem gap-[2rem]">
        {vendors.map((vendor, index) => (
          <Link
            key={index}
            to={vendor !== undefined ? `${location.pathname}${location.search}&vendor=${vendor?.seller?.id}` : '#'}>
            {/* <Vendor vendors={vendor} /> */}
            <div className="w-full h-52 bg-white rounded-xl">
              {vendor?.sellerImage !== null ? (
                <Image src={vendor?.sellerImage} className="object-cover rounded-xl w-full h-full" />
              ) : (
                <LogoIcon />
              )}
            </div>
            <h3 className="font-medium pt-3"> {vendor?.seller?.businessName}</h3>
            <div className="flex gap-2 items-center flex-wrap mt-2">
              {vendor?.sub_categories?.slice(0, 5)?.map((category: string, index: any) => {
                return (
                  <p
                    key={index}
                    className=" border border-secondary/40 text-[10px] bg-white/25 tracking-wide text-secondary px-3 py-0.5 rounded">
                    {category}
                  </p>
                );
              })}
            </div>
          </Link>
        ))}
        {isLoading && <ProductSkeletons count={skeletonCount ?? 6} />}
      </div>
      {!isLoading && !vendors && (
        <Empty message={emptyText ?? 'Unfortunately, there are no vendors in this category.'} />
      )}
    </>
  );
};

export default VendorsGrid;
