import LOCALSTORAGE_KEYS from 'constants/localStorage';
import QUERY_KEYS from 'network/config/queryKeys';
import { addProductToCart, removeProductFromCart, updateCartItemQty } from 'network/mutations/products';
import { viewCart } from 'network/services/goods';
import { getCustomer } from 'network/services/profile';
import { useMutation, useQuery } from 'react-query';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { CartState, ServerCartState, cartListAtom, serverCartListAtom } from 'store/atoms/cartAtom';
import { userRoleAtom } from 'store/atoms/userAtom';
import { syncLocalAndServerCart } from '../Goods/utils';

export const NewUseUpdatedCart = () => {
  const userRole = useRecoilValue(userRoleAtom);
  const { data: profileData } = useQuery({
    queryFn: getCustomer,
    queryKey: [QUERY_KEYS.CUSTOMER_PROFILE]
  });

  const setServerCartState = useSetRecoilState(serverCartListAtom);

  const cartQuery = useQuery({
    queryKey: [QUERY_KEYS.CART, userRole],
    queryFn: () => viewCart(userRole === 'BUSINESS' ? 'BUSINESS' : 'REGULAR'),
    onSuccess: data => {
      if (profileData && data) {
        const serverCart: ServerCartState = {};
        setServerCartState(serverCart);
      }
    }
  });

  return cartQuery;
};

export const useUpdatedCart = () => {
  const userRole = useRecoilValue(userRoleAtom);
  const { mutateAsync: addMutate } = useMutation({
    mutationFn: addProductToCart
  });
  const { mutateAsync: updateMutate } = useMutation({
    mutationFn: updateCartItemQty
  });
  const { mutateAsync: removeMutate } = useMutation({
    mutationFn: removeProductFromCart
  });
  const { data: profileData } = useQuery({
    queryFn: getCustomer,
    queryKey: [QUERY_KEYS.CUSTOMER_PROFILE]
  });
  const setCartState = useSetRecoilState(cartListAtom);

  const cartQuery = useQuery({
    queryKey: [QUERY_KEYS.CART, userRole],
    queryFn: () => viewCart(userRole === 'BUSINESS' ? 'BUSINESS' : 'REGULAR'),
    onSuccess: data => {
      const serverCart: CartState = {};
      const localCartStr = localStorage.getItem(LOCALSTORAGE_KEYS.CART);
      const localCart: CartState | undefined = localCartStr !== null ? JSON.parse(localCartStr) : undefined;
      if (data) {
        data.orders.forEach(order => {
          serverCart[order.vendorId] = order.products.map(product => {
            return {
              name: product?.productName ?? '',
              quantityAvailable: product?.quantityAvailable ?? 0,
              amount: product.price,
              id: product?.productId ?? 0,
              quantity: product.quantity,
              photo: product?.images ?? '',
              shortDSC: product?.description ?? '',
              minimumOrderQuantity: product?.minimumOrderQuantity ?? 0,
              sellerId: product?.seller.id ?? 0
            };
          });
        });
        if (profileData !== undefined && localStorage.getItem(LOCALSTORAGE_KEYS.CART) !== null) {
          syncLocalAndServerCart(
            data,
            addMutate,
            updateMutate,
            removeMutate,
            setCartState,
            userRole === 'BUSINESS' ? 'BUSINESS' : 'REGULAR'
          );
        } else {
          setCartState(serverCart);
        }
      } else {
        setCartState(localCart ?? {});
      }
    }
  });

  return cartQuery;
};
