import { Checkbox, Radio, RadioGroup } from '@mui/material';
import { Spacer } from 'components/atoms';
import ButtonDatePicker from 'components/molecules/m-button-date-pikcer';
import dayjs from 'dayjs';
import React from 'react';
import { useRecoilState } from 'recoil';
import { checkoutDetailsAtom } from 'store/atoms/checkoutAtom';
import checkoutVariablesAtom from 'store/atoms/checkoutVariables';
import { COMMONTYPES } from 'TYPES/event.types';
import {
  CustomAccordion,
  CustomAccordionDetails,
  CustomAccordionSummary,
  RecurringDeliveryCon,
  StyledFormLabel
} from './style';

type DeliveryPeriod = {
  name: string;
  value: string;
};

const DELIVERY_PERIODS: DeliveryPeriod[] = [
  { name: '1 Week', value: 'ONE_WEEK' },
  { name: '2 Weeks', value: 'TWO_WEEKS' },
  { name: '1 Month', value: 'ONE_MONTH' },
  { name: '2 Months', value: 'TWO_MONTHS' }
];
export const RecurringDelivery = () => {
  const [checkoutVariables, setCheckoutVariables] = useRecoilState(checkoutVariablesAtom);
  const [checkoutDetails, setCheckoutDetails] = useRecoilState(checkoutDetailsAtom);

  const onSelectMethod = (e: COMMONTYPES['inputChange']) => {
    setCheckoutVariables(curr => ({ ...curr, deliveryOccurrence: e.currentTarget.value }));
  };

  React.useEffect(() => {
    if (checkoutVariables.deliveryOccurrence !== 'None') {
      setCheckoutDetails(curr => ({ ...curr, deliveryOccurrence: checkoutVariables.deliveryOccurrence as any }));
    }
  }, [checkoutVariables, setCheckoutDetails]);

  return (
    <RecurringDeliveryCon>
      <StyledFormLabel
        control={<Checkbox size="small" />}
        onChange={() => {
          checkoutVariables.deliveryOccurrence === 'None'
            ? setCheckoutVariables(curr => ({ ...curr, deliveryOccurrence: 'ONE_WEEK' }))
            : setCheckoutVariables(curr => ({ ...curr, deliveryOccurrence: 'None' }));
        }}
        checked={checkoutVariables.deliveryOccurrence !== 'None' && checkoutVariables.deliveryOccurrence !== undefined}
        label="Save up to 15% on future auto-deliveries"
      />
      <CustomAccordion
        expanded={
          checkoutVariables.deliveryOccurrence !== 'None' && checkoutVariables.deliveryOccurrence !== undefined
        }>
        <CustomAccordionSummary disableRipple={true} aria-controls="panel1a-content" id="panel1a-header">
          <p>Recurring delivery every (Madina Plus):</p>
        </CustomAccordionSummary>
        <CustomAccordionDetails>
          <RadioGroup value={checkoutVariables.deliveryOccurrence} onChange={onSelectMethod}>
            <StyledFormLabel
              value="None"
              labelPlacement="start"
              control={<Radio />}
              label="None"
              checked={checkoutVariables.deliveryOccurrence === 'None'}
            />
            <Spacer width={34} />
            {DELIVERY_PERIODS.map(item => (
              <React.Fragment key={item.value}>
                <StyledFormLabel
                  labelPlacement="start"
                  value={item.value}
                  control={<Radio />}
                  label={item.name}
                  checked={checkoutVariables.deliveryOccurrence === item.value}
                />
                <Spacer width={34} />
              </React.Fragment>
            ))}
          </RadioGroup>
        </CustomAccordionDetails>
        {checkoutVariables.deliveryOccurrence !== 'None' && (
          <ButtonDatePicker
            title="Recurring Delivery End-Date"
            onChange={val =>
              setCheckoutDetails(curr => ({ ...curr, recurringDeliveryEndDate: val?.toDate().toISOString().slice(0, 10) }))
            }
            defaultValue={dayjs(new Date(checkoutDetails.recurringDeliveryEndDate ?? ''))}
            value={dayjs(new Date(checkoutDetails.recurringDeliveryEndDate ?? ''))}
          />
        )}
      </CustomAccordion>
    </RecurringDeliveryCon>
  );
};
