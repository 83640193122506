import { MenuItem, SelectChangeEvent, Switch } from '@mui/material';
import { COMMONTYPES } from 'TYPES/event.types';
import { stateinNigeria } from 'assets/mockData';
import QUERY_KEYS from 'network/config/queryKeys';
import { useMutation } from 'network/mutations/useMutationHook';
import { addAddress, updateAddress } from 'network/services/address';
import { validateAddressDeliveryCallback } from 'network/services/order';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { useMutation as rUseMutation, useQueryClient } from 'react-query';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ADDRESS_INITIAL, contactState } from 'store/atoms/contactAtom';
import { profileAtom } from 'store/atoms/profileAtom';
import { checkFormData, validators } from 'utils/validator';
import { Modal } from '..';
import { Button, CustomSelect, InputField } from '../../atoms';
import { CenterModalContentStyle, CenterModalStyle } from '../Modal/style';
import { AddressModalCon } from './styles';
interface Props {
  refetch?: () => void;
}

const ERR_INITIAL = { firstname: '', lastname: '', address: '', phoneNumber: '', street: '' };

export const EditAddressModal: React.FC<Props> = ({ refetch }) => {
  const profile = useRecoilValue(profileAtom);
  const [addressInfo, setAddressInfo] = useRecoilState(contactState);
  const [formData, setFormData] = useState(addressInfo);
  const [formError, setFormError] = useState(ERR_INITIAL);
  const { street, phoneNumber } = formData;
  const queryClient = useQueryClient();

  const { mutateAsync: asyncValidateAddress } = rUseMutation(validateAddressDeliveryCallback, {
    onSuccess: data => {},
    onError: error => {
      enqueueSnackbar('Address validation failed. Please check the details and try again', { variant: 'error' });
    }
  });
  const setModalClose = () => {
    setAddressInfo(ADDRESS_INITIAL);
  };

  const onInputChange = (e: COMMONTYPES['inputChange']) => {
    const { name, value } = e.target;
    const error = validators[name](value);
    setFormData(formData => ({ ...formData, [name]: value }));
    setFormError({
      ...formError,
      [name]: error
    });
  };

  const onDefaultChange = (e: COMMONTYPES['inputChange']) => {
    const { name, checked } = e.target;
    setFormData(formData => ({ ...formData, [name]: checked }));
  };

  const onSelectChange = (e: SelectChangeEvent<unknown>) => {
    const { name, value } = e.target;
    setFormData(formData => ({ ...formData, [name]: value }));
  };

  const { mutateAsync: newAddressMutate, isLoading: newLoading } = useMutation(addAddress, 'NEW_ADDRESS');
  const { mutateAsync: updateAddressMutate, isLoading: updateLoading } = useMutation(updateAddress, 'UPDATE_ADDRESS');

  const _submit = async (e: COMMONTYPES['buttonClick']) => {
    e.preventDefault();

    // Check if required form fields are valid
    const isValid = checkFormData({ street, phoneNumber }, setFormError);
    if (!isValid) return;

    const { isEditAddressModal, ...rest } = formData;
    const addrStr = `${rest.street}, ${rest.state}, ${rest.country}`;
    const name =
      profile?.customer_type === 'BUSINESS'
        ? profile?.business_name + ' Business'
        : (profile?.firstname || 'User') + ' ' + (profile?.lastname || ' User');

    try {
      // Validate the address before adding or updating
      const validationResult = await asyncValidateAddress({
        name: name,
        email: profile?.email || 'test@gmail.com',
        phone: formData.phoneNumber || '09048937589',
        address: addrStr
      });

      if (validationResult.status !== 'SUCCESS') {
        enqueueSnackbar('Address validation failed. Please check the details and try again.', { variant: 'error' });
        return;
      }

      // Proceed to add or update the address if validation succeeds
      if (rest.id) {
        await updateAddressMutate({ id: rest.id, addressCode: validationResult?.data?.address_code, ...rest });
      } else {
        await newAddressMutate({
          status: rest.status,
          street: rest.street,
          state: rest.state,
          country: rest.country,
          city: rest.city,
          addressCode: validationResult?.data?.address_code,
          phoneNumber: validationResult?.data?.phone
        });
      }

      // Invalidate query to refetch updated address list
      queryClient.invalidateQueries(QUERY_KEYS.CUSTOMER_ADDRESSES);
      if (refetch) refetch();

      // Reset the form data to initial state
      setAddressInfo(ADDRESS_INITIAL);
    } catch (error) {
      enqueueSnackbar('An error occurred while updating the address.', { variant: 'error' });
    }
  };

  const selectClick = (e: COMMONTYPES['divClick']) => e.stopPropagation();

  return (
    <Modal
      onModalClose={setModalClose}
      extraModalContentStyle={{ ...CenterModalContentStyle, maxWidth: '636px' }}
      extraModalStyle={CenterModalStyle}>
      <AddressModalCon>
        <h3>Edit Address</h3>
        <div className="name-div hidden" style={{ display: 'none' }}>
          <InputField
            label="Firstname"
            name="firstname"
            value={'firstname'}
            // onChange={onInputChange}
            placeholder="Enter first name"
            required
            error={formError.firstname}
          />
          <InputField
            label="Lastname"
            name="lastname"
            value={'lastname'}
            // onChange={onInputChange}
            placeholder="Enter last name"
            required
            error={formError.lastname}
            // className='hidden'
          />
        </div>
        <label>Country</label>
        <CustomSelect name="country" value={formData.country} onChange={onSelectChange} onClick={selectClick}>
          <MenuItem value="Nigeria">Nigeria</MenuItem>
        </CustomSelect>
        <label>State</label>
        <CustomSelect name="state" value={formData.state} onChange={onSelectChange} onClick={selectClick}>
          {stateinNigeria.map((state, index) => (
            <MenuItem value={state} key={index}>
              {state}
            </MenuItem>
          ))}
        </CustomSelect>
        <InputField
          label="Street"
          name="street"
          value={street}
          onChange={onInputChange}
          placeholder="Enter your street"
          required
          error={formError.street}
        />
        <InputField
          label="Phone Number"
          type="tel"
          name="phoneNumber"
          value={phoneNumber}
          onChange={onInputChange}
          placeholder="Enter phone number"
          required
          error={formError.phoneNumber}
        />
        <label htmlFor="save-default">
          Save as default{' '}
          <Switch id="save-default" name="status" checked={!!formData.status} onChange={onDefaultChange} />
        </label>
        <Button extraStyles={{ width: '194px' }} onClick={_submit} isLoading={newLoading || updateLoading}>
          Confirm Address
        </Button>
      </AddressModalCon>
    </Modal>
  );
};
