import { Loader } from '@mantine/core';
import { deleteCustomerAccount } from 'network/services/profile';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

const DeleteAccount: React.FC = () => {
  const goto = useNavigate();
  const { mutateAsync, isLoading } = useMutation({
    mutationFn: deleteCustomerAccount
  });
  const passRef = React.useRef<HTMLInputElement>(null);

  const handleDelete = async () => {
    if (!passRef.current?.value || passRef.current.value.length < 1) {
      enqueueSnackbar('Please enter your password', { variant: 'error' });
      return;
    }
    try {
      await mutateAsync(passRef.current.value);
      enqueueSnackbar('Account Deleted', { variant: 'error' });
      goto('/login', { replace: true });
    } catch (error: any) {
      console.log(error);
      enqueueSnackbar(`${error?.response?.data?.message}`, {
        variant: 'error'
      });
    }
  };

  return (
    <div className="max-w-[550px]">
      <h1 className="font-bold text-2xl border-b py-4">Delete Account</h1>
      <p className="text-lg mt-4">
        Deleting your account will remove all your information from our database. This cannot be undone.
      </p>
      <div className="mt-8 text-black/50">
        <p>
          To confirm this, please enter "<span className="font-bold">your password</span>"
        </p>
        <div className="flex mt-3 items-center gap-4">
          <input type="text" name="fake-username" autoComplete="username" className="hidden" />
          <input
            type="password"
            ref={passRef}
            autoComplete="new-password"
            className="p-3 bg-transparent px-2 border rounded-md"
          />
          <button
            type="button"
            onClick={handleDelete}
            disabled={isLoading}
            className="bg-red-700 w-full max-w-[200px] text-white p-[13px] h-full px-6 rounded-md">
            {isLoading ? <Loader p={0} m={0} size="sm" color="white" /> : 'Delete Account'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteAccount;
