import { styled } from '@mui/material';

export const SearchBar = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 4px;
  position: relative;
  width: 100%;
  max-width: 500px;
  svg {
    position: absolute;
    left: 1rem;
    z-index: 10;
  }
`;

export const SearchInput = {
  paddingLeft: '3rem',
  fontSize: '0.9rem',
  height: '40px',
  borderRadius: '4px'
};

export const extraStylesBtn = {
  height: '40px',
  borderRadius: '4px',
  padding: '0 2em'
};
