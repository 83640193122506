import { Badge, BadgeProps, styled } from '@mui/material';
import { COLORS } from '../../../theme/color';
import { breakpoints, maxWidth } from '../../../theme/theme';

export const StickyNotification = styled('div')`
  position: sticky;
  top: 70px; /* Adjust this value to match the height of your TopNavBarCon */
  background-color: ${COLORS.MainRed}; /* Adjust the color as needed */
  padding: 1.2rem 0.5rem; /* Adjust the padding as needed */
  text-align: center;
  color: ${COLORS.White}; /* Adjust the text color as needed */
  z-index: 1;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adjust the shadow as needed */

  @media (max-width: ${breakpoints.md}px) {
    top: 64px;
  }
`;

export const TopNavBarCon = styled('div')`
  width: 100%;
  background: ${COLORS.White};
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 12px 2rem;
  z-index: 2;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  @media (max-width: ${breakpoints.sm}px) {
    padding: 12px 1rem;
    color: ${COLORS.SecBlue};
  }
  .content {
    width: 100%;
    max-width: ${maxWidth}px;
    /* padding: 0 4%; */

    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex: 1;

      .logo {
        cursor: pointer;
        max-height: 100%;
        margin-left: 2px;
      }

      @media (max-width: ${breakpoints.md}px) {
        flex: none;
        .logo {
          transform: scale(0.85);
        }
      }
    }

    .right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex: 1;

      h3 {
        font-weight: 400;
        font-size: 1rem;
        color: ${COLORS.MainBlue};
        display: flex;
        justify-content: start;
        align-items: center;
        max-width: 190px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      svg {
        color: #483d3d;
      }
      .link {
        font-weight: 600;
        font-size: 1rem;
        color: ${COLORS.MainBlue};
        text-decoration: none;

        display: flex;
        align-items: center;
      }
      .avatar {
        transform: scale(1.3);
        cursor: pointer;
      }
      @media (max-width: ${breakpoints.md}px) {
        .cart {
          transform: scale(0.9);
        }
        .avatar {
          color: ${COLORS.SecBlue};
          transform: scale(1.1);
          cursor: pointer;
        }
      }
    }
  }
`;

export const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    top: 0,
    left: theme.breakpoints.down('md') ? 0 : 7,
    backgroundColor: COLORS.DarkRed,
    color: COLORS.White,
    width: '3px',
    fontSize: '10px'
  }
}));
