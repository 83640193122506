import { Image } from '@mantine/core';
import {
  blob,
  fashion as fashionImg,
  health as healthImg,
  home as homeImg,
  supermarket as supermarketImg
} from 'components/molecules/blobs';
import { Link } from 'react-router-dom';

import { Category } from 'TYPES/api.body';

interface Props {
  categories: Category[];
}

const BlobFlex: React.FC<Props> = ({ categories }) => {
  const fashion = categories.filter(item => item.name.toLowerCase().includes('fashion')).pop();
  const food = categories.filter(item => item.name.toLowerCase().includes('food')).pop();
  const home = categories.filter(item => item.name.toLowerCase().includes('home')).pop();
  const health = categories.filter(item => item.name.toLowerCase().includes('health')).pop();
  const supermarket = categories.filter(item => item.name.toLowerCase().includes('supermarket')).pop();

  const makeSlug = (name: string) => name.toLowerCase().split(' ').join('-');

  return (
    <div className="md:flex md:justify-center gap-14 padding-section py-24   flex-wrap">
      <div className="flex flex-col items-center  ">
        <Link
          className="flex flex-col items-center"
          to={food !== undefined ? `/categories/supermarket/${makeSlug(food.name)}?category=${food?.id}` : '#'}>
          <div className="blob blob1 mb-2  border border-[#000000] hover:border-blue-600">
            <Image src={blob} />
          </div>
          <span className="text-[#4D4D4D]">Food</span>
        </Link>
      </div>
      <div className="flex flex-col items-center">
        <Link
          className="flex flex-col items-center"
          to={
            fashion !== undefined ? `/categories/supermarket/${makeSlug(fashion.name)}?category=${fashion?.id}` : '#'
          }>
          <div className="blob blob2 mb-2 border border-[#000000] hover:border-amber-800">
            <Image src={fashionImg} />
          </div>
          <span className="text-[#4D4D4D]">Fashion</span>
        </Link>
      </div>
      <div className="flex flex-col items-center">
        <Link
          className="flex flex-col items-center"
          to={
            supermarket !== undefined
              ? `/categories/supermarket/${makeSlug(supermarket.name)}?category=${supermarket?.id}`
              : '#'
          }>
          <div className="blob blob3 mb-2 border  border-[#000000] hover:border-orange-600">
            <Image src={supermarketImg} />
          </div>
          <span className="text-[#4D4D4D]">Supermarket</span>
        </Link>
      </div>
      <div className="flex flex-col items-center">
        <Link
          className="flex flex-col items-center"
          to={home !== undefined ? `/categories/supermarket/${makeSlug(home.name)}?category=${home?.id}` : '#'}>
          <div className="blob blob4 mb-2 border  border-[#000000] hover:border-green-400">
            <Image src={homeImg} />
          </div>
          <span className="text-[#4D4D4D]">Home & Office</span>
        </Link>
      </div>
      <div className="flex flex-col items-center">
        <Link
          className="flex flex-col items-center"
          to={health !== undefined ? `/categories/supermarket/${makeSlug(health.name)}?category=${health?.id}` : '#'}>
          <div className="blob blob5 mb-2 border  border-[#000000]  hover:border-violet-800">
            <Image src={healthImg} />
          </div>
          <span className="text-[#4D4D4D]">Health & Beauty</span>
        </Link>
      </div>
    </div>
  );
};

export default BlobFlex;
