import { atom, selector } from 'recoil';
import { cartListAtom } from '../atoms/cartAtom';
import { STORE_KEYS } from '../keys';

export const cartItemLength = selector<{
  [key: string]: number;
}>({
  key: STORE_KEYS.CART_LENGTH,
  get: ({ get }) => {
    const list = get(cartListAtom);
    const vendorOrderLengths: {
      [key: string]: number;
    } = {};

    Object.keys(list).forEach(vendorId =>
      list[vendorId].reduce((total, item) => {
        return total + item.quantity;
      }, 0)
    );

    return vendorOrderLengths;
  }
});

export const cartTotalAmount = selector<{
  [key: string]: number;
}>({
  key: STORE_KEYS.CART_AMOUNT_TOTAL,
  get: ({ get }) => {
    const list = get(cartListAtom);
    const vendorTotals: {
      [key: string]: number;
    } = {};

    Object.keys(list).forEach(vendorId =>
      list[vendorId].reduce((total, { quantity, amount }) => {
        return (total = total + quantity * amount);
      }, 0)
    );

    return vendorTotals;
  }
});
export const WasAttemptingCheckout = atom<boolean>({
  key: STORE_KEYS.WASATTEMPTINGCHECKOUT,
  default: false
});
