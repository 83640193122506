import React from 'react';
import { GoStarFill } from 'react-icons/go';
interface StarProps {
  stars: number;
}

export const GoldStars: React.FunctionComponent<StarProps> = ({ stars }) => {
  return (
    <div className="text-base flex gap-2">
      {Array.from({ length: stars }, (_, i) => (
        <GoStarFill fill="#F79009" key={i} className="w-6 h-6" />
      ))}
      {Array.from({ length: 5 - stars }, (_, i) => (
        <GoStarFill fill="#D3D3D3" key={i} className="w-6 h-6" />
      ))}
    </div>
  );
};
