import { Grid, Theme, useMediaQuery } from '@mui/material';
import { useState } from 'react';

import { DebitCardIcon, LockIcon, ProfileIcon, ShipIcon } from 'assets/Icons';
import {
  ChangePassword,
  CustomTab,
  CustomTabs,
  ProfileDetails,
  TabPanel,
  UserCards,
  a11yProps
} from 'components/molecules';
import DeleteAccount from 'pages/DeleteAccount';
import { HeaderCON } from '../styles';
import { ProfileRight } from './styles';
import { VerifyIdentity } from '../VerifyIdentity/VerifyIdentity';
import { DeliveryAddress } from '../DeliveryAddress/DeliveryAddress';
import { AiOutlineUserDelete } from 'react-icons/ai';
import { IoCheckmarkDone } from 'react-icons/io5';
import { useRecoilValue } from 'recoil';
import { userRoleAtom } from 'store/atoms/userAtom';

export const Profile = () => {
  const [currentRoute, setCurrentRoute] = useState(0);
  const userRole = useRecoilValue(userRoleAtom);

  const lg = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  const handleChange = (_: React.SyntheticEvent<Element, Event>, value: any) => {
    setCurrentRoute(value);
  };

  return (
    <>
      {!lg && <HeaderCON>Profile</HeaderCON>}
      <Grid container gap={'33px'}>
        <Grid xs={12} lg={3.5} item>
          <CustomTabs
            value={currentRoute}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            orientation={lg ? 'horizontal' : 'vertical'}>
            <CustomTab
              label="Personal Details"
              {...a11yProps(0)}
              disableRipple
              icon={<ProfileIcon />}
              iconPosition="start"
            />
            <CustomTab label="Password" {...a11yProps(1)} disableRipple icon={<LockIcon />} iconPosition="start" />
            <CustomTab
              label="Delivery Address"
              {...a11yProps(2)}
              disableRipple
              icon={<ShipIcon />}
              iconPosition="start"
            />
            {userRole === 'BUSINESS' && (
              <CustomTab
                label="Verify Identity"
                {...a11yProps(3)}
                disableRipple
                icon={<IoCheckmarkDone className="font-bold text-2xl" />}
                iconPosition="start"
              />
            )}
            <CustomTab
              label="Delete Account"
              {...a11yProps(userRole === 'BUSINESS' ? 4 : 3)}
              disableRipple
              icon={<AiOutlineUserDelete className="font-bold text-2xl" />}
              iconPosition="start"
            />
          </CustomTabs>
        </Grid>

        <Grid xs={12} lg={7.7} item>
          <ProfileRight>
            <TabPanel value={currentRoute} index={0}>
              <ProfileDetails />
            </TabPanel>
            <TabPanel value={currentRoute} index={1}>
              <ChangePassword />
            </TabPanel>
            <TabPanel value={currentRoute} index={2}>
              <DeliveryAddress />
            </TabPanel>
            {userRole === 'BUSINESS' && (
              <TabPanel value={currentRoute} index={3}>
                <VerifyIdentity />
              </TabPanel>
            )}
            <TabPanel value={currentRoute} index={userRole === 'BUSINESS' ? 4 : 3}>
              <DeleteAccount />
            </TabPanel>
          </ProfileRight>
        </Grid>
      </Grid>
    </>
  );
};
