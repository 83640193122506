import { atom } from 'recoil';
import { CheckoutDetails } from '../../TYPES/api.body';
import { STORE_KEYS } from '../keys';

let date = new Date();
date.setDate(date.getDate() + 14);
export const DEFAULT_CHECKOUT_DETAILS: CheckoutDetails = {
  deliveryMethod: 'pickup',
  paymentMode: 'PayStack',
  shippingFee: 0,
  grandTotal: 0,
  has10PercentDiscount: false,
  total: 0,
  paymentStatus: 'pending',
  logistic: null,
  scheduleDate: null,
  paymentTrxRef: '',
  paymentType: '',
  deliveryAgentId: undefined,
  addressId: undefined,
  deliverySchedule: 'Immediately',
  driverNote: '',
  subAmount: 0,
  request_token: '',
  points: 0,
  recurringDelivery: false,
  recurringDeliveryEndDate: date.toISOString().slice(0, 10)
};

export const checkoutDetailsAtom = atom<CheckoutDetails>({
  key: STORE_KEYS.CHECKOUT_DETAILS,
  default: DEFAULT_CHECKOUT_DETAILS
});

interface checkLoanInsuranceInterface {
  id: number;
  burglaryPercentage: number;
  criticalIllnessPercentage: number;
  deathPercentage: number;
  lossOfJobPercentage: number;
  others: number;
  permanentDisabilityPercentage: number;
  temporaryDisabilityPercentage: number;
  total: number;
}

export const loanInsuranceAtom = atom<checkLoanInsuranceInterface>({
  key: STORE_KEYS.LOAN_INSURANCE,
  default: {
    id: 0,
    burglaryPercentage: 0,
    criticalIllnessPercentage: 0,
    deathPercentage: 0,
    lossOfJobPercentage: 0,
    others: 0,
    permanentDisabilityPercentage: 0,
    temporaryDisabilityPercentage: 0,
    total: 0
  }
});

export const loanTenuresAtom = atom<any>({
  // ResponseContent<any>
  key: STORE_KEYS.LOAN_TENURES,
  default: []
});

export const loanSettingsAtom = atom<any>({
  key: STORE_KEYS.LOAN_SETTINGS,
  default: {}
});
