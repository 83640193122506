import React from 'react';
import { Link, To } from 'react-router-dom';

interface TypeFillerProps {
  name: string;
  image: any;
  to: To;
}
const CategoryFilter: React.FC<TypeFillerProps> = ({ name, image, to }) => {
  return (
    <Link to={to} className="min-w-[1px]">
      <div className={`w-fit   max-w-fit flex items-center gap-3 `}>
        <div className="w-10 h-10">
          <img src={image} alt={image} className="w-10 h-10 rounded-full object-cover" />
        </div>
        <p className="whitespace-nowrap">{name}</p>
      </div>
    </Link>
  );
};

export default CategoryFilter;
