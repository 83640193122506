import { Scrollable } from 'components/atoms';
import QUERY_KEYS from 'network/config/queryKeys';
import { getProductsBySearch } from 'network/services/goods';
import { ProductsGrid } from 'pages/Products';
import { CategorySection } from 'pages/Products/styles';
import React from 'react';
import { useInfiniteQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { GoodsGridGoodsGridCon } from './style';

const LIMIT = 5;
export const SupermarketGoodsFromSearch = () => {
  const [searchParams] = useSearchParams();
  const query = searchParams.get('query');
  const [enabled, setEnabled] = React.useState(false);

  const { data, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: [QUERY_KEYS.PRODUCTS_BY_SEARCH, query],
    enabled,
    onSuccess: () => setEnabled(false),
    queryFn: ({ pageParam = 0 }) => getProductsBySearch({ search: query ?? '', size: LIMIT, page: pageParam }),
    getNextPageParam: (lastPage, allPages) => ((lastPage?.data ?? []).length === LIMIT ? allPages.length : undefined)
  });

  const fetchMore = () => {
    if (hasNextPage) {
      fetchNextPage();
    }
  };

  React.useEffect(() => setEnabled(true), [query]);

  return (
    <GoodsGridGoodsGridCon>
      <Scrollable onScrollBottom={fetchMore}>
        <CategorySection>
          <h1>{query}</h1>
          <ProductsGrid
            emptyMessage="Unfortunately, no products matching your search were found"
            skeletonCount={12}
            isLoading={(isFetching || isFetchingNextPage) && enabled}
            products={(data?.pages ?? []).map(page => page.data ?? []).flat()}
          />
        </CategorySection>
      </Scrollable>
    </GoodsGridGoodsGridCon>
  );
};
