import { atom } from 'recoil';
import { STORE_KEYS } from '../keys';

export interface IWishListItem {
  id: number;
  name: string;
  amount: number;
  shortDSC: string;
  img: string;
  imgs?: string[];
  isLiked: boolean;
  atrs?: { type: string; atr: string[] }[];
}

export type IWishList = IWishListItem[];

export const wishListState = atom<IWishList>({
  key: STORE_KEYS.WISH_LIST,
  default: []
});
