import { FiMinus, FiPlus } from 'react-icons/fi';

type IProductQtyCounter = {
  value: number;
  setValue: (arr: number) => void;
  customerType?: 'REGURAR' | 'BUSINESS';
  max?: number;
  minimumOrderQuantity?: number;
};

export const ProductQtyCounter = ({ value, setValue, customerType, max, minimumOrderQuantity }: IProductQtyCounter) => {
  return (
    <div className="flex items-center gap-2">
      <button
        className="outline-none text-sm p-1 rounded-full aspect-square border border-black/30"
        onClick={() => {
          if (value > 1) {
            setValue(value - 1);
          }
        }}>
        <FiMinus />
      </button>
      <input
        data-business={customerType === 'BUSINESS'}
        min={customerType === 'BUSINESS' ? minimumOrderQuantity : 1}
        step="any"
        type="number"
        disabled={customerType !== 'BUSINESS'}
        value={value}
        onChange={e => setValue(Number(e.currentTarget.value))}
        className="bg-transparent data-[business=true]:border border-black/10 hover:border-black/50 transition-all rounded py-1 w-12 text-center font-light text-sm outline-none [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
      />
      <button
        className="outline-none text-sm p-1 rounded-full aspect-square border border-black/30"
        onClick={() => {
          if (max && value >= max) {
            return;
          }
          setValue(value + 1);
        }}>
        <FiPlus />
      </button>
    </div>
  );
};
