import { Autocomplete, TextField } from '@mui/material';
import { HidePassword, ShowPassword } from 'assets/Icons';
import { stateinNigeria } from 'assets/mockData';
import { Button, Container, Spacer } from 'components/atoms';
import { InputField } from 'components/atoms/InputField/InputField';
import { ChangeEvent, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useRecoilState } from 'recoil';
import { businessRegDetails } from 'store/atoms/businessKYB';
import { validators } from 'utils/validator';
import { AltSignUp, buttonStyle, extraStylesInput, extraStylesInputPhone, InputCon, TogglePassword } from './styles';

const INITIALSTATE = {
  businessName: '',
  street: '',
  password: '',
  cacRegNo: '',
  state: '',
  websiteUrl: '',
  businessEmail: '',
  businessPhoneNumber: ''
};

const SignUp = () => {
  const [formError, setFormError] = useState(INITIALSTATE);
  const [businessFormData, setBusinessFormData] = useRecoilState(businessRegDetails);

  const setFormInfo = (name: string, value: unknown) => {
    const error = validators[name](value);
    setBusinessFormData(curr => ({
      ...curr,
      request: { ...curr.request, [name]: value }
    }));
    setFormError({
      ...formError,
      [name]: error
    });
  };

  const onUpdateFormData = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    const error = validators[name](value);

    setBusinessFormData(curr => ({
      ...curr,
      request: { ...curr.request, [name]: value }
    }));

    setFormError({
      ...formError,
      [name]: error
    });
  };

  const [showPassword, setShowPassword] = useState<'text' | 'password'>('password');
  const onTogglePassword = () => {
    setShowPassword(showPassword === 'text' ? 'password' : 'text');
  };

  const _handleSubmit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setBusinessFormData(curr => ({ ...curr, step: 2 }));
  };

  const disabled =
    !businessFormData.request.businessName ||
    !businessFormData.request.password ||
    !businessFormData.request.street ||
    !businessFormData.request.businessPhoneNumber ||
    !businessFormData.request.businessEmail ||
    !businessFormData.request.cacRegNo ||
    !businessFormData.request.websiteUrl ||
    !businessFormData.request.state;

  return (
    <Container>
      <form className="sign-up">
        <AltSignUp>
          <InputCon>
            <span>Business Name</span>
            <InputField
              type="text"
              name="businessName"
              value={businessFormData.request.businessName}
              onChange={onUpdateFormData}
              placeholder="Business Name"
              extraStyles={extraStylesInput}
              required={true}
              error={formError.businessName}
            />
          </InputCon>
          <Spacer height="2rem" width="2rem" />
          <InputCon>
            <span>Business Email</span>
            <InputField
              type="email"
              name="businessEmail"
              value={businessFormData.request.businessEmail}
              onChange={onUpdateFormData}
              placeholder="example@mail.com"
              extraStyles={extraStylesInput}
              required={true}
              error={formError.businessEmail}
              autoComplete="username"
            />
          </InputCon>
        </AltSignUp>
        <Spacer height="1.5rem" />
        <AltSignUp>
          <InputCon>
            <span>Business Street</span>
            <InputField
              type="text"
              name="street"
              value={businessFormData.request.street}
              onChange={onUpdateFormData}
              placeholder="Address"
              extraStyles={extraStylesInput}
              required={true}
              error={formError.street}
            />
          </InputCon>
          <Spacer height="2rem" width="2rem" />
          <InputCon>
            <span>Business Phone Number</span>
            <PhoneInput
              country={'ng'}
              value={businessFormData.request.businessPhoneNumber}
              onChange={phone => {
                setBusinessFormData(curr => ({
                  ...curr,
                  request: { ...curr.request, businessPhoneNumber: phone }
                }));
              }}
              placeholder={'80XXXXXXXX'}
              buttonStyle={buttonStyle}
              autoFormat={false}
              inputStyle={extraStylesInputPhone}
            />
            <p>{formError.businessPhoneNumber}</p>
          </InputCon>
        </AltSignUp>
        <Spacer height="1.5rem" />
        <InputCon>
          <span>State</span>
          <Autocomplete
            options={stateinNigeria}
            onChange={(e, val) => {
              setFormInfo('state', val);
            }}
            value={businessFormData.request.state}
            sx={{ borderRadius: 15 }}
            renderInput={params => <TextField {...params} placeholder="Select State" sx={{ borderRadius: '15px' }} />}
          />
        </InputCon>
        <Spacer height="1.5rem" />
        <InputCon>
          <span>Password</span>
          <InputField
            type={showPassword}
            name="password"
            value={businessFormData.request.password}
            onChange={onUpdateFormData}
            placeholder="enter your password"
            extraStyles={extraStylesInput}
            required={true}
            error={formError.password}
            action={
              <TogglePassword onClick={onTogglePassword}>
                {showPassword !== 'text' ? <ShowPassword /> : <HidePassword />}
              </TogglePassword>
            }
            autoComplete="new-password"
          />
        </InputCon>

        <Spacer height="1.5rem" />
        <AltSignUp>
          <InputCon>
            <span>Website URL</span>
            <InputField
              type="text"
              name="websiteUrl"
              value={businessFormData.request.websiteUrl}
              onChange={onUpdateFormData}
              placeholder="website Url"
              extraStyles={extraStylesInput}
              error={formError.websiteUrl}
            />
          </InputCon>
          <Spacer height="2rem" width="2rem" />
          <InputCon>
            <span>CAC Reg No</span>
            <InputField
              type="text"
              name="cacRegNo"
              value={businessFormData.request.cacRegNo}
              onChange={onUpdateFormData}
              placeholder="CAC Registration Number"
              extraStyles={extraStylesInput}
              required={true}
              error={formError.cacRegNo}
            />
          </InputCon>
        </AltSignUp>
        <Spacer height="4rem" />

        <div className="buttonContainer">
          <Button type="submit" onClick={_handleSubmit} disabled={disabled}>
            Continue
          </Button>
        </div>
      </form>
    </Container>
  );
};

export default SignUp;
