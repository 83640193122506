import { Product as ProductType } from 'TYPES/api.body';
import { Empty } from 'components/atoms';
import { Product } from 'components/molecules/Goods/Product';
import { ProductSkeletons } from 'pages/Products';
import React from 'react';
import { GridContainer } from 'styles/grids';

interface Props {
  products: ProductType[];
  seller: {
    seller_logo: string;
    businessName: string;
    id: number;
  };
  diva?: any;
  isLoading: boolean;
  skeletonCount?: number;
  emptyMessage?: string;
  showEmptyPrompt?: boolean;
  emptyText?: string;
}

const ProductsGrid: React.FC<Props> = ({
  diva,
  products,
  seller,
  isLoading,
  skeletonCount,
  showEmptyPrompt = true,
  emptyText
}) => {
  return (
    <>
      <GridContainer>
        {products?.map(product => (
          <div key={product?.productId}>
            <Product product={{ ...product, seller }} />
          </div>
        ))}
        {isLoading && <ProductSkeletons count={skeletonCount ?? 6} />}
      </GridContainer>
      {!isLoading && showEmptyPrompt && products.length < 1 && (
        <Empty message={emptyText ?? 'Unfortunately, there are no items in this category.'} />
      )}
    </>
  );
};

export default ProductsGrid;
