export enum COLORS {
  MainBlue = '#2F4DC4',
  SecondaryBlue = '#3E5AC8',
  SecBlue = '#E0E4F6',
  MainBlack = '#180101',
  SecBlack = '#483D3D',
  MainGreen = '#1AB66E',
  White = '#FFFFFF',
  MainRed = '#F04438',
  DarkRed = '#C52929',
  MildGray = '#F8F7F7',
  MainGray = '#E6E6E6',
  MildestGray = '#F9F9F9',
  DarkBlue = '#0F183B',
  Disabled = '#CCC',
  Transparent = 'transparent'
}
