import { Empty, Scrollable, Spacer } from 'components/atoms';
import { CatergoriesCON } from 'pages/GoodsByCategories/style';
import useVendorsByCategoriesHook from 'pages/VendorsByCategories/useVendorsByCategoriesHook';
import VendorsGrid from 'pages/VendorsByCategories/VendorsGrid';

const VendorsCategories = () => {
  const { fetchMoreCategoryVendors, categoryVendorsData, categoryVendorsFetching } = useVendorsByCategoriesHook();

  // Filter out any undefined or invalid vendor objects
  const vendors = (categoryVendorsData?.pages.flat() ?? []).filter(vendor => vendor?.seller?.id);

  return (
    <CatergoriesCON>
      {(vendors ?? []).length > 0 && (
        <Scrollable onScrollBottom={fetchMoreCategoryVendors}>
          <VendorsGrid
            vendors={vendors}
            diva={vendors}
            isLoading={categoryVendorsFetching}
            emptyText="No vendors in this category"
          />
        </Scrollable>
      )}

      {vendors.length === 0 && !categoryVendorsFetching && (
        <Empty message="Oops! There are no vendors available in this category at the moment. Please check back later or explore other categories." />
      )}

      <Spacer height={'2.5rem'} />
    </CatergoriesCON>
  );
};

export default VendorsCategories;
