import { Divider, Grid } from '@mui/material';
import { WalletIcon } from 'assets/Icons';
import { Spacer } from 'components/atoms';
import { NewUseUpdatedCart } from 'components/molecules/Cart/hooks';
import { CheckoutProduct, PointModal } from 'pages/Checkout/component';
import { CheckoutItems, PriceSummary } from 'pages/Checkout/style';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { cartListAtom } from 'store/atoms/cartAtom';
import { checkoutDetailsAtom } from 'store/atoms/checkoutAtom';
import checkoutVariablesAtom from 'store/atoms/checkoutVariables';
import { pointsAtom } from 'store/atoms/points';
import { userRoleAtom } from 'store/atoms/userAtom';
import { formatCurrency } from 'utils/currencyFormater';

const CheckoutSummary = () => {
  const { orderId } = useParams();
  const navigate = useNavigate(); // useNavigate hook for routing
  const [points, setPoints] = useRecoilState(pointsAtom);
  const [user, checkNewUser] = useState('');
  const userRole = useRecoilValue(userRoleAtom);

  const checkoutDetails = useRecoilValue(checkoutDetailsAtom);
  const checkoutVariables = useRecoilValue(checkoutVariablesAtom);

  const serverCart = NewUseUpdatedCart()?.data;
  const cartOrder = serverCart?.orders?.find(order => order?.vendorId === Number(orderId));

  const subTotal = cartOrder?.totalAmount || 0;

  // Reroute to /account/orders if cartOrder is undefined
  useEffect(() => {
    if (!cartOrder) {
      navigate('/account/orders'); // Redirect when cartOrder is undefined
    }
  }, [cartOrder, navigate]);

  useEffect(() => {
    return checkNewUser(sessionStorage.getItem('newuser') ?? 'false');
  }, []);

  return (
    <>
      <Grid item xs={12} md={4.5} lg={3.75}>
        <CheckoutItems>
          <h3>Your Items</h3>
          <Spacer height={20} />
          <Divider />
          <Spacer height={35} />
          <div>
            {cartOrder?.products?.map((item, index) => {
              return (
                <div key={index}>
                  {index > 0 && <Spacer height={35} />}
                  <CheckoutProduct item={{ ...item, index }} orderId={cartOrder?.id} customerType={userRole} />
                </div>
              );
            })}
          </div>
        </CheckoutItems>
        <PriceSummary>
          {user === 'true' && (
            <div>
              <p className="font-bold text-sm">10% Discount On Your First Transaction 🥳</p>
            </div>
          )}
          <div className="subtotal">
            <p>Subtotal</p>
            <p>{formatCurrency(cartOrder?.totalAmount || 0)}</p>
          </div>
          <div className="delivery">
            <p>Delivery</p>
            <p>{formatCurrency(checkoutDetails.shippingFee ?? 0)}</p>
          </div>
          <div className="total">
            <p>Total</p>
            <p>{formatCurrency(subTotal + (checkoutDetails.shippingFee ?? 0))}</p>
          </div>

          <div className="wallet">
            <p className="wallet-point" onClick={() => setPoints(c => ({ ...c, open: true }))}>
              <WalletIcon /> <span className="wallet-text">Point from Wallet</span>
            </p>
            <p className="flex items-center gap-2">
              <span>-</span>
              <span>
                {formatCurrency(
                  `${
                    isNaN(checkoutVariables.pointsUsed.pointNairaEquivalent)
                      ? 0
                      : checkoutVariables.pointsUsed.pointNairaEquivalent
                  }`
                )}
              </span>
            </p>
          </div>
          <div className="grandtotal">
            <p>Grand Total</p>
            <p>
              {formatCurrency(
                subTotal +
                  (checkoutDetails.shippingFee ?? 0) -
                  (isNaN(checkoutVariables.pointsUsed.pointNairaEquivalent)
                    ? 0
                    : checkoutVariables.pointsUsed.pointNairaEquivalent)
              )}
            </p>
          </div>
        </PriceSummary>
      </Grid>
      {points.open && <PointModal />}
    </>
  );
};

export default CheckoutSummary;
