import { ListItem, Skeleton } from '@mui/material';
import useLogout from 'hooks/logout';
import { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { To } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { HamBurger } from '../../../assets/Icons';
import { userIsLoggedIn } from '../../../store/atoms/userAtom';
import { FrontNavLinks, Spacer } from '../../atoms';
import CategoryFilter from './CategoryFilter';
import { NavCon, SkeletonCon } from './style';

type FrontSideNavType = {
  navs: {
    to: To;
    id?: number;
    name: string;
    logo?: any;
    Icon?: any;
    minimize?: boolean;
  }[];
  isLoading?: boolean;
  title: string;
  signout?: boolean;
  disable?: boolean;
};

export const FrontSideNav = ({ navs, isLoading, title, signout, disable }: FrontSideNavType) => {
  const [checked, setChecked] = useState(true);
  const [activeNav, setActiveNav] = useState<string | null>(null); // Track active nav

  const isLogged = useRecoilValue(userIsLoggedIn);
  const logout = useLogout();

  const isSmallScreen = useMediaQuery({ query: '(max-width: 900px)' });
  const handleChange = () => {
    setChecked(prev => !prev);
  };

  const handleNavClick = (name: string) => {
    setActiveNav(name); // Set the clicked item as active
  };

  const _logout = async () => {
    logout();
  };

  return (
    <NavCon>
      <div className="">
        {!isSmallScreen && (
          <div className="menu">
            {!disable && (
              <>
                <HamBurger onClick={handleChange} />
                <Spacer width="1.3rem" />
              </>
            )}
            <h2>{title}</h2>
          </div>
        )}
        {!isLoading ? (
          isSmallScreen ? (
            <div className="flex gap-x-6 overflow-x-scroll lg:overflow-x-hidden mb-10 ">
              {navs?.length > 0 &&
                navs.map(({ name, logo, to }, i) => (
                  <div
                    key={i}
                    onClick={() => handleNavClick(name)}
                    className={`px-5 py-3 rounded-md ${
                      activeNav === name ? 'bg-secondary/50 text-white' : 'bg-white text-[#454545]'
                    }`}>
                    <CategoryFilter name={name} image={logo} to={to} />
                  </div>
                ))}
            </div>
          ) : (
            navs?.length > 0 &&
            navs.map(({ to, name, logo }, i) => (
              <ListItem key={i} disablePadding>
                <FrontNavLinks img={logo} label={name} to={to} minimize={false} />
              </ListItem>
            ))
          )
        ) : (
          Array(10)
            .fill(0)
            .map((_, i) => (
              <SkeletonCon key={i}>
                <Skeleton width="280px" height="60px" />
              </SkeletonCon>
            ))
        )}
        {signout && isLogged && (
          <ListItem disablePadding>
            <div className="logout" onClick={_logout}>
              Logout
            </div>
          </ListItem>
        )}
      </div>
    </NavCon>
  );
};
