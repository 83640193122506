import { Route, Routes } from 'react-router-dom';

import AssistedShopping from 'pages/AssistedShopping';
import Supermarket from 'pages/Supermarket';
import PageLayout from '../components/layout/PageLayout';
import ForgotPassword from '../pages/Auth/ForgotPassword';
import Login from '../pages/Auth/Login';
import NewPassword from '../pages/Auth/NewPassword';
import Otp from '../pages/Auth/Otp';
import SignUp from '../pages/Auth/SignUp';
import CheckoutPage from '../pages/Checkout';
import ComingSoon from '../pages/ComingSoon';
import AccountRoutes from './AccountRoutes/AccountRoutes';
import CategoriesRoutes from './CategoriesRoutes/Categories';
import HomeRoutes from './HomeRoutes/HomeRoutes';
import ProtectedRoute from './ProtectedRoutes';
import {
  ACCOUNT,
  ASSISTED_SHOPPING,
  CATEGORIES,
  CHECKOUT,
  COMING_SOON,
  FORGOT_PASSWORD,
  HOME,
  LOGIN,
  NEW_PASSWORD,
  OTP,
  RESET_PASSWORD,
  SIGN_UP,
  SUPERMARKET
} from './routes';

export const AppNavigation = () => {
  return (
    <>
      <Routes>
        <Route path={COMING_SOON} element={<ComingSoon />} />

        <Route element={<ProtectedRoute />}>
          <Route path={SIGN_UP} element={<SignUp />} />
          <Route path={LOGIN} element={<Login />} />
          <Route path={OTP} element={<Otp />} />
          <Route path={FORGOT_PASSWORD} element={<ForgotPassword />} />
          <Route path={NEW_PASSWORD} element={<NewPassword />} />
          <Route path={RESET_PASSWORD} element={<Otp />} />
        </Route>

        <Route element={<PageLayout out />}>
          <Route path={`${HOME}/*`} element={<HomeRoutes />} />
          <Route path={`${CATEGORIES}/*`} element={<CategoriesRoutes />} />
          <Route path={`${SUPERMARKET}`} element={<Supermarket />} />
          <Route path={`${CHECKOUT}`} element={<CheckoutPage />} />
          <Route path={`${CHECKOUT}/:orderId`} element={<CheckoutPage />} />
          <Route path={`${ASSISTED_SHOPPING}`} element={<AssistedShopping />} />
        </Route>

        <Route element={<ProtectedRoute authRequired />}>
          <Route path={`${ACCOUNT}/*`} element={<AccountRoutes />} />
        </Route>
      </Routes>
    </>
  );
};
