import { AddToCartArgs, CustomerType, Product, RemoveFromCartArgs, UpdateCartItemQuantityArgs } from 'TYPES/api.body';
import LOCALSTORAGE_KEYS from 'constants/localStorage';
import QUERY_KEYS from 'network/config/queryKeys';
import { enqueueSnackbar } from 'notistack';
import { QueryClient, UseMutateAsyncFunction } from 'react-query';
import { CartItem, CartState } from 'store/atoms/cartAtom';

const handleAddMutateError = (err: any) => {
  if (err?.response?.data?.message) {
    enqueueSnackbar(err?.response?.data?.message, {
      variant: 'error'
    });
  } else {
    enqueueSnackbar('Failed to update product quantity', {
      variant: 'error'
    });
  }
};

export interface CartProduct extends Product {
  maxWeight: any;
  quantity: number;
  size: any;
  totalAmount: number;
}
export interface ServerCart {
  id: number;
  submitted: boolean;
  orders: {
    id: number;
    customer: {
      name: string;
    };
    orderRef: string;
    cartSize: number;
    totalAmount: number;
    shippingFee: number;
    orderStatus: string;
    paymentStatus: string;
    orderReturned: boolean;
    products: CartProduct[];
    totalItems: number;
    vendorId: number;
    vendorEmail: string;
    vendorName: string;
    vendorPhone: string;
    vendorLogo: string;
    vendorAddress: any;
    vendorCategory: string;
  }[];
}

export const syncLocalAndServerCart = (
  serverCart: ServerCart,
  addMutate: UseMutateAsyncFunction<any, unknown, AddToCartArgs, unknown>,
  updateMutate: UseMutateAsyncFunction<any, unknown, UpdateCartItemQuantityArgs, unknown>,
  removeMutate: UseMutateAsyncFunction<any, unknown, RemoveFromCartArgs, unknown>,
  setCartList: (valOrUpdater: CartState | ((currVal: CartState) => CartState)) => void,
  customerType?: CustomerType
) => {
  const localCartStr = localStorage.getItem(LOCALSTORAGE_KEYS.CART);

  if (localCartStr !== null) {
    const cart: CartState = {};

    serverCart.orders?.forEach(order => {
      cart[order.vendorId] = order.products.map(product => ({
        name: product?.productName ?? '',
        quantityAvailable: product?.quantityAvailable ?? 0,
        amount: product.price,
        totalAmount: product?.totalAmount,
        id: product?.productId ?? 0,
        quantity: product.quantity,
        photo: product?.images ?? [],
        shortDSC: product?.description ?? '',
        minimumOrderQuantity: product?.minimumOrderQuantity ?? 0,
        sellerId: product?.vendorId ?? 0,
        sellerName: product?.vendorName ?? ''
      }));
    });

    if (customerType !== 'BUSINESS') {
      let allServerProducts: CartItem[] = [];

      Object.keys(cart)?.forEach(sellerId => {
        if (!cart[sellerId]) {
          cart[sellerId] = [];
        }
        allServerProducts = [...allServerProducts, ...cart[sellerId]];
      });

      const serverIds = allServerProducts.map(item => item.id);
      const localCart: CartState = JSON.parse(localCartStr);
      let allLocalProducts: CartItem[] = [];

      Object.keys(localCart)?.forEach(sellerId => {
        if (!localCart[sellerId]) {
          localCart[sellerId] = [];
        }
        allLocalProducts = [...allLocalProducts, ...localCart[sellerId]];
      });

      const localIds = allLocalProducts.map(item => item.id);
      const itemsToAdd: CartItem[] = [];

      allLocalProducts?.forEach(item => {
        if (serverIds.includes(item.id)) {
          const toUpdateIndex = cart[item.sellerId]?.findIndex(i => item.id === i.id);

          if (toUpdateIndex >= 0) {
            cart[item.sellerId] = cart[item.sellerId].map((existingItem, index) =>
              index === toUpdateIndex ? { ...existingItem, quantity: item.quantity } : existingItem
            );
          }

          setCartList({ ...cart });
          performUpdateCartItemQty(
            item.quantity,

            item,
            serverCart.id,
            updateMutate,
            removeMutate
          );
        } else {
          itemsToAdd.push(item);
        }
      });

      const newCart: CartState = {};
      itemsToAdd?.forEach(item => {
        if (!newCart[item.sellerId]) {
          newCart[item.sellerId] = [];
        }
        newCart[item.sellerId].push(item);
      });

      if (itemsToAdd.length > 0) {
        setCartList({ ...newCart });
        addMutate({
          items: itemsToAdd.map(item => ({
            product_id: item.id,
            quantity: item.quantity,
            amount: item.amount,
            minimumOrderQuantity: item.minimumOrderQuantity
          })),
          customerType
        });
      }
    }
    localStorage.removeItem(LOCALSTORAGE_KEYS.CART);
  }
};

export const localAddProductToCart = (
  qty: number,
  oldCart: CartState,
  product: CartItem,
  sellerId: number,
  setCartList: (valOrUpdater: CartState | ((currVal: CartState) => CartState)) => void
) => {
  const cart = { ...oldCart };
  let existingSellerInCart = cart[sellerId];
  if (existingSellerInCart) {
    const existingCartItemIndex = existingSellerInCart?.findIndex(item => item.id === product.id);
    const sellerCartCopy = [...cart[sellerId]];

    if (existingCartItemIndex >= 0) {
      const itemCopy = { ...cart[sellerId][existingCartItemIndex] };
      itemCopy.quantity = itemCopy.quantity + qty;
      sellerCartCopy[existingCartItemIndex] = itemCopy;
    } else {
      sellerCartCopy.push(product);
    }
    cart[sellerId] = sellerCartCopy;
  } else {
    cart[sellerId] = [product];
  }

  localStorage.setItem(LOCALSTORAGE_KEYS.CART, JSON.stringify(cart));
  setCartList(cart);
};

export const localRemoveProductFromCart = (
  cart: CartState,
  sellerId: number,
  index: number,
  setCartList: (valOrUpdater: CartState | ((currVal: CartState) => CartState)) => void
) => {
  cart[sellerId].splice(index, 1);
  localStorage.setItem(LOCALSTORAGE_KEYS.CART, JSON.stringify(cart));
  setCartList(cart);
};

export const localUpdateCartItemQuantity = (
  newQty: number,
  cart: CartState,
  sellerId: number,
  product: CartItem,
  setCartList: (valOrUpdater: CartState | ((currVal: CartState) => CartState)) => void
) => {
  let newCart: CartState = { ...cart };
  const index = cart[sellerId].findIndex(item => item.id === product.id);
  if (newQty === 0) {
    newCart[sellerId] = newCart[sellerId].filter(item => item.id !== product.id);
    localStorage.setItem(LOCALSTORAGE_KEYS.CART, JSON.stringify(newCart));
    setCartList(newCart);
  } else if (newQty === 1 && index < 0) {
    newCart[sellerId] = [...cart[sellerId], { ...product, quantity: 1 }];
    localStorage.setItem(LOCALSTORAGE_KEYS.CART, JSON.stringify(newCart));
    setCartList(newCart);
    enqueueSnackbar(`${product.name} added to cart`, { variant: 'success' });
  } else {
    if (index >= 0) {
      newCart[sellerId][index] = { ...newCart[sellerId][index], quantity: newQty };
    }
    localStorage.setItem(LOCALSTORAGE_KEYS.CART, JSON.stringify(newCart));
    setCartList(newCart);
  }
};

export const performAddProductToCart = async (
  serverCart: any,
  productId: any,
  quantity: number,
  orderId: any,
  addMutate: UseMutateAsyncFunction<any, unknown, AddToCartArgs, unknown>,
  updateMutate: UseMutateAsyncFunction<any, unknown, UpdateCartItemQuantityArgs, unknown>,
  removeMutate: UseMutateAsyncFunction<any, unknown, RemoveFromCartArgs, unknown>,
  queryClient: QueryClient,
  customerType?: CustomerType
) => {
  const existingItem = serverCart?.orders
    .flatMap((order: any) => order.products)
    .find((product: any) => product.productId === productId);

  if (existingItem) {
    // Product already exists in cart, update quantity
    try {
      await performUpdateCartItemQty(
        existingItem.quantity + quantity,
        existingItem,
        orderId,
        updateMutate,
        removeMutate,
        customerType
      );
      enqueueSnackbar(`Updated quantity for ${existingItem.productName}`, { variant: 'success' });
    } catch (err) {
      handleAddMutateError(err);
    }
  } else {
    // Product not in cart, add new item
    try {
      const { data } = await addMutate({
        items: [{ product_id: productId, quantity }],
        customerType
      });

      if (data) {
        queryClient.invalidateQueries(QUERY_KEYS.CART);
        enqueueSnackbar(`Added ${quantity} item(s) to cart`, { variant: 'success' });
      } else {
        enqueueSnackbar(data?.description, { variant: 'error' });
      }
    } catch (err) {
      handleAddMutateError(err);
    }
  }
};

export const performRemoveProductFromCart = async (
  item: any,
  removeMutate: UseMutateAsyncFunction<any, unknown, RemoveFromCartArgs, unknown>,
  queryClient: QueryClient,
  customerType?: CustomerType,
  orderId?: number
) => {
  removeMutate({ id: item?.productId, customerType, orderId })
    .then(() => {
      queryClient.invalidateQueries(QUERY_KEYS.CART);
      enqueueSnackbar(`Removed from cart`, { variant: 'success' });
    })
    .catch(err => {
      enqueueSnackbar('Failed to remove item from cart', { variant: 'error' });
    });
};

export const performUpdateCartItemQty = async (
  newQty: number,
  product: any,
  orderId: number,
  updateMutate: UseMutateAsyncFunction<any, unknown, UpdateCartItemQuantityArgs, unknown>,
  removeMutate: UseMutateAsyncFunction<any, unknown, RemoveFromCartArgs, unknown>,
  customerType?: CustomerType
) => {
  if (newQty < 0) {
    removeMutate({ id: product.productId, customerType }).catch(err => console.log(err));
  } else {
    updateMutate({ orderId: orderId, qty: newQty, productId: product.productId });

    // .then(res => {
    //   console.log({ res });
    //   const itemIndex = cart[sellerId]?.findIndex(item => item.id === product.id);
    //   cart[sellerId][itemIndex] = { ...cart[sellerId][itemIndex], quantity: newQty };
    //   setCartList(cart);
    // })
    // .catch(err => console.log(err));
  }
};
