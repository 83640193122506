import { GETANYTHINGDELIVERED } from 'constants/landingPage/getAnythingDelivered';

const GetAnythingDelivered = ({ exploreclick }: any) => {
  return (
    <div className="text-[#1E1E1E] grid justify-center ">
      <h1 className="text-center text-xl md:text-2xl font-semibold mb-8 ">Get Anything Delivered</h1>
      <div className=" md:grid  lg:grid-cols-3 justify-between  w-full max-w-[1380px] gap-10 ">
        {GETANYTHINGDELIVERED.map(offer => (
          <div key={offer.title} className=" mt-4 md:mt-9">
            <div className="flex flex-col gap-2  items-center">
              <div className="w-[60px] h-[60px] md:w-[70px] md:h-[70px] grid place-content-center">
                <img src={offer.img} alt="return policy" />
              </div>
              <span className="md:text-lg  font-semibold mt-2 mb-1">{offer.title}</span>
            </div>
            <p className="text-center max-w-[90%] m-auto md:max-w-full  text-[#3D3D3D] ">{offer.description}</p>
          </div>
        ))}
      </div>

      <button
        className="py-4 md:py-[18px] px-8 md:px-10 border border-primary text-primary mt-10 md:mt-16  rounded-[999px] m-auto hover:bg-primary hover:text-white"
        onClick={exploreclick}>
        Explore stores
      </button>
    </div>
  );
};

export default GetAnythingDelivered;
