import React from 'react';

interface TypeFillerProps {
  name: string;
  isSelected: boolean;
  onClick: () => void;
}

const SubCategoryFilter: React.FC<TypeFillerProps> = ({ name, isSelected, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={`text-white py-2  whitespace-nowrap px-7 lg:py-2 lg:px-8 text-sm lg:text-base rounded-2xl lg:rounded-[20px] cursor-pointer ${
        isSelected ? 'bg-secondary' : 'bg-secondary/50 hover:bg-secondary'
      }`}>
      {name}
    </div>
  );
};

export default SubCategoryFilter;
