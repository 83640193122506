import { CircularProgress } from '@mui/material';
import { EmptyCon, Scrollable } from 'components/atoms';
import QUERY_KEYS from 'network/config/queryKeys';
import {
  getCancelledOrders,
  getCompletedOrders,
  getOngoingOrders,
  getOrderHistories,
  getRecurringOrders
} from 'network/services/order';
import React from 'react';
import { useInfiniteQuery } from 'react-query';
import { OrderCard } from '../OrderCard/OrderCard';

interface Props {
  noneMessage?: string;
  variant: 'ONGOING' | 'COMPLETE' | 'CANCELED' | 'ALL' | 'RECURRING';
}

const LIMIT = 20;
const OrderList: React.FC<Props> = ({ noneMessage, variant }) => {
  const { data, hasNextPage, fetchNextPage, isFetching, isFetchingNextPage } = useInfiniteQuery({
    queryKey: [
      variant === 'RECURRING'
        ? QUERY_KEYS.RECURRING_ORDERS
        : variant === 'ONGOING'
        ? QUERY_KEYS.ONGOING_ORDERS
        : variant === 'COMPLETE'
        ? QUERY_KEYS.COMPLETE_ORDERS
        : variant === 'CANCELED'
        ? QUERY_KEYS.RECURRING_ORDERS
        : QUERY_KEYS.ALL_ORDERS
    ],
    queryFn: ({ pageParam = 0 }) =>
      variant === 'RECURRING'
        ? getRecurringOrders({ size: LIMIT, page: pageParam })
        : variant === 'ONGOING'
        ? getOngoingOrders({ size: LIMIT, page: pageParam })
        : variant === 'COMPLETE'
        ? getCompletedOrders({ size: LIMIT, page: pageParam })
        : variant === 'CANCELED'
        ? getCancelledOrders({ size: LIMIT, page: pageParam })
        : getOrderHistories({ size: LIMIT, page: pageParam }),
    getNextPageParam: (lastPage, allPages) => ((lastPage ?? []).length === LIMIT ? allPages.length : undefined)
  });

  const orders = (data?.pages ?? []).flat();

  const fetchMore = () => {
    if (hasNextPage) {
      fetchNextPage();
    }
  };

  return (
    <>
      {isFetching ? (
        <div className="w-full flex justify-center">
          {' '}
          <CircularProgress size={30} />
        </div>
      ) : (
        <Scrollable onScrollBottom={fetchMore}>
          {orders.length > 0 &&
            orders.map(order => {
              return <OrderCard item={variant === 'RECURRING' ? order?.order : order} key={order.id} />;
            })}
        </Scrollable>
      )}

      {orders.length < 1 && !(isFetching || isFetchingNextPage) && (
        <EmptyCon>{noneMessage ?? 'No ongoing order items'}</EmptyCon>
      )}
    </>
  );
};

export default OrderList;
