import { Category } from 'TYPES/api.body';
import React from 'react';
import 'react-multi-carousel/lib/styles.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { ProductCard } from './ProductCard';

interface Props {
  product: {
    productId: number;
    productName: string;
    price: number;
    quantityAvailable: number;
    minimumOrderQuantity: number;
    bulkOrderPrice: number;
    barCode: string;
    productImage1: string | string[];
    productImage2?: string | string[];
    productImage3?: string | string[];
    productImage4?: string | string[];
    likes?: number;
    status: string;
    description: string;
    seller: {
      seller_logo: string;
      businessName: string;
      id: number;
    };
    category?: Category;
  };
}

export const Product: React.FC<Props> = ({ product }) => {
  const goto = useNavigate();
  const location = useLocation();

  const navigateProductDetail = () => {
    const search = new URLSearchParams(location.search);
    search.delete('productId');
    search.append('productId', product.productId.toString());
    goto(location.pathname.concat('?', search.toString()), { state: product });
  };

  return (
    <>
      <ProductCard {...product} setOnSeeGoodsDetails={navigateProductDetail} />
    </>
  );
};
