import ERROR_MESSAGES from 'constants/errorMessages';

export type ValidationType = Record<string, (...args: (string | any)[]) => string | undefined>;

const EMAIL_PATTERN =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const PHONE_PATTERN = /^[0-9]{10,15}$/; // Assuming phone numbers can be 10 to 15 digits

const SPECIAL_CHARACTER = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;

const BVN_PATTERN = /^\d+$/;

const URL_PATTERN = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/;

export const validators: ValidationType = {
  bvn: (bvn: string) => {
    if (!bvn) {
      return 'BVN is required';
    }

    if (!BVN_PATTERN.test(bvn)) {
      return 'BVN is invalid';
    }
  },

  nin: (bvn: string) => {
    if (!bvn) {
      return 'NIN is required';
    }

    if (!BVN_PATTERN.test(bvn)) {
      return 'NIN is invalid';
    }
  },

  email: (email: string) => {
    if (!email) {
      return 'E-mail is required';
    }

    if (!EMAIL_PATTERN.test(email)) {
      return 'E-mail is invalid';
    }
  },

  address: (name: string) => {
    if (name === '') return 'address is required';
  },
  location: (name: string) => {
    if (name === '') return 'location is required';
  },
  state: (name: string) => {
    if (name === '') return 'state is required';
  },
  city: (name: string) => {
    if (name === '') return 'city is required';
  },
  street: (name: string) => {
    if (name === '') return 'street is required';
  },

  role: (selected: string) => {
    if (selected === '') {
      return 'Select a role';
    }
  },

  fullName: (name: string) => {
    if (name === '') return 'Name is required';

    // if (!/^[^\s]+( [^\s]+)+$/.test(name)) return "Invalid name";

    // if (name.length < 5) return "Name is too short";
  },
  name: (name: string) => {
    if (name === '') return 'Name is required';

    // if (!/^[^\s]+( [^\s]+)+$/.test(name)) return "Invalid name";

    // if (name.length < 5) return "Name is too short";
  },
  firstname: (name: string) => {
    if (name === '') return 'Name is required';

    // if (!/^[^\s]+( [^\s]+)+$/.test(name)) return "Invalid name";

    // if (name.length < 5) return "Name is too short";
  },
  lastname: (name: string) => {
    if (name === '') return 'Name is required';

    // if (!/^[^\s]+( [^\s]+)+$/.test(name)) return "Invalid name";

    // if (name.length < 5) return "Name is too short";
  },

  businessName: (name: string) => {
    if (name === '') return 'Business Name is required';
  },

  businessAddress: (address: string) => {
    if (address === '') return 'Business Address is required';
  },

  businessEmail: (value: string) => {
    if (!value) return 'Business Email is required';
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(value)) return 'Invalid email format';
    return '';
  },

  cac: (cac: string) => {
    if (cac === '') return 'CAC is required';
  },
  cacRegNo: (cacRegNo: string) => {
    if (cacRegNo === '') return 'CAC Registration Number is required';
  },

  description: (name: string) => {
    if (name === '') return 'Description is required';

    // if (!/^[^\s]+( [^\s]+)+$/.test(name)) return "Invalid name";

    // if (name.length < 5) return "Name is too short";
  },
  quantity: (name: number) => {
    if (name < 1) return 'Quantity is required';

    // if (!/^[^\s]+( [^\s]+)+$/.test(name)) return "Invalid name";

    // if (name.length < 5) return "Name is too short";
  },
  price: (name: number) => {
    if (name < 1) return 'Price is required';
  },
  weight: (name: number) => {
    if (name < 1) return 'Weight is required';
  },
  phone: (number: string) => {
    if (number.length === 0) {
      return 'Phone number is required';
    }
  },
  phoneNumber: (number: string) => {
    if (number.length === 0) {
      return 'Phone number is required';
    }
  },
  businessPhoneNumber: (number: string) => {
    if (number.length === 0) {
      return 'Business Phone number is required';
    }
  },
  country: (number: string) => {
    if (number.length === 0) {
      return 'Country is required';
    }
  },

  productImage: (name: string[]) => {
    if (name.length === 0) {
      return 'Product Image is required';
    }
  },

  productWeightImage: (name: string) => {
    if (name === '') return 'Product Weight Image is required';
  },

  videoUrl: (name: string) => {
    if (name === '') return 'Product VideoUrl Image is required';
  },
  referralPhoneNumber: (number: string) => {
    if (number.length === 0) {
      return 'Number is required';
    }
  },

  otp: (number: string) => {
    if (number.length === 0) return 'OTP is required';
    if (!/^\d{4}$/.test(number)) return 'Enter four digit number';
  },

  password: (password: string) => {
    if (password.length === 0) return 'Password is required';
    if (password.length <= 8) return ERROR_MESSAGES.PASSWORD_LENGTH;
    if (!/[A-Z]/.test(password)) {
      return 'Password must include at least one uppercase letter';
    }
    if (!/[a-z]/.test(password)) {
      return 'Password must include at least one lowercase letter';
    }
    if (!/[0-9]/.test(password)) {
      return 'Password must include at least one number';
    }
    if (!SPECIAL_CHARACTER.test(password)) {
      return 'Password must include at least one special character';
    }
  },

  confirmPassword: (confirmNewPassword: string) => {
    if (confirmNewPassword.length === 0) return 'Password is required';
    if (confirmNewPassword.length <= 8) {
      return ERROR_MESSAGES.PASSWORD_LENGTH;
    }
    if (!/[A-Z]/.test(confirmNewPassword)) {
      return 'Password must include at least one uppercase letter';
    }
    if (!/[a-z]/.test(confirmNewPassword)) {
      return 'Password must include at least one lowercase letter';
    }
    if (!/[0-9]/.test(confirmNewPassword)) {
      return 'Password must include at least one number';
    }
    if (!SPECIAL_CHARACTER.test(confirmNewPassword)) {
      return 'Password must include at least one special character';
    }
  },


  confirm_password: (confirmNewPassword: string) => {
    if (confirmNewPassword.length === 0) return 'Password is required';
    if (confirmNewPassword.length <= 8) {
      return ERROR_MESSAGES.PASSWORD_LENGTH;
    }
    if (!/[A-Z]/.test(confirmNewPassword)) {
      return 'Password must include at least one uppercase letter';
    }
    if (!/[a-z]/.test(confirmNewPassword)) {
      return 'Password must include at least one lowercase letter';
    }
    if (!/[0-9]/.test(confirmNewPassword)) {
      return 'Password must include at least one number';
    }
    if (!SPECIAL_CHARACTER.test(confirmNewPassword)) {
      return 'Password must include at least one special character';
    }
  },

  oldPassword: (confirmNewPassword: string) => {
    if (confirmNewPassword.length === 0) return 'Password is required';
    if (confirmNewPassword.length <= 8) {
      return ERROR_MESSAGES.PASSWORD_LENGTH;
    }
    if (!/[A-Z]/.test(confirmNewPassword)) {
      return 'Password must include at least one uppercase letter';
    }
    if (!/[a-z]/.test(confirmNewPassword)) {
      return 'Password must include at least one lowercase letter';
    }
    if (!/[0-9]/.test(confirmNewPassword)) {
      return 'Password must include at least one number';
    }
    if (!SPECIAL_CHARACTER.test(confirmNewPassword)) {
      return 'Password must include at least one special character';
    }
  },

  old_password: (confirmNewPassword: string) => {
    if (confirmNewPassword.length === 0) return 'Password is required';
    if (confirmNewPassword.length <= 8) {
      return ERROR_MESSAGES.PASSWORD_LENGTH;
    }
    if (!/[A-Z]/.test(confirmNewPassword)) {
      return 'Password must include at least one uppercase letter';
    }
    if (!/[a-z]/.test(confirmNewPassword)) {
      return 'Password must include at least one lowercase letter';
    }
    if (!/[0-9]/.test(confirmNewPassword)) {
      return 'Password must include at least one number';
    }
    if (!SPECIAL_CHARACTER.test(confirmNewPassword)) {
      return 'Password must include at least one special character';
    }
  },

  newPassword: (newPassword: string) => {
    if (newPassword.length === 0) return 'Password is required';
    if (newPassword.length <= 8) return ERROR_MESSAGES.PASSWORD_LENGTH;
    if (!/[A-Z]/.test(newPassword)) {
      return 'Password must include at least one uppercase letter';
    }
    if (!/[a-z]/.test(newPassword)) {
      return 'Password must include at least one lowercase letter';
    }
    if (!/[0-9]/.test(newPassword)) {
      return 'Password must include at least one number';
    }
    if (!SPECIAL_CHARACTER.test(newPassword)) {
      return 'Password must include at least one special character ';
    }
  },

  identifier: (identifier: string) => {
    if (identifier.length === 0) return 'Email or Phone number is required';
    if (!EMAIL_PATTERN.test(identifier) && !PHONE_PATTERN.test(identifier)) {
      return 'Must be a valid email or phone number';
    }
  },
  websiteUrl: (url: string) => {
    if (!URL_PATTERN.test(url)) {
      return 'Website URL is invalid';
    }
  }

  
};

const validateFormSubmit = (formData: Record<string, string>) => {
  let errors: any = {};
  let isValid = true;

  Object.keys(formData).forEach(key => {
    const error = validators[key](formData[key], formData);
    errors[key] = error;
    if (error) {
      isValid = false;
    }
  });

  return { errors, isValid };
};


export const checkFormData = (formData: Record<string, any>, setFormError?: any) => {
  const { isValid, errors } = validateFormSubmit(formData);

  if (setFormError) {
    setFormError(errors);
  }
  return isValid;
};


export const isEmptyFormField = (objects: Record<string, any>) => {
  const value = Object.values(objects).includes('');
  return value;
};

export const isAmountNumber = (value: string) => {
  return value.replace(/[^,.0-9/]|\//g, '');
};
