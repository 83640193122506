import {
  AvailableCarrierPayload,
  AvailableCarrierResponse,
  CheckoutDetails,
  DeliveryRatesPayload,
  ICancelOrder,
  OrderDetailsResponse,
  OrderTrackingResponse,
  ResponseSuccess,
  ValidateAddressData,
  ValidateAddressPayload
} from '../../TYPES/api.body';
import {
  BUSINESS_SUBMIT_CART,
  CANCEL_ORDER,
  GET_ALL_ORDERS,
  GET_AVAILABLE_CARRIERS,
  GET_CANCELLED_ORDERS,
  GET_COMPLETED_ORDERS,
  GET_DELIVERY_AGENTS,
  GET_ONGOING_ORDERS,
  GET_ORDER_TRACKING,
  GET_PENDING_ORDERS,
  GET_RECURRING_ORDERS,
  GET_SHIPBUBBLE_CATEGORIES,
  GET_SHIPBUBBLE_DELIVERY_RATES,
  GET_SHIPBUBBLE_DIMENSIONS,
  GET_SINGLE_ORDER,
  SUBMIT_ORDER,
  VALIDATE_ADDRESS,
  VALIDATE_ADDRESS_DELIVERY
} from '../config/endpoints';
import api from '../config/setAuthHeader';

export const getOrderHistories = async (args?: { size?: number; page?: number }): Promise<OrderDetailsResponse[]> => {
  const { data } = await api.get(`${GET_ALL_ORDERS}?pageSize=${args?.size ?? 10}&page=${args?.page ?? 0}`);
  return data.data;
};

export const getPendingOrders = async (args?: { size?: number; page?: number }) => {
  const { data } = await api.get(`${GET_PENDING_ORDERS}?pageSize=${args?.size ?? 10}&page=${args?.page ?? 0}`);
  return data.data;
};
export const getOngoingOrders = async (args?: { size?: number; page?: number }): Promise<OrderDetailsResponse[]> => {
  const { data } = await api.get(`${GET_ONGOING_ORDERS}?pageSize=${args?.size ?? 10}&page=${args?.page ?? 0}`);
  return data.data;
};
export const getCompletedOrders = async (args?: { size?: number; page?: number }): Promise<OrderDetailsResponse[]> => {
  const { data } = await api.get(`${GET_COMPLETED_ORDERS}?pagesize=${args?.size ?? 10}&page=${args?.page ?? 0}`);
  return data.data;
};
export const getCancelledOrders = async (args?: { size?: number; page?: number }): Promise<OrderDetailsResponse[]> => {
  const { data } = await api.get(`${GET_CANCELLED_ORDERS}?pagesize=${args?.size ?? 10}&page=${args?.page ?? 0}`);
  return data.data;
};

export const getRecurringOrders = async (args?: { size?: number; page?: number }): Promise<any> => {
  const { data } = await api.get(
    `${GET_RECURRING_ORDERS}?page=${args?.page ?? 0}&size=${args?.size ?? 10}&sort=id,desc`
  );
  return data?.recurringOrders;
};

export const submitOrder = async (formData: CheckoutDetails) => {
  const { customerType, ...rest } = formData;
  if (customerType === 'BUSINESS') {
    return await api.post(BUSINESS_SUBMIT_CART, rest);
  }
  return await api.post(SUBMIT_ORDER, rest);
};

export const getSingleOrder = async (id: number): Promise<OrderDetailsResponse> => {
  const { data } = await api.get(`${GET_SINGLE_ORDER}/${id}`);
  return data?.data;
};
export const getSingleOrderdetail = async (id: number): Promise<OrderDetailsResponse> => {
  const { data } = await api.get(`${GET_SINGLE_ORDER}/${id}`);
  return data?.data;
};

export const getOrderTracking = async (id: string): Promise<OrderTrackingResponse[]> => {
  const { data } = await api.get(`${GET_ORDER_TRACKING}/${id}`);
  return data?.data;
};
export const cancelOrder = async (formData: ICancelOrder) => {
  const { id } = formData;
  const { data } = await api.post(`${CANCEL_ORDER}/${id}`, formData);
  return data?.data;
};

export const getDeliveryAgents = async () => {
  const { data } = await api.get(GET_DELIVERY_AGENTS);
  return data.data;
};

export const getShipBubbleCategories = async () => {
  const { data } = await api.get(GET_SHIPBUBBLE_CATEGORIES);
  return data.data;
};

export const getAllCarriers = async (
  opts: AvailableCarrierPayload
): Promise<ResponseSuccess<AvailableCarrierResponse[]>> => {
  const { data } = await api.post(GET_AVAILABLE_CARRIERS, opts);
  return data;
};

export const getDeliveryRates = async (opts: DeliveryRatesPayload): Promise<ResponseSuccess<any>> => {
  const { data } = await api.post(GET_SHIPBUBBLE_DELIVERY_RATES, opts);
  return data;
};

export const getShipBubbleDimensions = async () => {
  const { data } = await api.get(GET_SHIPBUBBLE_DIMENSIONS);
  return data.data;
};

export const validateAddressCallback = async (address: string): Promise<ResponseSuccess<ValidateAddressData>> => {
  const { data } = await api.get(VALIDATE_ADDRESS.concat(address));
  return data;
};

export const validateAddressDeliveryCallback = async (opts: ValidateAddressPayload): Promise<ResponseSuccess<any>> => {
  const { data } = await api.post(VALIDATE_ADDRESS_DELIVERY, opts);
  return data;
};
