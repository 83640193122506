import { Scrollable } from 'components/atoms';
import QUERY_KEYS from 'network/config/queryKeys';
import { getProductsByVendor } from 'network/services/goods';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { useInfiniteQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { CategorySection } from './styles';

interface Props {
  vendorId: number;
}

const LIMIT = 20;
const ProductsByVendor: React.FC<Props> = ({ vendorId }) => {
  const [searchParams] = useSearchParams();
  const categoryParam = searchParams.get('category');
  const categoryId = categoryParam ? parseInt(categoryParam) : undefined;
  const { fetchNextPage, hasNextPage, isFetching, data } = useInfiniteQuery({
    queryKey: [QUERY_KEYS.VENDOR_PRODUCTS, vendorId, categoryId],
    queryFn: ({ pageParam = 0 }) => getProductsByVendor({ categoryId, vendorId, size: LIMIT, page: pageParam }),
    getNextPageParam: (lastPage, allPages) => ((lastPage.data ?? []).length === LIMIT ? allPages.length : undefined),
    onError: error =>
      enqueueSnackbar(`${(error as any).response?.data?.message}`, {
        variant: 'error'
      })
  });

  return (
    <div>
      <Scrollable onScrollBottom={fetchNextPage} callWhen={hasNextPage}>
        <CategorySection>
          {/* <ProductsGrid
            emptyMessage="Unfortunately no products are currently offered by this vendor"
            isLoading={isFetching}
            products={data?.pages.map(page => page.data).flat() ?? []}
          /> */}
        </CategorySection>
      </Scrollable>
    </div>
  );
};

export default ProductsByVendor;
