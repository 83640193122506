import { Image, Loader } from '@mantine/core';
import image from 'assets/images/home-images/img-2.png';
import { FaTrash } from 'react-icons/fa6';

type CartOrdersProps = {
  vendorName: any;
  vendorLogo: any;
  orderRef: any;
  totalAmount: number;
  handleCheckout: () => void;
  deleteOrder: () => void;
  orderId: number;
  totalItems: number;
  deletingOrder: any;
};
const CartOrders: React.FunctionComponent<CartOrdersProps> = ({
  vendorName,
  vendorLogo,
  orderRef,
  totalAmount,
  handleCheckout,
  orderId,
  deleteOrder,
  totalItems,
  deletingOrder
}) => {
  return (
    <>
      <div className="grid  gap-y-4 py-10 border-b-[0.2px] border-[#D3D3D3] ">
        <div className="flex gap-3 items-center">
          <div className="relative w-10 h-10 rounded-full border overflow-hidden">
            <Image
              src={vendorLogo || image}
              alt="Vendor Logo"
              className="object-cover w-full h-full"
              onError={e => {
                e.currentTarget.src = image; // Set a fallback image
              }}
            />
          </div>
          <div className="grid items-start">
            <h2 className="text-[#1E223E] text-left text-lg font-medium">{vendorName}</h2>

            <h5 className="flex gap-2 items-center text-[#616161] text-sm">
              Order {orderRef} <span className="text-2xl">•</span> {totalItems} item{totalItems > 1 && 's'}
            </h5>
          </div>
        </div>

        <div className="flex items-center justify-between">
          <h5>
            Total : <span className="font-medium">₦ {totalAmount}</span>
          </h5>
          <div className="flex gap-3 items-center">
            <button
              className="rounded-md border border-secondary text-secondary cursor-pointer tracking-wide px-3 py-0.5"
              onClick={handleCheckout}>
              Checkout
            </button>
            {deletingOrder ? (
              <Loader color="red" size="sm" />
            ) : (
              <FaTrash className="text-red-500 cursor-pointer" onClick={deleteOrder} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CartOrders;
