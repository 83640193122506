import { Link } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import MADINA_INFO from 'constants/madina';
import { liveChatAtom } from 'store/atoms/liveChatAtom';
import { BigWhatsappIcon, CustomerSupport as CustomerSupportIcon, MailIcon } from 'assets/Icons';

const CustomerSupportWelcomeView = () => {
  const setLiveChat = useSetRecoilState(liveChatAtom);
  return (
    <div className="flex bg-white rounded-lg p-7 flex-col gap-10">
      <div className="flex flex-col gap-3">
        <h3 className="font-medium text-lg">Welcome to Madina Support</h3>
        <p className="text-black/60">You can reach us via the following channels.</p>
      </div>
      <div className="flex flex-col gap-7">
        <Link to={`mailto:${MADINA_INFO.email}`} className="flex gap-5 items-center">
          <MailIcon />
          <div className="flex flex-col justify-between">
            <p className="font-medium text-lg">Email</p>
            <p className="font-light text-sm">{MADINA_INFO.email}</p>
          </div>
        </Link>
        <Link to={MADINA_INFO.whatsApp} className="flex gap-5 items-center mt-1">
          <BigWhatsappIcon />
          <div className="flex flex-col justify-between">
            <p className="font-medium text-lg">Whatsapp</p>
            <p className="font-light text-sm">{MADINA_INFO.phone}</p>
          </div>
        </Link>
        {/* <button
          onClick={() => setLiveChat(curr => ({ ...curr, step: 1 }))}
          type="button"
          className="flex hover:bg-black/5 p-0.5 transition-all rounded py-1 gap-5 items-center">
          <CustomerSupportIcon />
          <div className="flex flex-col justify-between">
            <p className="font-medium text-lg">Live Chat</p>
            <p className="font-light text-sm">Chat with us</p>
          </div>
        </button> */}
      </div>
    </div>
  );
};

export default CustomerSupportWelcomeView;
