import {
  AnnounceIcon,
  EditIcon,
  GiftIcon,
  HeartIcon,
  OngoingIcon,
  ProfileIcon,
  DebitCardIcon,
  ShoppingBagIcon,
  SupportIcon,
  WalletIcon
} from 'assets/Icons';
import { MdOutlinePendingActions } from 'react-icons/md';

import { NavLinkProps as NavigationLink } from 'components/atoms/NavLinks/NavLinks';
import {
  ADDRESS,
  BNPL,
  DELETE_ACCOUNT,
  FAVOURITE,
  INBOX,
  ORDERS,
  PENDING_PAYMENTS,
  PROFILE,
  REFER,
  REVIEW,
  SUBSCRIPTIONS,
  SUPPORT,
  VERIFY_IDENTITY,
  WALLET
} from '../routes';

export const NAVIGATION_LINKS: readonly NavigationLink[] = [
  {
    label: 'Profile',
    to: PROFILE,
    Icon: ProfileIcon
  },
  {
    label: 'My Orders',
    to: ORDERS,
    Icon: OngoingIcon
  },
  {
    label: 'BNPL',
    to: BNPL,
    Icon: DebitCardIcon
  },
  {
    label: 'Inbox',
    to: INBOX,
    Icon: AnnounceIcon
  },
  {
    label: 'My Wallet',
    to: WALLET,
    Icon: WalletIcon
  },
  // {
  //   label: 'Delivery Address',
  //   to: ADDRESS,
  //   Icon: ShipIcon
  // },

  {
    label: 'Reviews',
    to: REVIEW,
    Icon: EditIcon
  },
  {
    label: 'Favourites',
    to: FAVOURITE,
    Icon: HeartIcon
  },
  {
    label: 'Refer a Friend',
    to: REFER,
    Icon: GiftIcon
  },
  {
    label: 'Madina Subscriptions',
    to: SUBSCRIPTIONS,
    Icon: ShoppingBagIcon
  },
  {
    label: 'Support',
    to: SUPPORT,
    Icon: SupportIcon
  },
  // {
  //   label: 'Delete account',
  //   to: DELETE_ACCOUNT,
  //   Icon: AiOutlineUserDelete
  // }
] as const;

const excludeFromBusiness = ['Profile', 'My Orders', 'Refer a Friend', 'Madina Subscriptions'];

export const BUSINESS_NAVS: NavigationLink[] = [
  ...[
    {
      label: 'Profile',
      to: PROFILE,
      Icon: ProfileIcon
    },
    {
      label: 'My Orders',
      to: ORDERS,
      Icon: OngoingIcon
    },
    {
      label: 'Pending Payments',
      to: PENDING_PAYMENTS,
      Icon: MdOutlinePendingActions
    }
  ],
  ...NAVIGATION_LINKS.filter(item => !excludeFromBusiness.includes(item.label))
];
