export interface getAnythingDeliveredProps {
  title: string;
  img: string;
  description: string;
}

export const GETANYTHINGDELIVERED: readonly getAnythingDeliveredProps[] = [
  {
    title: 'Fast Delivery',
    img: '/graphics/fastdelivery.png',
    description: 'Share your referral code and earn amazing rewards.'
  },
  {
    title: '24/7 Customer Support',
    img: '/graphics/support.png',
    description: 'Share your referral code and earn amazing rewards.'
  },
  {
    title: '14-Days Return Policy',
    img: '/graphics/returnpolicy.png',
    description: 'Share your referral code and earn amazing rewards.'
  }
];
