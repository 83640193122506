import { Tooltip } from '@chakra-ui/react';
import useLogout from 'hooks/logout';
import React from 'react';
import { TbLogout } from 'react-icons/tb';
import { Link, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { userIsLoggedIn } from 'store/atoms/userAtom';
import { NavLinkProps } from '../../atoms/NavLinks/NavLinks';

type SideNavType = {
  navs: readonly NavLinkProps[];
  title: string;
  signout?: boolean;
  disable?: boolean;
};

export const SideNav = ({ navs, title, signout, disable }: SideNavType) => {
  const [checked, setChecked] = React.useState(true);
  const isLogged = useRecoilValue(userIsLoggedIn);
  const logout = useLogout();
  const location = useLocation();
  const { pathname } = location;

  const handleChange = () => {
    setChecked(prev => !prev);
  };

  const _logout = async () => {
    await logout();
  };

  return (
    <>
      <div className="hidden md:flex justify-center h-[auto] p-2 sticky top-[56px] md:top-[58px] text-white bg-white rounded-2xl">
        <div className="py-6 px-2">
          <div className="flex flex-col justify-center">
            {navs.map((item, index) => (
              <Tooltip
                label={item.label}
                aria-label={item.label}
                placement="right-end"
                hasArrow
                className="rounded-2xl text-white bg-gray-300 px-4 py-2"
                bg="gray"
                key={index}>
                <Link
                  to={item.to}
                  className={`cursor-pointer inline-flex items-center justify-center text-sm mb-6 ${
                    `/account/${item.to}` === pathname && 'border-2 border-secondary rounded-lg'
                  } hover:border hover:border-primary hover:rounded-lg rounded-lg p-[6px] pb-2`}>
                  {item.Icon && (
                    <div className="px-[1px] relative">
                      {/* {item.new && (
                          <p className="p-[6px] rounded-full bg-red-500 absolute top-[-4px] right-[-4px] z-10" />
                        )} */}
                      <item.Icon className="text-black" />
                    </div>
                  )}

                  {/* {item.image && (
                      <div className="relative">
                        {item.new && (
                          <p className="p-[6px] rounded-full bg-red-500 absolute top-[-4px] right-[-4px] z-10" />
                        )}
                        <Image
                          className="text-center flex-shrink-0"
                          src={item.image}
                          alt={item.name}
                          width={20}
                          height={20}
                          style={{
                            filter: 'brightness(0) invert(1)'
                          }}
                        />
                      </div>
                    )} */}
                </Link>
              </Tooltip>
            ))}
          </div>
          {signout && isLogged && (
            <Tooltip
              label={'Log out'}
              aria-label={'Log out'}
              placement="right-end"
              hasArrow
              className="rounded-2xl text-white bg-gray-300 px-4 py-2"
              bg="gray">
              <div
                onClick={_logout}
                className="hover:bg-red-500 text-red-500  hover:text-white p-[6px] cursor-pointer text-xl  flex items-center justify-center rounded-lg">
                <TbLogout className="" />
              </div>
            </Tooltip>
          )}
        </div>
      </div>
    </>
  );
};
