import { Tooltip } from '@mui/material';
import { Category } from 'TYPES/api.body';
import { AddCart, CartTickIcon, LikeIcon, LogoIcon } from 'assets/Icons';
import { Spacer } from 'components/atoms';
import QUERY_KEYS from 'network/config/queryKeys';
import {
  addProductToCart,
  likeProduct,
  removeProductFromCart,
  unlikeProduct,
  updateCartItemQty
} from 'network/mutations/products';
import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { IWishList, wishListState } from 'store/atoms/productDetail';
import { profileAtom } from 'store/atoms/profileAtom';
import { userRoleAtom } from 'store/atoms/userAtom';
import { removeItemAtIndex } from 'utils/arrayHelpers';
import { formatCurrency } from 'utils/currencyFormater';
import { useUpdatedCart } from '../Cart/hooks';
import { GoodsCon } from './style';
import { performAddProductToCart } from './utils';

interface IProductCard {
  setOnSeeGoodsDetails: () => void;
  seller: {
    seller_logo: string;
    businessName: string;
    id: number;
  };
  productId: number;
  productName: string;
  price: number;
  quantityAvailable: number;
  minimumOrderQuantity: number;
  bulkOrderPrice: number;
  barCode: string;
  productImage1: string | string[];
  productImage2?: string | string[];
  productImage3?: string | string[];
  productImage4?: string | string[];
  likes?: number;
  status: string;
  description: string;
  category?: Category;
}

export const ProductCard = ({ setOnSeeGoodsDetails, ...rest }: IProductCard) => {
  const route = useNavigate();
  const queryClient = useQueryClient();
  const userRole = useRecoilValue(userRoleAtom);
  const { mutateAsync: addMutate } = useMutation({ mutationFn: addProductToCart });
  const { mutateAsync: updateMutate } = useMutation({ mutationFn: updateCartItemQty });
  const { mutateAsync: removeMutate } = useMutation({ mutationFn: removeProductFromCart });
  const serverCart = useUpdatedCart();
  const [result, setResult] = useState<{ orderId: number | null; productFound: boolean }>({
    orderId: null,
    productFound: false
  });

  const { productId: id, productName: name, price: amount, description: shortDSC, productImage1: img } = rest;

  useEffect(() => {
    if (id !== undefined && serverCart?.data) {
      const found = findProductInCart(id, serverCart?.data);
      setResult(found);
    }
  }, [serverCart?.data, id]);

  function findProductInCart(productId: number, cart: any) {
    if (!cart || !Array.isArray(cart.orders)) {
      return { orderId: null, productFound: false };
    }
    for (const order of cart.orders) {
      for (const product of order.products) {
        if (product.productId === productId) {
          return { orderId: order.id, productFound: true };
        }
      }
    }
    return { orderId: null, productFound: false };
  }

  const profile = useRecoilValue(profileAtom);
  const [wishList, setWishList] = useRecoilState(wishListState);
  const wishitemIndex = wishList.findIndex(item => item.id === id);
  const isItemInWishlist = wishitemIndex >= 0 || (rest.likes ?? 0) > 0;

  const { mutateAsync: likeProductMutate, isLoading: likeLoading } = useMutation({
    mutationFn: likeProduct,
    onError: err => {
      setWishList(removeItemAtIndex(wishList, wishitemIndex));
    }
  });

  const { mutateAsync: unlikeProductMutate, isLoading: unLikeLoading } = useMutation({
    mutationFn: unlikeProduct,
    onError: error => {
      setWishList(c => [
        ...c,
        {
          id,
          amount,
          img: Array.isArray(img) ? img[0] : img,
          name,
          isLiked: false,
          shortDSC,
          imgs: Array.isArray(img) ? img : [img],
          atrs: []
        }
      ]);
    }
  });

  const toggleCartItem: React.MouseEventHandler<HTMLButtonElement> = e => {
    e.stopPropagation();
    if (profile === undefined) {
      route('/login');
      return;
    }

    if (userRole === 'BUSINESS') {
      setOnSeeGoodsDetails();
    } else {
      performAddProductToCart(
        serverCart?.data,
        id,
        1,
        result?.orderId,
        addMutate,
        updateMutate,
        removeMutate,
        queryClient,
        'REGULAR'
      );
    }
  };

  const toggleLikeProduct: React.MouseEventHandler = e => {
    e.stopPropagation();
    if (profile === undefined) {
      route('/login');
      return;
    }
    let newArray: IWishList;
    if (isItemInWishlist) {
      unlikeProductMutate(id).then(() => queryClient.invalidateQueries([QUERY_KEYS.FAVOURITE_PRODUCTS]));
      newArray = wishList.filter(item => item.id !== id);
    } else {
      likeProductMutate(id).then(() => queryClient.invalidateQueries([QUERY_KEYS.FAVOURITE_PRODUCTS]));
      newArray = [
        ...wishList,
        {
          id,
          amount,
          img: Array.isArray(img) ? img[0] : img,
          name,
          isLiked: false,
          shortDSC,
          imgs: Array.isArray(img) ? img : [img],
          atrs: []
        }
      ];
    }
    setWishList(newArray);
  };

  const imgSrc = Array.isArray(img) ? img[0] : img;

  return (
    <GoodsCon $isLiked={isItemInWishlist} onClick={setOnSeeGoodsDetails}>
      <div className="likeCon">
        <button disabled={likeLoading || unLikeLoading} onClick={toggleLikeProduct} type="button">
          <LikeIcon />
        </button>
      </div>
      <div className="imageCon">{img != null ? <img id={name} src={imgSrc} alt={name} /> : <LogoIcon />}</div>
      <div className="goodsDetails">
        <div className="info">
          <Tooltip title={name}>
            <h3>{name}</h3>
          </Tooltip>
          <h4>{formatCurrency(amount)}</h4>
        </div>
        <Spacer height={'1.5rem'} />
        <div className="info">
          <p className="line-clamp-1 !text-black !no-underline">{shortDSC?.replace(/<[^>]*>/g, '')}</p>
          <button className="outline-none" onClick={toggleCartItem}>
            {result?.orderId !== null ? (
              <CartTickIcon />
            ) : (
              <div className="addScale">
                <AddCart />
              </div>
            )}
          </button>
        </div>
      </div>
    </GoodsCon>
  );
};
