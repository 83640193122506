import { BackArrow } from 'assets/Icons';
import { Button, InputField, Spacer } from 'components/atoms';
import { PRIVACY_POLICY, TERMS_AND_CONDITIONS } from 'navigation/routes';
import { registerBusiness } from 'network/services/auth';
import { validateAddressDeliveryCallback } from 'network/services/order';
import { enqueueSnackbar } from 'notistack';
import React, { ChangeEvent, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useMutation } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { businessRegDetails } from 'store/atoms/businessKYB';
import { validators } from 'utils/validator';
import { buttonStyle, extraStylesInput, extraStylesInputPhone, InputCon, KYBCon } from './styles';

const INITIALSTATE = {
  name: '',
  email: '',
  phoneNumber: ''
};

export default function BusinessKYB() {
  const [formError, setFormError] = useState(INITIALSTATE);
  const [agreement, setAgreement] = useState(false);
  const [businessFormData, setBusinessFormData] = useRecoilState(businessRegDetails);

  const navigate = useNavigate();

  const onToggleAgreement = (e: ChangeEvent<HTMLInputElement>) => {
    setAgreement(e.target.checked);
  };

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: registerBusiness
  });

  const { mutateAsync: validateAddress, isLoading: validatingAddress } = useMutation({
    mutationFn: validateAddressDeliveryCallback
  });

  const setFormInfo = (name: string, value: unknown) => {
    const error = validators[name](value);

    setBusinessFormData(curr => ({
      ...curr,
      request: {
        ...curr.request,
        representativeInfo: { ...curr.request.representativeInfo, [name]: value }
      }
    }));

    setFormError({
      ...formError,
      [name]: error
    });
  };

  const onUpdateFormData = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    setFormInfo(name, value);
  };

  const removePrefix234 = (input: string): string => {
    // Remove prefix '234' if it exists
    const withoutPrefix = input.startsWith('234') ? input.slice(3) : input;

    // Add '0' to the front if the remaining number is 10 digits
    return withoutPrefix.length === 10 ? `0${withoutPrefix}` : withoutPrefix;
  };

  const ensureLastName = (name: string): string => {
    // Check if there's a space in the name
    return name.includes(' ') ? name : `${name} Business`;
  };
  const removeNonAlphabets = (input: string): string => input.replace(/[^a-zA-Z\s]/g, '');
  const _handleSubmit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    const businessAddrPromise = validateAddress({
      name: removeNonAlphabets(ensureLastName(businessFormData.request.businessName)),
      email: businessFormData.request.businessEmail,
      phone: removePrefix234(businessFormData.request.businessPhoneNumber),
      address: `${businessFormData.request.street}, ${businessFormData.request.state}, Nigeria`
    });

    // If address validation fails, stop the process
    if (!businessAddrPromise || (await businessAddrPromise).status !== 'SUCCESS') {
      enqueueSnackbar('Address validation failed. Please enter a valid address', { variant: 'error' });
      return;
    }
    enqueueSnackbar('Business Address validation successful ', { variant: 'success' });
    // Ensure business phone number includes the country code '234'
    let businessPhoneNumber = businessFormData.request.businessPhoneNumber;
    if (!businessPhoneNumber.startsWith('234')) {
      businessPhoneNumber = `234${businessPhoneNumber}`;
    }

    try {
      const { data } = await mutateAsync({
        businessEmail: businessFormData.request.businessEmail,
        businessPhoneNumber: businessPhoneNumber,
        password: businessFormData.request.password,
        businessName: businessFormData.request.businessName,
        street: businessFormData.request.street,
        cacRegNo: businessFormData.request.cacRegNo,
        state: businessFormData.request.state,
        websiteUrl: businessFormData.request.websiteUrl,
        representativeInfo: businessFormData.request.representativeInfo,
        country: 'Nigeria'
      });

      if (data != null) {
        navigate(`/otp/${businessFormData.request.businessEmail}`);
      }
    } catch (error) {
      const errMessage = (error as any)?.response?.data?.message ?? 'An error occurred';
      enqueueSnackbar(errMessage, { variant: 'error' });
    }
  };

  const disabled =
    !businessFormData.request.businessName ||
    !businessFormData.request.representativeInfo.email ||
    !businessFormData.request.representativeInfo.phoneNumber ||
    !!validators.email(businessFormData.request.representativeInfo.email);

  return (
    <KYBCon>
      <BackArrow style={{ cursor: 'pointer' }} onClick={() => setBusinessFormData(curr => ({ ...curr, step: 1 }))} />
      <Spacer height="24px" />
      <h2>Let's KYB better</h2>
      <Spacer height="16px" />
      <p>Provide your representative information</p>
      <Spacer height=".7rem" />

      <form className="kybForm">
        <InputCon>
          <span>Representative Name</span>
          <InputField
            type="text"
            name="name"
            onChange={onUpdateFormData}
            placeholder="Name"
            extraStyles={extraStylesInput}
            required={true}
            error={formError.name}
          />
        </InputCon>
        <Spacer height="1.5rem" />
        <InputCon>
          <span>Representative Email</span>
          <InputField
            type="email"
            name="email"
            onChange={onUpdateFormData}
            value={businessFormData.request.representativeInfo.email}
            placeholder="example@mail.com"
            extraStyles={extraStylesInput}
            required={true}
            error={formError.email}
            autoComplete="username"
          />
        </InputCon>
        <Spacer height="1.5rem" />
        <InputCon>
          <span>Representative Phone Number</span>
          <PhoneInput
            country={'ng'}
            value={businessFormData.request.representativeInfo.phoneNumber}
            onChange={phone => {
              setBusinessFormData(curr => ({
                ...curr,
                request: {
                  ...curr.request,
                  representativeInfo: {
                    ...curr.request.representativeInfo,
                    phoneNumber: phone
                  }
                }
              }));
            }}
            placeholder={'80XXXXXXXX'}
            autoFormat={false}
            buttonStyle={buttonStyle}
            inputStyle={extraStylesInputPhone}
          />
        </InputCon>

        <Spacer height="3rem" />

        <div className="policy">
          <input type="checkbox" name="policy" id="policy" onChange={onToggleAgreement} />
          <p className="option">
            I have read, understood and I agree to Madina’s{' '}
            <Link className="text-primary font-medium" to={PRIVACY_POLICY}>
              Privacy Policy
            </Link>
            , and{'  '}
            <Link className="text-primary font-medium" to={TERMS_AND_CONDITIONS}>
              Terms and conditions
            </Link>
            .
          </p>
        </div>
        <Spacer height="1rem" />

        <div className="buttonContainer">
          <Button
            type="submit"
            onClick={_handleSubmit}
            disabled={!agreement || isLoading || disabled}
            isLoading={isLoading}>
            Create Account
          </Button>
        </div>
      </form>
    </KYBCon>
  );
}
