import { CircularProgress, Tooltip } from '@mui/material';
import $ from 'lodash';
import QUERY_KEYS from 'network/config/queryKeys';
import { removeProductFromCart, updateCartItemQty } from 'network/mutations/products';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { BiTrash } from 'react-icons/bi';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { profileAtom } from 'store/atoms/profileAtom';
import { userRoleAtom } from 'store/atoms/userAtom';
import { formatCurrency } from 'utils/currencyFormater';
import { truncateCharCount } from 'utils/truncate';
import { ProductQtyCounter } from '../../atoms';
import { performRemoveProductFromCart, performUpdateCartItemQty } from '../Goods/utils';

type ICartProp = {
  // item: CartItem;
  item: any;
  index: number;
  vendorId: number;
  orderId: number;
  customerType?: any;
};
export const CartProduct: React.FC<ICartProp> = ({ item, index, vendorId, orderId, customerType }) => {
  const queryClient = useQueryClient();
  const route = useNavigate();
  const profile = useRecoilValue(profileAtom);
  const [value, setValue] = useState(item.quantity);
  const maxquantiy = item.quantityAvailable;
  const { amount, bulkOrderPrice, price, name, photo, minimumOrderQuantity, productName, quantity } = item;
  const userRole = useRecoilValue(userRoleAtom);

  const { mutateAsync: removeFn, isLoading: deleting } = useMutation({
    mutationFn: removeProductFromCart,
    onSuccess: () => {
      // Invalidate the cart query only if the update mutation was successful
      queryClient.invalidateQueries(QUERY_KEYS.CART);
    },
    onError: err => {
      enqueueSnackbar('Failed to remove item', {
        variant: 'error'
      });
    }
  });
  const { mutateAsync: updateMutate } = useMutation({
    mutationFn: updateCartItemQty,
    onSuccess: () => {
      // Invalidate the cart query only if the update mutation was successful
      queryClient.invalidateQueries(QUERY_KEYS.CART);
    },
    onError: err => {
      enqueueSnackbar('Failed to update item quantity', {
        variant: 'error'
      });
    }
  });

  const deleteItem = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    if (profile === undefined) {
      route('/login');
      // localRemoveProductFromCart(cartList, vendorId, index, setCartList);
    } else {
      performRemoveProductFromCart(item, removeFn, queryClient, customerType, orderId);
    }
  };

  const debounceUpdateQty = $.debounce((qty: number) => {
    if (profile === undefined) {
      route('/login');
    } else {
      if (qty < 1) {
        performRemoveProductFromCart(item, removeFn, queryClient, customerType);
      } else {
        performUpdateCartItemQty(qty, item, orderId, updateMutate, removeFn, customerType).catch(err => {
          enqueueSnackbar('Failed to update item quantity', {
            variant: 'error'
          });
        });
      }
    }
  }, 1000);

  const onChangeQuantity = (value: number) => {
    setValue(value);
    debounceUpdateQty(value);
  };

  const calculatePrice = () => {
    let amount;
    if (customerType === 'BUSINESS') {
      amount = bulkOrderPrice * quantity;
    } else {
      amount = price * quantity;
    }

    return formatCurrency(amount);
  };

  return (
    <div className="flex gap-3 w-full">
      {/* <div className="imageCon">{photo !== undefined ? <img src={photo} alt="productImage" /> : <Logo />}</div> */}
      <h2 className="text-3xl ">•</h2>
      <div className="  w-full">
        <div className="grid gap-y-6  w-full">
          <div className="flex justify-between items-center">
            <Tooltip title={name}>
              <h5 className="font-medium text-base">{truncateCharCount(name || productName, 25)}</h5>
            </Tooltip>
            {deleting ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              <button className="w-9 h-9 rounded-full bg-red-100 " onClick={deleteItem}>
                <BiTrash className="text-red-500 cursor-pointer m-auto " />
              </button>
            )}
          </div>

          <div className="flex justify-between items-center">
            <h4>{calculatePrice()}</h4>

            <div className="">
              <ProductQtyCounter
                value={value}
                setValue={onChangeQuantity}
                max={maxquantiy}
                customerType={userRole as any}
                minimumOrderQuantity={minimumOrderQuantity}
              />
            </div>
          </div>
        </div>
        {customerType === 'BUSINESS' && quantity < minimumOrderQuantity && (
          <p className="text-red-600 text-sm mt-2">
            * Quantity is below the required minimum order quantity {minimumOrderQuantity}
          </p>
        )}
      </div>
    </div>
  );
};
