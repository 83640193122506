import { Theme, useMediaQuery } from '@mui/material';
import { OrderDetailsResponse } from 'TYPES/api.body';
import React from 'react';
import { Link } from 'react-router-dom';
import { formatDate } from '../../../utils/dateFormater';
import { OrderCardCon } from './styles';

interface Props {
  item: OrderDetailsResponse;
  productimg?: string;
}

export const OrderCard: React.FC<Props> = ({ item }) => {
  const sm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <OrderCardCon>
      <div className="left">
        <img
          className="h-full object-cover"
          src={Array.isArray(item?.carts) ? item?.carts[0].product?.profileImage : '/graphics/madina.svg'}
          alt="seller"
        />
        <div className="text">
          <div className="text-upper">
            <div className="title">{item ? item?.vendorName : 'Empty Order'}</div>
            {item.orderRef && (
              <h5 className="id flex items-center gap-2">
                Order ID: {item?.orderRef} <span className="text-2xl">•</span> {item?.deliveryStatus}
              </h5>
            )}
          </div>
          <div className="delivered">{formatDate(item?.orderDate)}</div>
        </div>
      </div>
      <Link className="right" to={`${item.status}/${item.id}`}>
        {!sm && 'View'} details
      </Link>
    </OrderCardCon>
  );
};
