import { subscribeToPackage, updateSubscription } from 'network/services/subscriptions';
import { enqueueSnackbar } from 'notistack';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { PaystackSuccess, SubscriptionHistory, SubscriptionPackage } from 'TYPES/api.body';

export const usePaystackSubscribe = (
  setState: React.Dispatch<React.SetStateAction<SubscriptionPackage | undefined>>,
  sub?: SubscriptionPackage
) => {
  const router = useNavigate();
  const { mutateAsync } = useMutation({ mutationFn: subscribeToPackage });
  const onSuccess = (ref?: PaystackSuccess) => {
    if (ref !== undefined && sub) {
      mutateAsync({
        paymentRef: ref.reference,
        subscriptionPackageId: sub.id,
        paymentMethod: 'Paystack',
        paymentStatus: 'SUCCESS'
      })
        .then(data => {
          enqueueSnackbar({ message: data.message, variant: 'success' });
          router(0);
        })
        .catch(err => console.log({ err }));
    }
  };
  const onClose = () => setState(undefined);

  return { onSuccess, onClose };
};

export interface SubAction {
  action: 'upgrade' | 'downgrade' | 'update';
  sub: SubscriptionHistory;
}

export const useUpdateSubscription = (
  setState: React.Dispatch<React.SetStateAction<SubAction | undefined>>,
  subAction?: SubAction,
  upgrade?: SubscriptionPackage
) => {
  const { mutateAsync } = useMutation({ mutationFn: updateSubscription });
  const onSuccess = (ref?: PaystackSuccess) => {
    if (ref !== undefined && subAction && upgrade) {
      mutateAsync({
        toId: upgrade.id,
        currRef: subAction.sub.subscriptionRef
      })
        .then(data => {
          setState(undefined);
          enqueueSnackbar({ message: data.message, variant: 'success' });
        })
        .catch(err => console.log({ err }));
    }
  };
  const onClose = () => setState(undefined);

  return { onSuccess, onClose };
};
