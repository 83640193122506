import { styled } from '@mui/material';
import { COLORS } from '../../theme/color';
import { breakpoints } from '../../theme/theme';

export const CatergoriesCON = styled('div')(({ theme }) => ({
  background: COLORS.White,
  padding: '2rem 2rem',
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('md')]: {
    padding: '1rem 1rem'
  },
  '.none': {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    p: {
      fontWeight: '500',
      fontSize: '20px',
      opacity: '0.7'
    }
  }
}));

export const HeaderLink = styled('div')`
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  text-transform: capitalize;
  cursor: pointer;
  @media (max-width: ${breakpoints.md}px) {
    font-size: 18px;
  }
`;

export const HeaderCon = styled('div')<{ sub: boolean }>`
  display: flex;
  flex-direction: start;
  align-items: start;
  justify-content: space-between;
  gap: 20px;
  li:first-of-type div {
    font-weight: 400 !important;
  }
  margin-bottom: 2rem;
  & > .MuiBreadcrumbs-root {
    flex-shrink: 1;
  }
  @media (max-width: ${breakpoints.sm}px) {
    flex-direction: column;
    margin-bottom: ${props => (props.sub ? '1rem' : '2rem')};
    gap: 30px;
  }
`;

export const RecentlyViewed = styled('div')`
  padding: 2rem !important;
  background: #fff;
`;

export const SortWrapper = styled('div')`
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

export const CategorySection = styled('div')({
  background: '#fff',
  padding: '2rem',
  h1: {
    paddingBottom: '1rem',
    textTransform: 'capitalize'
  }
});