import { DeleteCartProduct } from 'assets/Icons';
import { performRemoveProductFromCart } from 'components/molecules/Goods/utils';
import QUERY_KEYS from 'network/config/queryKeys';
import { removeProductFromCart } from 'network/mutations/products';
import { useMutation, useQueryClient } from 'react-query';
import { formatCurrency } from 'utils/currencyFormater';
import { CheckoutProductCon } from './style';

type CheckoutProductProp = {
  item: any;
  orderId: any;
  customerType?: any;
};

export const CheckoutProduct = ({ item, orderId, customerType }: CheckoutProductProp) => {
  const queryClient = useQueryClient();
  const { price, bulkOrderPrice, images, productName, quantity } = item;
  const { mutateAsync: removeFn, isLoading: deleting } = useMutation({ mutationFn: removeProductFromCart });

  const deleteItem = () => {
    performRemoveProductFromCart(item, removeFn, queryClient, customerType, orderId);
    queryClient.invalidateQueries(QUERY_KEYS.CART);
  };

  const calculatePrice = () => {
    let amount;
    if (customerType === 'BUSINESS') {
      amount = bulkOrderPrice * quantity;
    } else {
      amount = price * quantity;
    }

    return formatCurrency(amount);
  };

  return (
    <div>
      <CheckoutProductCon>
        <div className="imageCon">
          <img src={Array.isArray(images) ? images[0] : images ?? '/graphics/madina.svg'} alt="productImage" />
        </div>
        <div className="productDetailsCon">
          <div className="top flex items-start justify-between">
            <div className="desc">
              <p>{productName}</p>
              <p>QTY: {quantity}</p>
            </div>
            <button disabled={deleting} onClick={deleteItem} className="pt-2">
              <DeleteCartProduct />
            </button>
          </div>
          <div className="price">{calculatePrice()}</div>
        </div>
      </CheckoutProductCon>
    </div>
  );
};
