import { Box, Stack } from '@mui/material';
import { Debitwallet, Wallethistory } from 'assets/Icons';

import { useMediaQuery } from 'react-responsive';

const ListElement = ({ img, name, amount, time, specialization, amountState, id }: any) => {
  const breakPoint = useMediaQuery({
    query: '(max-width:  1024px)'
  });

  function formatDateString(dateString: any) {
    // Define a map for month names to their numeric values
    const monthMap: any = {
      January: '01',
      February: '02',
      March: '03',
      April: '04',
      May: '05',
      June: '06',
      July: '07',
      August: '08',
      September: '09',
      October: '10',
      November: '11',
      December: '12'
    };

    // Extract the date and time parts using regex
    const regex = /(\d+)\s+of\s+(\w+),\s+(\d+)\s+at\s+(\d+):(\d+)(AM|PM)/;
    const match = dateString.match(regex);

    if (!match) {
      throw new Error('Invalid date format');
    }

    const [, day, monthName, year, hourStr, minute, period] = match;
    const month = monthMap[monthName];

    // Convert hour to 24-hour format if needed
    let hour = parseInt(hourStr, 10);
    if (period === 'PM' && hour !== 12) {
      hour += 12;
    } else if (period === 'AM' && hour === 12) {
      hour = 0;
    }

    // Format the hour back to 12-hour format for the desired output
    const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
    const formattedTime = `${formattedHour}:${minute}${period.toLowerCase()}`;

    // Format the date part
    const formattedDate = `${day}-${month}-${year}`;

    // Combine and return the formatted date and time
    return `${formattedTime}, ${formattedDate}`;
  }

  return (
    <div className="border-b border-dashed border-[#C8C6D7] py-2">
      <Box
        sx={{
          width: '100%',
          height: '50%'
        }}
        p={2}>
        <div className="flex justify-between gap-3 lg:grid lg:grid-cols-4 items-center">
          <Stack direction="row" spacing={2} alignItems="center" className="col-span-2">
            {/* <StyledBadge
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              variant="dot"
            > */}
            {amountState === 'Debit' ? <Debitwallet /> : <Wallethistory />}

            {/* </StyledBadge> */}

            <div>
              <p
                className={` text-sm md:text-base 
                text-darkerSecondary
                tracking-wide`}>
                {name}
              </p>
              <p
                className={`text-lightGrayPrimary
                 text-sm tracking-wide`}>
                {specialization}
              </p>
            </div>
          </Stack>
          {breakPoint ? null : (
            <p
              className={` ${amountState === 'Debit' ? 'text-[#FF0000]' : 'text-[#00B227]/90'}
            tracking-wider text-sm `}>
              {amountState === 'Debit' ? '-' : '+'}
              {amount}
            </p>
          )}

          <Stack spacing={1} alignItems={'center'}>
            {breakPoint ? (
              <p
                className={` ${amountState === 'Debit' ? 'text-[#FF0000]' : 'text-[#00B227]/90'}
            tracking-wider text-xs md:text-sm `}>
                {amountState === 'Debit' ? '-' : '+'}
                {amount}
              </p>
            ) : null}
            <p
              className={`hidden lg:block  tracking-wide text-lightGrayPrimary
              } text-xs md:text-sm`}>
              {
                // breakPoint
                // ? formatDateString(
                //     time.split(',').map(({ part, index }: any) => (
                //       <span key={index}>
                //         {part.trim()} {/* Remove extra spaces */}
                //         <br /> {/* Break after each part */}
                //       </span>
                //     ))
                //   )
                //   :
                formatDateString(time)
              }
            </p>
          </Stack>
        </div>
      </Box>
    </div>
  );
};

export default ListElement;
