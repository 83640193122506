import { Category } from 'TYPES/api.body';
import { Empty } from 'components/atoms';
import { Product } from 'components/molecules/Goods/Product';
import React from 'react';
import { GridContainer } from 'styles/grids';
import ProductSkeletons from './ProductSkeletons';

interface Props {
  products: {
    productId: number;
    productName: string;
    price: number;
    quantityAvailable: number;
    minimumOrderQuantity: number;
    bulkOrderPrice: number;
    barCode: string;
    productImage1: string | string[];
    productImage2?: string | string[];
    productImage3?: string | string[];
    likes?: number;
    status: string;
    description: string;
    seller: {
      seller_logo: string;
      businessName: string;
      id: number;
    };
    category?: Category;
  }[];
  diva?: any;
  isLoading: boolean;
  skeletonCount?: number;
  emptyMessage?: string;
  showEmptyPrompt?: boolean;
  emptyText?: string;
}

const ProductsGrid: React.FC<Props> = ({
  diva,
  products,
  isLoading,
  skeletonCount,
  showEmptyPrompt = true,
  emptyText
}) => {
  return (
    <>
      <GridContainer>
        {products.map(product => (
          <div key={product?.productId}>
            <Product product={product} />
          </div>
        ))}
        {isLoading && <ProductSkeletons count={skeletonCount ?? 6} />}
      </GridContainer>
      {!isLoading && showEmptyPrompt && products.length < 1 && (
        <Empty message={emptyText ?? 'Unfortunately, there are no items in this category.'} />
      )}
    </>
  );
};

export default ProductsGrid;
