import React from 'react';
import { IoArrowBackCircle } from 'react-icons/io5';

interface HeaderVendorProps {
  title: any;
  description: string;
  vendorBannerSrc?: any;
  handleBack: () => void;
}

const HeaderVendor: React.FC<HeaderVendorProps> = ({ title, description, vendorBannerSrc, handleBack }) => {
  return (
    <div>
      <div className="md:flex justify-between">
        <div className="flex gap-3 md:gap-6 md:items-center">
          <IoArrowBackCircle className="text-4xl text-secondary" onClick={handleBack} />
          <div>
            <h1 className="text-secondary font-medium text-2xl mb-1 lg:text-3xl">{title}</h1>
            <p className="text-[#4D4D4D] leading-6 text-base mr-6 md:mr-0">{description}</p>
          </div>
        </div>
        <div className="md:flex md:items-center gap-4">
          {/* <div className="pt-1 md:pt-0">
            <GoldStars stars={4} />
          </div> */}
        </div>
      </div>
      {vendorBannerSrc !== undefined && (
        <div className="relative my-5 lg:my-8 border rounded-lg border-[#F4F5F9] h-[200px] lg:w-[70%]">
          <img src={vendorBannerSrc} className="rounded-lg object-cover w-full h-full" alt="vendor's brand"></img>
        </div>
      )}
    </div>
  );
};

export default HeaderVendor;
