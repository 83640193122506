import { CircularProgress, Divider, TextField } from '@mui/material';
import { EmptyCon, OrderButton } from 'components/atoms';
import { Modal } from 'components/molecules';
import SEARCH_PARAMS from 'constants/searchParams';
import moment from 'moment';
import QUERY_KEYS from 'network/config/queryKeys';
import { addProductToCart, removeProductFromCart, updateCartItemQty } from 'network/mutations/products';
import { cancelOrder, getSingleOrder } from 'network/services/order';
import { enqueueSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { cartListAtom } from 'store/atoms/cartAtom';
import { userRoleAtom } from 'store/atoms/userAtom';
import { COLORS } from 'theme/color';
import { formatCurrency } from 'utils/currencyFormater';
import { formatDate } from 'utils/dateFormater';
import { HeaderCON } from '../styles';
import { OrderDetailsCon, OrderInfo, OrderSection } from './styles';

export const OrderDetails = () => {
  const queryClient = useQueryClient();
  const go = useNavigate();
  const { id } = useParams();
  const userRole = useRecoilValue(userRoleAtom);
  const [cartList, setCartList] = useRecoilState(cartListAtom);
  const [isDecline, setDecline] = useState<boolean>(false);
  const [err, setreasonError] = useState<string>('');
  const [reason, setReason] = React.useState('');
  const { mutateAsync: cancel } = useMutation({
    mutationFn: cancelOrder,
    onSuccess: _data => {
      enqueueSnackbar(`Order ${id} cancelled successfully.`, {
        variant: 'success'
      });
      setDecline(false);
    },
    onError: () => {
      enqueueSnackbar(`Failed to cancel order #${id}`, { variant: 'error' });
      setDecline(false);
    }
  });
  const { mutateAsync: addMutate, isLoading } = useMutation({
    mutationFn: addProductToCart
  });
  const { mutateAsync: updateMutate } = useMutation({
    mutationFn: updateCartItemQty
  });
  const { mutateAsync: removeMutate } = useMutation({
    mutationFn: removeProductFromCart
  });

  const { data: order, isFetchedAfterMount } = useQuery({
    queryKey: [QUERY_KEYS.SINGLE_ORDER, id],
    queryFn: () => getSingleOrder(Number(id))
  });

  const track = () => go(`track?${SEARCH_PARAMS.DELIVERY_METHOD}=${order?.deliveryMethod}`);
  const handleCancel = () => {
    if (reason.length < 2) {
      setreasonError('Please Enter a Reason');
    } else {
      cancel({ id: Number(id), reason: reason });
    }
  };

  const calculatePrice = (price: any, quantity: any) => {
    let amount;
    amount = price * quantity;
    return formatCurrency(amount);
  };

  // const handleBuyAgain = () => {
  //   if (order) {
  //     performAddProductToCart(
  //       cartList,
  //       (order.carts ?? []).map(prod => ({
  //         ...prod.product,
  //         id: prod.product.id,
  //         name: prod.product.productName,
  //         amount: prod.product.price,
  //         quantity: prod.quantity,
  //         sellerId: 1
  //       })),
  //       1,
  //       addMutate,
  //       updateMutate,
  //       removeMutate,
  //       setCartList,
  //       queryClient,
  //       userRole as any
  //     );
  //   }
  // };

  return (
    <>
      <HeaderCON>Order Details</HeaderCON>
      {!isFetchedAfterMount && (
        <EmptyCon>
          <CircularProgress size={30} />
        </EmptyCon>
      )}
      {isFetchedAfterMount && order && (
        <OrderDetailsCon>
          <div className="id flex lg:gap-4">
            <p>Order {order?.orderRef}</p>
            <span
              className={`px-3 py-1 rounded-md ${
                order?.orderStatus?.toUpperCase() === 'PENDING'
                  ? 'text-amber-900 bg-yellow-200'
                  : order?.orderStatus?.toUpperCase() === 'REJECTED'
                  ? 'text-red-100 bg-red-900'
                  : order?.orderStatus?.toUpperCase() === 'CANCELLED'
                  ? 'text-red-800 bg-red-200'
                  : order?.orderStatus?.toUpperCase() === 'ONGOING'
                  ? 'text-green-800 bg-green-200'
                  : order.orderStatus?.toUpperCase() === 'APPROVED'
                  ? 'text-green-800 bg-green-200'
                  : order.orderStatus?.toUpperCase() === 'COMPLETED'
                  ? 'text-green-800 bg-green-200'
                  : order?.orderStatus?.toUpperCase() === 'ORDER_PROCESSED'
                  ? 'text-green-800 bg-green-200'
                  : 'text-red-800 bg-red-200'
              }`}>
              {order?.orderStatus?.toUpperCase() === 'ORDER_PROCESSED'
                ? 'ORDER PROCESSED'
                : order.orderStatus?.toUpperCase()}
            </span>
          </div>
          <div className="text1">{order?.products && order?.products.length} Items</div>
          <div className="text1">{formatDate(order?.orderDate)}</div>
          <div className="div1 cancel">
            Total: {formatCurrency(order?.totalAmount)}
            {/* <OrderButton
              onClick={() => {
                if (order?.status.toUpperCase() === "PENDING") {
                  setDecline(true);
                } else {
                  handleBuyAgain();
                }
              }}
              disabled={isLoading}
              bgColor={
                order?.status.toUpperCase() === "PENDING"
                  ? "#F04438"
                  : COLORS.MainBlue
              }
            >
              {order.status.toUpperCase() === "PENDING" && !isLoading ? (
                "Cancel Order"
              ) : order.status.toUpperCase() !== "PENDING" && !isLoading ? (
                "Buy Again"
              ) : (
                <Loader size="sm" color="white" />
              )}
            </OrderButton> */}
          </div>
          <Divider />
          <div className="div1">
            Items in this order
            <OrderButton outlined color={COLORS.MainBlue} onClick={track}>
              Track History
            </OrderButton>
          </div>
          <Divider />
          {Array.isArray(order?.products) &&
            order?.products?.map(item => (
              <OrderSection key={id}>
                <div className="item">
                  <img className="h-full object-cover" src={item?.images[0]} alt="order" />
                  <div className="text">
                    <div>
                      <div className="title">{item.productName}</div>
                      <div className="qty">QTY: {item.quantity}</div>
                    </div>
                    <div className="amount">
                      {userRole === 'BUSINESS'
                        ? calculatePrice(item.bulkOrderPrice, item.quantity)
                        : calculatePrice(item.price, item.quantity)}
                    </div>
                  </div>
                </div>
              </OrderSection>
            ))}
          <OrderInfo>
            <div className="col">
              <div className="headings">PAYMENT INFORMATION</div>
              <div className="text-con">
                <div className="method">Payment Method</div>
                <div>{order.paymentType}</div>
                <div>Payment Details</div>
                <div>Items: {order?.products && order?.products?.length}</div>
                <div>Shipping: {formatCurrency(order?.shippingFee)}</div>
                <div>Total: {formatCurrency(order?.totalAmount)}</div>
              </div>
            </div>
            <div className="col">
              <div className="headings">DELIVERY INFORMATION</div>
              <div className="text-con">
                <div className="method">Delivery Method</div>
                <div>{order.deliveryMethod === 'pickup' ? 'Pick Up' : 'Home delivery'}</div>
                {order.address && (
                  <>
                    <div>Delivery Details</div>
                    <div>{order.customer.name}</div>
                    <div>{order.address}</div>
                    <div>Expected Delivery Date on {moment(order?.dateOfSchedule).format('DD/MM/YYYY hh:mm A')}</div>
                  </>
                )}
              </div>
            </div>
          </OrderInfo>
          {isDecline && (
            <Modal maxWidth="400px" onModalClose={() => setDecline(false)}>
              <div className="flex items-center w-full flex-col gap-5">
                <p>Are you sure you want to cancel your order?</p>
                <TextField
                  rows={5}
                  multiline
                  fullWidth
                  value={reason}
                  onChange={e => setReason(e.currentTarget.value)}
                  placeholder="Why do you want to cancel your order"
                  required
                />
                <span className="text-red-600 text-sm">{err}</span>
                <div className="flex items-center gap-2">
                  <OrderButton onClick={() => setDecline(false)} outlined color="#808080">
                    No Go Back!
                  </OrderButton>
                  <OrderButton
                    className="disabled:opacity-30 disabled:cursor-not-allowed disabled:hover:opacity-30"
                    disabled={reason.length < 2}
                    onClick={handleCancel}
                    outlined
                    color="#F04438">
                    Yes Cancel!
                  </OrderButton>
                </div>
              </div>
            </Modal>
          )}
        </OrderDetailsCon>
      )}
    </>
  );
};
