import { parseInt } from 'lodash';
import QUERY_KEYS from 'network/config/queryKeys';
import React from 'react';
import { useInfiniteQuery, useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { getVendorById, getVendorsByCategory } from '../../network/services/goods';

export default function useVendorsByCategoriesHook() {
  const [searchParams] = useSearchParams();
  const LIMIT = 20;

  const category = searchParams.get('category');

  const {
    isFetching: categoryVendorsFetching,
    data: categoryVendorsData,
    hasNextPage: categoryVendorsHasNext,
    fetchNextPage: categoryVendorsFetchNext,
    error: categoryVendorsError 
  } = useInfiniteQuery({
    queryKey: [QUERY_KEYS.VENDORS_BY_CATEGORIES, { categoryId: category }],
    enabled: category != null ? !!category : true,
    queryFn: ({ pageParam = 0 }) => getVendorsByCategory({ categoryId: category, page: pageParam, size: LIMIT }),
    getNextPageParam: (lastPage, allPages) => ((lastPage ?? []).length === LIMIT ? allPages.length : undefined)
  });

  const fetchMoreCategoryVendors = React.useCallback(() => {
    if (categoryVendorsHasNext) {
      categoryVendorsFetchNext();
    }
  }, [categoryVendorsFetchNext, categoryVendorsHasNext]);

  return {
    categoryVendorsData,
    categoryVendorsFetching,
    fetchMoreCategoryVendors,
    categoryVendorsError
  };
}

export const useGoodsByVendor = () => {
  const [searchParams] = useSearchParams();

  const vendorId = searchParams.get('vendorId');
  return useQuery(['vendorDetails', vendorId], () => getVendorById(parseInt(vendorId, 10)));
};
