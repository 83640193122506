import { Scrollable } from 'components/atoms';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ProductsGrid from './ProductsGrid';
import SubCategoryFilter from './SubCategoryFilter';
import HeaderVendor from './header-vendor';
import { CategorySection } from './style';
import useProductsByVendorsCategoriesHook from './useProductsByVendorsCategoriesHook';

const ProductsByVendor = () => {
  const {
    fetchMoreProductsByVendors,
    productsByVendorsData,
    productsByVendorsisFetching,
    productsByVendorsHasNext,
    seller,
    subCategories
  } = useProductsByVendorsCategoriesHook();

  const navigate = useNavigate();
  const location = useLocation();
  const [selectedSubCategory, setSelectedSubCategory] = useState('All');

  // Function to handle subcategory change
  const handleSubCategoryChange = (subCategory: string) => {
    setSelectedSubCategory(subCategory);
  };

  // Function to handle back button click
  const handleBack = () => {
    const params = new URLSearchParams(location.search);
    params.delete('vendor');
    navigate({ search: params.toString() });
  };

  // Filter products based on selected subcategory
  const allProducts = productsByVendorsData?.pages?.flat() ?? [];
  const filteredProducts =
    selectedSubCategory === 'All'
      ? allProducts
      : allProducts.filter(product => product.subCategory === selectedSubCategory);

  // Check if there are no products
  const noProducts =
    filteredProducts.length === 1 && 'content' in filteredProducts[0] && filteredProducts[0].content.length === 0;

  const sellerData: any = seller;
  const subCategoryList: string[] = !!subCategories && ['All', ...subCategories];

  const emptyMessage = noProducts
    ? 'Unfortunately, no products are currently offered by this vendor'
    : selectedSubCategory !== 'All' && filteredProducts.length === 0
    ? `No products in this subcategory: ${selectedSubCategory}`
    : '';

  return (
    <div>
      <HeaderVendor
        title={sellerData?.businessName}
        description={sellerData?.businessDescription}
        vendorBannerSrc={sellerData?.seller_logo}
        handleBack={handleBack}
      />
      <div className="my-5 lg:my-10">
        <div className="overflow-x-scroll flex items-center gap-4 lg:overflow-x-hidden mt-5 md:mt-0">
          {subCategories?.length > 0 &&
            subCategoryList.map((subCategory, index) => (
              <SubCategoryFilter
                key={index}
                name={subCategory}
                isSelected={selectedSubCategory === subCategory}
                onClick={() => handleSubCategoryChange(subCategory)}
              />
            ))}
        </div>
      </div>
      <Scrollable onScrollBottom={fetchMoreProductsByVendors} callWhen={productsByVendorsHasNext}>
        <CategorySection>
          {noProducts || filteredProducts.length === 0 ? (
            <p>{emptyMessage}</p>
          ) : (
            <ProductsGrid
              emptyText={emptyMessage}
              isLoading={productsByVendorsisFetching}
              products={filteredProducts}
              seller={sellerData}
            />
          )}
        </CategorySection>
      </Scrollable>
    </div>
  );
};

export default ProductsByVendor;
