import { COMMONTYPES } from "TYPES/event.types";
import { DropDownIcon, LocationIcon } from "assets/Icons";
import QUERY_KEYS from "network/config/queryKeys";
import { getAllAddress, setDefaultAddress } from "network/services/address";
import { validateAddressCallback } from "network/services/order";
import { enqueueSnackbar } from "notistack";
import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useRecoilState, useSetRecoilState } from "recoil";
import userAddressAtom from "store/atoms/address";
import { userLocationAtom } from "store/atoms/userLocationAtom";
import { setCacheAddress } from "utils/address";
import { truncateCharCount } from "utils/truncate";
import { InputField, Spacer } from "../../atoms";
import { DropDown } from "../DropDown/DropDown";
import { AddressCon, DropListCON, Spinner } from "./styles";

export const AddressDropDown = () => {
  const [search, setSearch] = useState("");
  const { data, isFetching } = useQuery(
    [QUERY_KEYS.CUSTOMER_ADDRESSES],
    getAllAddress,
  );
  const [userAddress, setUserAddress] = useRecoilState(userAddressAtom);
  const setUserLocation = useSetRecoilState(userLocationAtom);
  const addresses = React.useMemo(
    () => (data?.data ? data.data : []),
    [data?.data],
  );

  const { mutateAsync: validateAddress } = useMutation({
    mutationFn: validateAddressCallback,
  });

  React.useEffect(() => {
    const defaultAddress =
      addresses.find((address) => address.status) ?? addresses.length > 0
        ? addresses[0]
        : undefined;
    (async () => {
      if (userAddress && userAddress.length > 0) {
        try {
          const { data } = await validateAddress(userAddress);
          setUserLocation({
            lat: data.lat,
            display_name: userAddress,
            name: userAddress,
            place_id: "0",
            lon: data.lng,
            address: {
              state:
                data.state !== "-" ? data.state : defaultAddress?.state ?? "",
              city: data.city,
              country: data.country,
              postcode: data.zipCode,
            },
          });
        } catch { }
      }
    })();
  }, [userAddress, validateAddress, setUserLocation, addresses]);

  React.useEffect(() => {
    const defaultAddress = addresses.find((item) => item.status === true);
    if (defaultAddress) {
      setCacheAddress(defaultAddress.street);
      setUserAddress(defaultAddress.street);
    }
  }, [addresses, setUserAddress]);

  const handleSearch = (e: COMMONTYPES["inputChange"]) => {
    e.preventDefault();
    setSearch(e.target.value);
  };

  const filtered = addresses.filter(({ street }) => {
    const name = street.toLowerCase();
    if (search.length <= 2) return addresses;
    const s = search.toLowerCase();
    return name.includes(s);
  });

  const queryClient = useQueryClient();

  const saveAsDefault = async (val: string, bool: boolean) => {
    if (bool) return;
    try {
      await setDefaultAddress(val);
      queryClient.invalidateQueries("addresses");
        const selectedAddress = addresses.find(address => address.id === Number(val)); // Convert val to number
        if (selectedAddress) {
          setUserAddress(selectedAddress.street); // Update the local state
          setCacheAddress(selectedAddress.street); // Persist in local storage
        }
      enqueueSnackbar("Address Updated Successfully", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar(
        `Error setting default address: ${(error as any).message}`,
        { variant: "error" },
      );
    }
  };

  return (
    <DropDown
      x="right"
      label={
        <h3>
          <LocationIcon />
          <Spacer width={10} />
          <span>
            {truncateCharCount(
              userAddress !== "" ? userAddress : "No Default Address",
              18,
            )}
          </span>
          <Spacer width={10} />
          <DropDownIcon />
        </h3>
      }
      margin="10px 0 0 0"
    >
      <AddressCon onClick={(e) => e.stopPropagation()}>
        <div className="heading">Delivering to</div>
        <Spacer height={10} />
        <InputField
          type="text"
          name="search"
          onChange={handleSearch}
          value={search}
          disabled={isFetching}
        />
        <DropListCON>
          {!isFetching ? (
            <div className="list-con">
              {filtered.length ? (
                filtered.map(({ street, state, country, status, id }) => (
                  <div
                    className="list"
                    onClick={() => saveAsDefault(`${id}`, status)}
                    data-current={status}
                    key={id}
                  >
                    <div className="name">{street}</div>
                    <div className="desc">
                      {state} {country}
                    </div>
                  </div>
                ))
              ) : (
                <div className="loading">No address found</div>
              )}
            </div>
          ) : (
            <div className="loading">
              <Spinner size={30} />
            </div>
          )}
        </DropListCON>
      </AddressCon>
    </DropDown>
  );
};
