import { Theme, useMediaQuery } from '@mui/material';
import { Modal } from 'components/molecules/Modal/Modal';
import { CenterModalContentStyle, CenterModalStyle } from 'components/molecules/Modal/style';
import { useReducer } from 'react';
import { INTIALSTATE, checkoutReducer } from 'reducer/checkout-reducer/checkout-reducer';
import { singleChangeAction } from 'reducer/helperfunc';
import { formatCurrency } from 'utils/currencyFormater';
import { BuyNowPayLaterButton } from './BuyNowPayLater/BuyNowPayLaterButton';
import { PayWithMadinaWallet } from './PayWithMadinaPoints';
import { Paystack } from './Paystack';
import { PaymentModalCon } from './style';

type PaymentTypes = 'wallet' | 'bnpl' | 'bank' | 'card';

type PaymentModalProp = {
  amount: number;
  onSuccess: (ref?: any) => void;
  onWalletSuccess?: () => void;
  onClose: () => void;
  exclude?: PaymentTypes[];
};

export const PaymentModal = ({ amount, onWalletSuccess, onSuccess, onClose, exclude }: PaymentModalProp) => {
  const sm = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const [, dispatch] = useReducer(checkoutReducer, INTIALSTATE);

  const setModalClose = () => {
    dispatch(singleChangeAction({ isPaymentModal: false }));
    onClose();
  };


  return (
    <Modal
      onModalClose={setModalClose}
      extraModalContentStyle={{ ...CenterModalContentStyle, width: sm ? '500px' : '' }}
      extraModalStyle={CenterModalStyle}>
      <PaymentModalCon>
        <h3>Payment Method</h3>
        <div className="total-amount">
          <span>Total Amount</span>
          <span className="amount">{formatCurrency(amount)}</span>
        </div>

        <div className={`payment-buttons`}>
          {!exclude?.includes('card') && (
            <Paystack
              desc=""
              title="Pay With Card"
              onSuccess={onSuccess}
              onClose={onClose}
              amount={amount}
              channels={['card']}
            />
          )}
          {!exclude?.includes('bank') && (
            <Paystack
              title="Bank Transfer"
              desc=""
              onSuccess={onSuccess}
              onClose={onClose}
              amount={amount}
              channels={['bank']}
            />
          )}
          {!exclude?.includes('wallet') && <PayWithMadinaWallet amount={amount} onSuccess={onWalletSuccess} />}
          {!exclude?.includes('bnpl') && <BuyNowPayLaterButton setPaymentModalClose={setModalClose} />}
        </div>
      </PaymentModalCon>
    </Modal>
  );
};
