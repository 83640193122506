import { NoteIcon } from 'assets/Icons';
import QUERY_KEYS from 'network/config/queryKeys';
import { convertPointsToNaira, getPointNairaEquivalent } from 'network/services/wallet';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Button, InputField } from '../../atoms';
import { Modal } from '../Modal/Modal';
import { SideModalContentStyle, SideModalStyle } from '../Modal/style';
import { ConvertPointModalCon } from './styles';

const initial = {
  x: '100%',
  height: 'auto'
};
const animate = {
  x: '0'
};

type PointModalProp = {
  setIsPoint: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ConvertPointModal = ({ setIsPoint }: PointModalProp) => {
  const queryClient = useQueryClient();
  const setModalClose = () => {
    setIsPoint(false);
  };
  const [points, setpoints] = useState('');
  const { data: nairaVal } = useQuery({
    queryFn: () => getPointNairaEquivalent(1),
    queryKey: [QUERY_KEYS.POINTS_NAIRA_EQUIVALENT, 1]
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setpoints(event.target.value);
  };
  const { mutateAsync: convertPoints, isLoading } = useMutation({ mutationFn: convertPointsToNaira });
  const handleConversion = async () => {
    try {
      const { data } = await convertPoints(Number(points ?? '0'));
      if (data.status === 'success') {
        enqueueSnackbar(`${data.description}`, { variant: 'success' });
        queryClient.invalidateQueries(QUERY_KEYS.WALLET_BALANCE);
      }
    } catch (error: any) {
 
      enqueueSnackbar(`${error.response.data.description}`, { variant: 'error' });
    }
  };
  return (
    <Modal
      onModalClose={setModalClose}
      extraModalStyle={SideModalStyle}
      extraModalContentStyle={SideModalContentStyle}
      initial={initial}
      animate={animate}>
      <ConvertPointModalCon>
        <div className="first">
          <h3>Convert points</h3>
          <div className="info">
            <NoteIcon />
            <div>
              <p>Exchange value</p>
              <p>1 MADINA POINT IS EQUIVALENT TO N{nairaVal?.data} </p>
            </div>
          </div>
          <InputField label="Amount of points to convert" name={''} onChange={handleChange} value={points} />
        </div>
        <Button type="submit" onClick={handleConversion} isLoading={isLoading}>
          Convert
        </Button>
      </ConvertPointModalCon>
    </Modal>
  );
};
