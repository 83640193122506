import { Theme, useMediaQuery } from '@mui/material';
import { Facebook, Instagram, Twitter } from 'assets/Icons';
import { Link } from 'react-router-dom';
import { Logo } from '../../atoms';

const footerLinks = [
  {
    header: 'Company',
    link: [
      {
        title: 'About Us',
        to: '/about-us'
      },
      {
        title: 'Terms & Conditions',
        to: '/terms-and-conditions'
      },
      {
        title: 'Privacy Policies',
        to: '/privacy-policy'
      }
    ]
  },
  {
    header: 'Support',
    link: [
      {
        title: 'FAQs',
        to: '/faqs'
      }
    ]
  },
  {
    header: 'Contact Us',
    link: [
      {
        title: '(+234) 916 860 4446',
        to: 'tel:+2349168604446'
      },
      {
        title: 'support@madinasupermarket.com',
        to: 'mailto:support@madinasupermarket.com'
      }
    ]
  },
  {
    header: 'Locations',
    link: [
      {
        title: 'Lekki, Lagos',
        to: '/'
      },
      {
        title: 'Maitama, Abuja',
        to: '/'
      }
    ]
  }
];

const socialIcon = [
  {
    Icon: Facebook,
    href: 'https://web.facebook.com/profile.php?id=100089994672845&mibextid=ZbWKwL&_rdc=1&_rdr'
  },
  {
    Icon: Twitter,
    href: 'https://twitter.com/madina_lekki'
  },
  {
    Icon: Instagram,
    href: 'https://www.instagram.com/madina.africa/'
  }
];
export const Footer = () => {
  const md = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  return (
    <div className=" bg-secondary padding-section py-12 md:pt-16 pb-10 w-full">
      <div className=" max-width-section grid gap-9   w-full">
        <div className="grid gap-y-10 md:gap-y-12">
          <div>
            <Logo noFill={true} />
            <div className=" flex gap-4 mt-4 items-center ">
              {socialIcon.map((Icon, i) => (
                <a key={i} href={Icon.href} target="_blank" rel="noreferrer">
                  <div className="w-8 h-8 grid place-items-center bg-white rounded-full">
                    <Icon.Icon className="svg" />
                  </div>
                </a>
              ))}
            </div>
          </div>

          <div className="grid grid-cols-2 gap-8 lg:gap-12 md:flex">
            {footerLinks?.map((footer, index) => (
              <div key={index}>
                <h4 className="text-[#E9D7FE]">{footer?.header}</h4>
                <div className="grid gap-y-4 mt-4">
                  {footer?.link?.map((link, index) => (
                    <Link to={link?.to} key={index}>
                      <p className="text-sm text-[#C9C9C9E5]">{link?.title}</p>
                    </Link>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="text-center  w-full pt-3 border-t text-[#E9D7FE]  border-white text-sm">
          © 2024 Madina. All Rights Reserved.
        </div>
      </div>
    </div>
  );
};
