import axios from 'axios';
import { GET_PRODUCTS_BY_VENDORS_CATEGORIES } from 'network/config/endpoints';
import QUERY_KEYS from 'network/config/queryKeys';
import React, { useEffect, useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { getProductsByVendorsandCategories } from '../../network/services/goods';

export default function useProductsByVendorsCategoriesHook() {
  const [searchParams] = useSearchParams();
  const LIMIT = 20;
  const [seller, setSeller] = useState(null);
  const [subCategories, setSubCategories] = useState([]); // State for subcategories

  const category = searchParams.get('category');
  const vendor = searchParams.get('vendor');

  const {
    isFetching: productsByVendorsisFetching,
    data: productsByVendorsData,
    hasNextPage: productsByVendorsHasNext,
    fetchNextPage: productsByVendorsFetchNext
  } = useInfiniteQuery({
    queryKey: [QUERY_KEYS.PRODUCT_BY_VENDORS_CATEGORIES, { categoryId: category, vendorId: vendor }],
    enabled: category != null && vendor != null ? !!category && !!vendor : true,
    queryFn: ({ pageParam = 0 }) =>
      getProductsByVendorsandCategories({ categoryId: category, vendorId: vendor, page: pageParam, size: LIMIT }),
    getNextPageParam: (lastPage, allPages) => ((lastPage ?? []).length === LIMIT ? allPages.length : undefined)
  });

  const baseUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    if (category && vendor) {
      axios
        .get(
          `${baseUrl}${GET_PRODUCTS_BY_VENDORS_CATEGORIES}/?vendorId=${vendor}&categoryId=${category}&page=${0}&size=${6}`
        )
        .then(response => {
          const sellerData = response.data.seller;
          const subCategoriesData = response.data.subCategories; // Adjust according to API response structure
          setSeller(sellerData);
          setSubCategories(subCategoriesData); // Set subcategories
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    }
  }, [category, vendor, baseUrl]);

  const fetchMoreProductsByVendors = React.useCallback(() => {
    if (productsByVendorsHasNext) {
      productsByVendorsFetchNext();
    }
  }, [productsByVendorsFetchNext, productsByVendorsHasNext]);

  return {
    productsByVendorsData,
    productsByVendorsisFetching,
    fetchMoreProductsByVendors,
    productsByVendorsHasNext,
    seller, // return the seller data
    subCategories // return the subcategories data
  };
}
