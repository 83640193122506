import { styled } from '@mui/material';
import { COLORS } from '../theme/color';
import { breakpoints, maxWidth } from '../theme/theme';

export const Container = styled('div')`
  width: 100vw;
  background-color: #f8f7f7;
  position: relative;
  padding: 60px 2rem 0;
  @media (max-width: ${breakpoints.md}px) {
    padding: 30px 4.5vw 0;
  }
`;

export const MainGridCon = styled('div')`
  width: 100%;
  display: flex;
  max-width: ${maxWidth}px;
  justify-content: space-between;
  gap: 55px;
  margin: 0 auto;
  @media (max-width: ${breakpoints.md}px) {
    display: block;
  }
`;

export const CategoriesLeft = styled('div')`
  @media (max-width: ${breakpoints.md}px) {
    // display: none;
  }
  a {
    min-width: 280px;
  }
  a.active,
  active:hover {
    background-color: ${COLORS.MainBlue};
    color: #fff;
    font-weight: 500;
  }
`;

export const AccountLeft = styled('div')`
  @media (max-width: ${breakpoints.md}px) {
    display: none;
  }
  a {
    height: 40px;
    min-width: 50px;
  }
  a.active {
    background-color: ${COLORS.SecBlue};
    color: ${COLORS.MainBlue};
    font-weight: 500;
    position: relative;
  }
  a.active:after {
    content: '';
    width: 4px;
    border-radius: 4px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: ${COLORS.MainBlue};
  }
`;

export const Right = styled('div')`
  width: 100%;
  min-width: 0;
`;
