import { CartProduct } from 'components/molecules/Goods/utils';
import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { STORE_KEYS } from '../keys';
const { persistAtom } = recoilPersist();

export type CartItem = {
  id: number;
  productId?: number;
  name: string;
  productName?: string;
  photo?: string | string[];
  amount: number;
  price?: number;
  cartId?: number;
  quantity: number;
  shortDSC?: string;
  category?: string;
  productImage1?: string;
  images?: string[];
  quantityAvailable: number;
  minimumOrderQuantity?: number;
  sellerId: number;
};
export type CartOrders = {
  id: number;
  customer: {
    id?: string;
    name: string;
  };
  orderRef: string;
  cartSize: number;
  totalAmount: number;
  shippingFee: number;
  orderStatus: string;
  size: string;
  paymentStatus: string;
  orderReturned: boolean;
  products: CartProduct[];
  totalItems: number;
  vendorId: number;
  vendorName?: string;
  vendorLogo: string;
  vendorAddress: any;
};

export const isCartOpen = atom<{ onCartOpen: boolean }>({
  key: STORE_KEYS.IS_CART_OPEN,
  default: {
    onCartOpen: false
  }
});

export interface CartState {
  [key: string]: CartItem[];
}

export const cartListAtom = atom<CartState>({
  key: STORE_KEYS.CART_LIST,
  default: {}
  // effects_UNSTABLE: [persistAtom]
});

export interface ServerCartState {
  [key: string]: CartOrders[];
}

export const serverCartListAtom = atom<ServerCartState>({
  key: STORE_KEYS.SERVER_CART_LIST,
  default: {},
  effects_UNSTABLE: [persistAtom]
});
