import { Scrollable } from 'components/atoms';
import { parseInt } from 'lodash';
import { getProductsBySubCategory } from 'network/services/goods';
import { enqueueSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import { useParams, useSearchParams } from 'react-router-dom';
import { CategorySection } from './styles';

const LIMIT = 20;
const ProductBySubCategory: React.FC = () => {
  const [params] = useSearchParams();
  const [page, setpage] = useState(params.get('page') ?? '0');
  const { sub_category } = useParams();
  const subCategoryId = parseInt(`${sub_category}`.split('-').pop() ?? '1');
  const { isFetching, data, hasNextPage, fetchNextPage } = useInfiniteQuery({
    queryKey: ['productsBySubCategory', { subCategoryId, page }],
    queryFn: ({ pageParam = page }) => getProductsBySubCategory({ subCategoryId, page: pageParam, size: LIMIT }),
    getNextPageParam: (lastPage, allPages) => ((lastPage.data ?? []).length === LIMIT ? allPages.length : undefined),
    onError: error =>
      enqueueSnackbar(`${(error as any).response?.data?.message}`, {
        variant: 'error'
      })
  });
  const totalpages = Number(data?.pages[0].totalPages ?? 1) - 1;
  const words = sub_category?.split('-');
  words?.pop();
  const subCategory = words?.join(' ');

  return (
    <div>
      <Scrollable onScrollBottom={fetchNextPage} callWhen={hasNextPage}>
        <CategorySection>
          {/* my mark */}
          <h1>{subCategory}</h1>
          {/* <ProductsGrid
                        skeletonCount={LIMIT}
                        emptyMessage="Unfortunately, there are no items in this category."
                        isLoading={isFetching}
                        products={data?.pages.map(page => page.data).flat() ?? []}
                    /> */}
          <div className="w-full flex justify-between mt-2">
            <span
              data-hidden={Number(page) === 0}
              className="capitalize text-blue-700 hover:underline cursor-pointer text-lg data-[hidden=true]:opacity-0 data-[hidden=true]:pointer-events-none"
              onClick={() => {
                setpage(curr => String(Number(curr) - 1));
              }}>
              Previous
            </span>
            <span
              data-hidden={Number(page) === totalpages}
              className="capitalize text-blue-700 hover:underline cursor-pointer text-lg data-[hidden=true]:opacity-0 data-[hidden=true]:pointer-events-none"
              onClick={() => {
                setpage(curr => String(Number(curr) + 1));
              }}>
              Next
            </span>
          </div>
        </CategorySection>
      </Scrollable>
    </div>
  );
};

export default ProductBySubCategory;
