import { Grid } from '@mui/material';
import { Button, Divider, Spacer } from 'components/atoms';
import TransactionInProgressModal from 'components/atoms/a-transaction-in-progress-modal';
import { NewUseUpdatedCart, useUpdatedCart } from 'components/molecules/Cart/hooks';
import MadinaPointsInput from 'components/molecules/m-madina-points-input';
import CheckoutDetails from 'components/organisms/o-checkout-details';
import SEARCH_PARAMS from 'constants/searchParams';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { buyNowPayLaterStepsAtom } from 'store/atoms/buyNowPayLaterAtom';
import { cartListAtom } from 'store/atoms/cartAtom';
import { checkoutDetailsAtom } from 'store/atoms/checkoutAtom';
import checkoutVariablesAtom from 'store/atoms/checkoutVariables';
import modalAtom from 'store/atoms/modals';
import { userIsLoggedIn, userRoleAtom } from 'store/atoms/userAtom';
import { LoanSummary, PaymentModal, RecurringDelivery, UsePointsModal } from './component';
import { BuyNowPayLater } from './component/BuyNowPayLater';
import { RejectPaymentModal } from './component/RejectPaymentModal';
import usePayment from './hooks';
import { CheckoutContainer, MainGridCon } from './style';

const CheckoutPage = () => {
  useUpdatedCart();
  const go = useNavigate();
  const { pathname } = useLocation();

  const { orderId } = useParams();
  const cartList = useRecoilValue(cartListAtom);
  const userRole = useRecoilValue(userRoleAtom);
  const isLogged = useRecoilValue(userIsLoggedIn);
  const [isPoint, setIsPoint] = React.useState(false);
  const modalState = useRecoilValue(modalAtom);
  const [businessPayOpen, setBusinessPayOpen] = React.useState(false);
  const buyNowPayLaterStep = useRecoilValue(buyNowPayLaterStepsAtom);
  const [checkoutDetails, setCheckoutDetails] = useRecoilState(checkoutDetailsAtom);
  const [checkoutVariables, setCheckoutVariables] = useRecoilState(checkoutVariablesAtom);

  const {
    onWalletSuccess,
    initiatePayment,
    onClose: paymentClose,
    onSuccess: checkoutSuccess,
    initiateBusinessPostPayment
  } = usePayment();

  const handleNavigateToSignin: any = (): void => {
    go(`/login?${SEARCH_PARAMS.LOGIN_SUCCESS_REROUTE}=${pathname}`, {
      state: { from: pathname }
    });
  };

  const serverCart = NewUseUpdatedCart()?.data;
  const cartOrder = serverCart?.orders?.find(order => order?.vendorId === Number(orderId));

  React.useEffect(() => {
    if (checkoutDetails.deliveryMethod === 'homedelivery' && checkoutVariables.selectedCarrier?.total !== undefined) {
      setCheckoutDetails(prev => ({
        ...prev,
        shippingFee: checkoutVariables.selectedCarrier?.total ?? 0
      }));
    } else {
      setCheckoutDetails(prev => ({ ...prev, shippingFee: 0 }));
    }
  }, [checkoutDetails.deliveryMethod, checkoutVariables.selectedCarrier, setCheckoutDetails]);

  React.useEffect(() => {
    if (!orderId) {
      go('/');
    }
  }, [cartList, go]);

  return (
    <div>
      <Spacer height={30} />
      {buyNowPayLaterStep === 4 ? (
        <LoanSummary />
      ) : (
        <MainGridCon container gap={0.25}>
          <Grid item xs={12} md={7.25} lg={8}>
            <CheckoutContainer>
              <h3>Checkout</h3>
              <Divider />
              <h2>Review details & Confirm order</h2>
              <p className="top">Review the address, payment method and tips 😉 to complete your purchase</p>
              <CheckoutDetails.Delivery />
              <CheckoutDetails.Contact />
              <CheckoutDetails.Logistics />

              <MadinaPointsInput
                onChange={points =>
                  setCheckoutVariables(curr => ({
                    ...curr,
                    pointsUsed: points
                  }))
                }
              />
              <RecurringDelivery />
              <Button
                isLoading={checkoutVariables.checkoutLoading}
                onClick={() => {
                  if (isLogged) {
                    if (userRole === 'BUSINESS') {
                      const hasInvalidQuantity = cartOrder?.products?.some(
                        product => product.quantity < product.minimumOrderQuantity
                      );

                      if (hasInvalidQuantity) {
                        // Optionally show an error message or notification
                        enqueueSnackbar(
                          'Some products have quantities less than their minimum order quantities. View cart'
                        );
                        return; // Prevent checkout
                      }

                      setBusinessPayOpen(true); // Proceed if all quantities are valid
                    } else {
                      initiatePayment(); // For non-business users
                    }
                  } else {
                    handleNavigateToSignin();
                  }
                }}>
                Proceed To Make Payment
              </Button>

              <Spacer height={20} />
            </CheckoutContainer>
          </Grid>

          <CheckoutDetails.Summary />
        </MainGridCon>
      )}
      <CheckoutDetails.BusinessPaymentModal
        opened={businessPayOpen}
        initiatePayment={initiatePayment}
        onClose={() => setBusinessPayOpen(false)}
        initiateBusinessPostPayment={initiateBusinessPostPayment}
      />

      <Spacer height={100} />

      {modalState.paymentModalOpen && (
        <PaymentModal
          onWalletSuccess={onWalletSuccess}
          onSuccess={checkoutSuccess}
          onClose={paymentClose}
          amount={checkoutDetails.grandTotal}
        />
      )}

      {isPoint && <UsePointsModal showPaymentModal={initiatePayment} setIsPoint={setIsPoint} />}

      {modalState.paymentFailureModalOpen && <RejectPaymentModal />}
      <BuyNowPayLater />
      <TransactionInProgressModal />
    </div>
  );
};

export default CheckoutPage;
