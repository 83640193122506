const QUERY_KEYS = {
  CART: 'cart',
  BVN_CHECK: 'checkdbvn',
  VENDORS: 'vendors',
  ALL_LOANS: 'allLoans',
  ALL_ORDERS: 'allOrders',
  CATEGORIES: 'categories',
  CUSTOMER_CARDS: 'mycards',
  VERIFIED_BVN: 'verifiedBVN',
  CHAT_HISTORY: 'chatHistory',
  ALL_PRODUCTS: 'allProducts',
  SINGLE_ORDER: 'singleOrder',
  REFFERAL_LINK: 'refferalLink',
  REFFERAL_TOTAL: 'referralTotal',
  ORDER_TRACKING: 'orderTracking',
  ONGOING_ORDERS: 'ongoingOrders',
  RECURRING_ORDERS: 'recurringOrders',
  WALLET_BALANCE: 'walletBalance',
  AI_CHAT_THREADS: 'aiChatThreads',
  VENDOR_PRODUCTS: 'vendorProducts',
  COMPLETE_ORDERS: 'completeOrdres',
  REFFERAL_REWARD: 'refferalReward',
  CANCELED_ORDERS: 'canceledOrders',
  CUSTOMER_PROFILE: 'customerProfile',
  PRODUCTS_BY_SEARCH: 'productsBySearch',
  CUSTOMER_ADDRESSES: 'customerAddresses',
  PRODUCT_CATEGORIES: 'productCategories',
  FAVOURITE_PRODUCTS: 'favouriteProducts',
  PRODUCT_BY_CATEGORIES: 'productCategories',
  PRODUCT_BY_VENDORS_CATEGORIES: 'productVendorsCategories',
  VENDORS_BY_CATEGORIES: 'vendorsCategories',
  SUBSCRIPTION_HISTORY: 'subscriptionHistory',
  SUBSCRIPTION_PACKAGES: 'subscriptionPackages',
  PRODUCTS_BY_SUBCATEGORY: 'productBySubcategory',
  POINTS_NAIRA_EQUIVALENT: 'pointsNairaEquivalent',
  CUSTOMER_BNPL_LOAN_STATUS: 'customerBNPLLoanStatus',
  SHIPBUBBLE_CATEGORIES: 'shipBubbleCategories',
  SHIPBUBBLE_DIMENSIONS: 'shipBubbleDimensions'
} as const;

export default QUERY_KEYS;
