import { Alert, Radio as MantineRadio, Stack } from '@mantine/core';
import { CircularProgress, Radio, RadioGroup } from '@mui/material';
import { Spacer } from 'components/atoms';
import { StyledFormLabel } from 'components/atoms/InputField/style';
import { NewUseUpdatedCart } from 'components/molecules/Cart/hooks';
import Fuse from 'fuse.js';
import { uniqBy } from 'lodash';
import QUERY_KEYS from 'network/config/queryKeys';
import { getAllAddress } from 'network/services/address';
import {
  getAllCarriers,
  getDeliveryRates,
  getShipBubbleCategories,
  getShipBubbleDimensions,
  validateAddressDeliveryCallback
} from 'network/services/order';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { IoInformationCircleSharp } from 'react-icons/io5';
import { useMutation, useQuery } from 'react-query';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { checkoutDetailsAtom } from 'store/atoms/checkoutAtom';
import checkoutVariablesAtom from 'store/atoms/checkoutVariables';
import { contactState } from 'store/atoms/contactAtom';
import { profileAtom } from 'store/atoms/profileAtom';

interface ShipBubbleCategory {
  category_id: string;
  category: string;
}
const DeliveryDetails = () => {
  const go = useNavigate();
  const { orderId } = useParams();
  const { pathname } = useLocation();
  const profile = useRecoilValue(profileAtom);
  const addressInfo = useRecoilValue(contactState);
  const serverCart = NewUseUpdatedCart()?.data;
  const cartOrder = serverCart?.orders?.find(order => order?.vendorId === Number(orderId));

  const [checkoutVariables, setCheckoutVariables] = useRecoilState(checkoutVariablesAtom);
  const [checkoutDetails, setCheckoutDetails] = useRecoilState(checkoutDetailsAtom);
  const { data: addressData, isLoading: addressLoading } = useQuery({
    queryFn: getAllAddress,
    queryKey: QUERY_KEYS.CUSTOMER_ADDRESSES
  });

  function calculateTotalWeight(cartOrder: any) {
    return cartOrder.reduce((acc: number, product: { quantity: number; size: number }) => {
      const productWeight = product.quantity * Number(product.size);
      return acc + productWeight;
    }, 0);
  }

  // Function to get the best-fit package dimensions based on total weight
  function getPackageDimensions(totalWeight: number, dimensions: any[]) {
    // Sort dimensions by size in ascending order
    const sortedDimensions = dimensions?.sort((a, b) => a.size - b.size);

    // Find the smallest box that can fit the totalWeight
    const suitableBox = sortedDimensions?.find(box => box.size >= totalWeight);

    // Return the suitable box if found, otherwise the largest box available
    return suitableBox || sortedDimensions[sortedDimensions.length - 1];
  }
  const { data: shipBubbleCategories, isLoading: shipBubbleCategoriesLoading } = useQuery({
    queryFn: getShipBubbleCategories,
    queryKey: QUERY_KEYS.SHIPBUBBLE_CATEGORIES
  });
  const { data: shipBubbleDimensions, isLoading: shipBubbleDimensionsLoading } = useQuery({
    queryFn: getShipBubbleDimensions,
    queryKey: QUERY_KEYS.SHIPBUBBLE_DIMENSIONS
  });

  const fuse = React.useMemo(() => {
    return shipBubbleCategories
      ? new Fuse<ShipBubbleCategory>(shipBubbleCategories, {
          keys: ['category'],
          threshold: 0.3 // Adjust threshold for strictness of match
        })
      : null;
  }, [shipBubbleCategories]);

  // Use useMemo to run getPackageForOrder only when dependencies are defined
  const packageDimension = React.useMemo(() => {
    if (shipBubbleDimensions && cartOrder?.products) {
      const totalWeight = calculateTotalWeight(cartOrder.products);
      return getPackageDimensions(totalWeight, shipBubbleDimensions);
    }
    return null; // Return null if dependencies are not ready
  }, [shipBubbleDimensions, cartOrder]);

  const {
    mutateAsync: asyncGetAllCarriers,
    data: carriersData,
    isLoading: carriersLoading
  } = useMutation({ mutationFn: getAllCarriers });

  const {
    mutateAsync: asyncGetDeliveryRates,
    data: deliveryRatesData,
    isLoading: deliveryRatesLoading
  } = useMutation(getDeliveryRates, {
    onSuccess: data => {
      // Set the request_token immediately after receiving the response
      const requestToken = data?.data?.data?.request_token;
      if (requestToken) {
        setCheckoutVariables(c => ({ ...c, request_token: requestToken }));
      }
    },
    onError: error => {
      console.error('Failed to get delivery rates:', error);
      enqueueSnackbar('Error fetching delivery rates', { variant: 'error' });
    }
  });

  const carriers: any[] = uniqBy(
    deliveryRatesData !== undefined ? deliveryRatesData.data?.data?.couriers : [],
    c => c.courier_id
  );

  const { mutateAsync: validateAddress, isLoading: validatingAddress } = useMutation({
    mutationFn: validateAddressDeliveryCallback
  });

  const defaultAddress: any = React.useMemo(() => {
    const addresses = addressData?.data ? addressData.data : [];
    const defAddr = addresses.find(item => item.status === true);
    if (!defAddr && addresses.length > 0) {
      return addresses[0];
    }
    return defAddr;
  }, [addressData]);

  const handleGetCarriers = React.useCallback(async () => {
    // Get today's date
    const today = new Date();

    // Format the date to YYYY-MM-DD
    const formattedDate = today.toISOString().split('T')[0];
    if (profile?.email === undefined) {
      enqueueSnackbar('Please login to continue', { variant: 'error' });
      go('/login', { state: { from: pathname } });
    } else {
      if (!defaultAddress && !addressLoading) {
        enqueueSnackbar('No default address found: Please set a default delivery address', { variant: 'error' });
        return;
      }
      setCheckoutDetails(c => ({ ...c, addressId: defaultAddress?.id }));

      if (!shipBubbleCategories || !defaultAddress) {
        return;
      }
      // Match the vendorCategory with specific shipBubbleCategories
      let mostSimilarCategory: ShipBubbleCategory | null = null;
      if (cartOrder?.vendorCategory === 'Supermarket') {
        mostSimilarCategory =
          shipBubbleCategories?.find((cat: { category: string }) => cat.category === 'Groceries') || null;
      } else if (cartOrder?.vendorCategory === 'Home and Office') {
        mostSimilarCategory =
          shipBubbleCategories?.find((cat: { category: string }) => cat.category === 'Electronics and gadgets') || null;
      } else if (fuse && cartOrder?.vendorCategory) {
        // Use Fuse.js search for other cases
        const searchResult = fuse.search(cartOrder.vendorCategory);
        mostSimilarCategory = searchResult.length > 0 ? searchResult[0].item : null;
      }

      if (Object.keys(serverCart?.orders!).length === 0) {
        enqueueSnackbar('Please add items to cart', { variant: 'error' });
        return;
      }

      try {
        asyncGetDeliveryRates({
          sender_address_code: cartOrder?.vendorAddress?.addressCode,
          reciever_address_code: defaultAddress?.addressCode ?? '',
          pickup_date: `${formattedDate}`,
          category_id: mostSimilarCategory?.category_id ?? '',
          package_items: cartOrder?.products
            ? cartOrder?.products.map(item => ({
                name: item.productName,
                description: `${item.description}`,
                unit_weight: `${item.size}`,
                unit_amount: `${item.price}`,
                quantity: `${item.quantity}`
              }))
            : [],
          service_type: 'pickup',
          delivery_instructions: 'this needs to be delivered',
          package_dimension: {
            length: packageDimension?.length ?? 12,
            width: packageDimension?.width ?? 30,
            height: packageDimension?.height ?? 11
          }
        });
      } catch (error) {
        enqueueSnackbar(`Error getting Carriers`, {
          variant: 'error'
        });
      }
    }
  }, [
    go,
    pathname,
    serverCart?.orders,
    cartOrder?.products,
    profile?.email,
    defaultAddress,
    addressLoading,
    validateAddress,
    shipBubbleCategories,
    shipBubbleDimensions,
    setCheckoutDetails,
    asyncGetAllCarriers,
    profile?.phoneNumber
  ]);

  React.useEffect(() => {
    if (checkoutDetails.deliveryMethod === 'homedelivery') {
      handleGetCarriers();
    }
  }, [checkoutDetails.deliveryMethod, handleGetCarriers, addressInfo]);

  return (
    <>
      <p className="sub-head">Delivery Method</p>

      <RadioGroup
        row
        value={checkoutDetails.deliveryMethod}
        onChange={e =>
          setCheckoutDetails(curr => ({
            ...curr,
            deliveryMethod: e.currentTarget.value as any
          }))
        }>
        <StyledFormLabel
          value="homedelivery"
          control={<Radio />}
          label="Home Delivery"
          checked={checkoutDetails.deliveryMethod === 'homedelivery'}
        />

        <Spacer width={34} />

        <StyledFormLabel
          value="pickup"
          control={<Radio />}
          label="Pick up"
          checked={checkoutDetails.deliveryMethod === 'pickup'}
        />
      </RadioGroup>

      {checkoutDetails.deliveryMethod === 'homedelivery' && (
        <>
          {defaultAddress && (
            <p>
              {defaultAddress.street}, {defaultAddress.state}, {defaultAddress.country}
            </p>
          )}
          <MantineRadio.Group
            value={checkoutVariables.selectedCarrier?.courier_id}
            onChange={courierId =>
              setCheckoutVariables(curr => ({
                ...curr,
                selectedCarrier: carriers.filter(c => c.courier_id === courierId).pop()
              }))
            }
            pl="lg"
            name="preferredCarrier"
            label="Select your preferred carrier">
            {!defaultAddress && (
              <p className="bg-primary/5 p-4 border border-primary/30 mt-2 rounded-md !text-primary">
                Default address not found,
                <Link to="/account/address" className="font-semibold hover:underline">
                  click here
                </Link>{' '}
                to set a default address.
              </p>
            )}
            <Stack pl="lg" mt="sm">
              {deliveryRatesLoading || validatingAddress ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                carriers.map((c, i) => (
                  <MantineRadio
                    classNames={{ label: 'w-full', labelWrapper: 'w-full' }}
                    w="100%"
                    color="#2F4DC4"
                    checked={checkoutVariables.selectedCarrier?.courier_id.toString() === c.courier_id.toString()}
                    value={c.courier_id}
                    label={
                      <div className="grid font-medium grid-cols-10 content-center w-full">
                        <img
                          src={c.courier_image ?? '/images/chowdeck.webp'}
                          alt={c.courier_name}
                          className="rounded-full w-5 h-5"
                        />
                        <span className="col-span-5 whitespace-nowrap text-ellipsis overflow-hidden">
                          {c.courier_name}
                        </span>
                        <span className="col-span-4">₦ {c.total.toFixed(2)}</span>
                      </div>
                    }
                    key={i}
                    variant="outline"
                  />
                ))
              )}
            </Stack>
          </MantineRadio.Group>
        </>
      )}

      {checkoutDetails.deliveryMethod === 'homedelivery' && checkoutVariables.selectedCarrier !== undefined && (
        <Alert
          className="my-5 rounded-lg"
          icon={<IoInformationCircleSharp size={24} />}
          color="#2F4DC4"
          title={`${
            checkoutVariables.selectedCarrier.delivery_eta ?? ''
          } Shipping Fee: ₦ ${checkoutVariables.selectedCarrier.total.toFixed(2)}`}
        />
      )}
    </>
  );
};

export default DeliveryDetails;
