import { BackgroundImage } from '@mantine/core';
import BlobFlex from 'components/molecules/BlobFlex';
import BlobGrid from 'components/molecules/BlobGrid';
import GetAnythingDelivered from 'components/molecules/GetAnythingDelivered';
import Reviews from 'components/molecules/Landingreviews/reviews';
import { Imager, Iphone13, Iphone13b, Iphone13c, mockup } from 'components/molecules/blobs';
import { OFFERS } from 'constants/landingPage/offers';
import QUERY_KEYS from 'network/config/queryKeys';
import { getAllCategories } from 'network/services/goods';
import { useEffect, useRef, useState } from 'react';
import { IoLogoApple } from 'react-icons/io5';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import BgImage from '../../assets/images/bg-images/landing-page.png';

const LandingPage = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { data: categories } = useQuery([QUERY_KEYS.CATEGORIES], () => getAllCategories());

  const categoriesRef = useRef<any>(null);
  const scrollToExploreStores = () => {
    if (categoriesRef.current) {
      categoriesRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <div className="w-screen overflow-hidden relative">
      <BackgroundImage src={BgImage}>
        <section
          className={` px-7 py-12 lg:py-36 text-white text-center font-semibold text-[20px] h-[calc(100vh-70px)] lg:w-full lg:min-h-80 grid content-center items-center  relative lg:pb-20 lg:px-20 `}>
          <h1 className=" lg:text-white text-3xl md:text-4xl lg:text-5xl lg:font-semibold lg:text-center leading-10 lg:leading-[67.2px] tracking-wide max-w-[800px] m-auto">
            One Stop Marketplace For All Your Needs: The Premier Family Haven
          </h1>
          <p className="lg:text-white md:text-2xl lg:text-3xl lg:mt-2 font-normal academy">
            Madina Lifestyle Supermarket: Nigeria's Premier Family Discount Haven
          </p>
          <div className="grid gap-4 lg:gap-2 md:grid-cols-2 m-auto max-w-[700px] mt-6 text-base md:text-lg lg:text-xl ">
            <Link
              className=" bg-white inline-flex items-center justify-center text-[#4D4D4D] py-3 font-medium px-6 rounded-lg lg:py-5"
              target="_blank"
              to="https://play.google.com/store/apps/details?id=com.chowdeck.com">
              <svg
                width="24"
                height="23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mr-2"
                viewBox="0 0 13 16">
                <path
                  d="m6.848 7.641-6.785 7.03.001.004A1.825 1.825 0 0 0 1.834 16c.339 0 .657-.09.93-.246l.022-.013 7.637-4.301L6.848 7.64Z"
                  fill="#EA4335"></path>
                <path
                  d="m13.713 6.444-.007-.004-3.297-1.866L6.694 7.8l3.728 3.638 3.28-1.847c.575-.303.965-.894.965-1.577 0-.677-.385-1.266-.954-1.57Z"
                  fill="#FBBC04"></path>
                <path
                  d="M.062 1.33c-.04.146-.062.3-.062.46v12.42c0 .16.021.315.062.46l7.02-6.849L.061 1.33Z"
                  fill="#4285F4"></path>
                <path
                  d="m6.898 8 3.512-3.427L2.78.256A1.871 1.871 0 0 0 1.834 0C.986 0 .27.563.063 1.327v.002L6.897 8Z"
                  fill="#34A853"></path>
              </svg>
              Download on Google Play
            </Link>
            <Link
              className=" bg-white inline-flex items-center justify-center text-[#4D4D4D] py-3 font-medium px-6 rounded-lg mb-4 xs:mx-0 mx-4 md:mb-0 lg:py-5"
              target="_blank"
              to="https://play.google.com/store/apps/details?id=com.chowdeck.com">
              <IoLogoApple className="mr-2 text-3xl text-black" />
              Download on App Store
            </Link>
          </div>
          {/* <LandingPageLocationInput /> */}
        </section>
      </BackgroundImage>

      <section ref={categoriesRef}>
        {windowWidth >= 768 ? <BlobFlex categories={categories ?? []} /> : <BlobGrid categories={categories ?? []} />}
      </section>
      {/* <img src="/wave.svg" alt="wave" className="w-screen min-w-[1000px]" /> */}
      <section className="w-full lg:flex items-center lg:flex-col lg:items-center lg:w-full lg:text-3xl lg:font-semibold lg:mt-0 lg:px-[0%]">
        {/* <h1 className="lg:text-[30px] lg:font-semibold w-full mx-auto text-center text-[18px] font-bold mb-6">
          Find and Shop our Products
        </h1> */}
        {/* <div className="flex-col lg:w-full lg:flex lg:flex-row lg:justify-evenly lg:my-6 lg:gap-6">
          <section className="blobicon mb-4 lg:mb-0">
            <div className="rounded-t-[42%] rounded-r-[58%] rounded-b-[14%] rounded-l-[86%] rounded-tl-[67%] rounded-tr-[73%] rounded-br-[27%] rounded-bl-[33%]  bg-[#E4E6F2] lg:p-9 p-6 lg:w-[110px] lg:h-[110px] w-[30] h-[30]">
              <Image src={verifiedIcon} />
            </div>
            <span className="font-semibold text-[20px]">Fast Delivery</span>
            <p className="lg:text-lg font-normal lg:text-gray-500 lg:text-center">
              share your referral code and earn amazing rewards
            </p>
          </section>

          <section className="blobicon mb-4 lg:mb-0">
            <div className="rounded-t-[75%] rounded-r-[25%] rounded-b-[71%] rounded-l-[29%] rounded-tl-[43%] rounded-tr-[18%] rounded-br-[82%] rounded-bl-[57%]  bg-[#E4E6F2] lg:p-9 p-8 lg:w-[110px] lg:h-[110px]  w-[30] h-[30] grid place-content-center">
              <Image src={zapped} alt="zap" />
            </div>
            <span className="font-semibold text-[20px]">24/7 Customer Support</span>
            <p className="lg:text-lg lg:font-normal lg:text-gray-500 lg:text-center">
              share your referral code and earn amazing rewards
            </p>
          </section>
          <section className="blobicon mb-4 lg:mb-0">
            <div className="rounded-t-[42%] rounded-r-[58%] rounded-b-[14%] rounded-l-[86%] rounded-tl-[67%] rounded-tr-[73%] rounded-br-[27%] rounded-bl-[33%] bg-[#E4E6F2] lg:p-9 p-6 lg:w-[110px] lg:h-[110px] w-[30] h-[30]">
              <Image src={featured} />
            </div>
            <span className="font-semibold text-[20px]">14-Day's return policy</span>
            <p className="lg:text-lg font-normal text-gray-500 text-center">
              share your referral code and earn amazing rewards
            </p>
          </section>
        </div> */}
        {/* <div className="w-full flex justify-center">
          <Link
            to="/supermarket"
            className="text-[16px] text-center mx-auto text-white rounded-[40px] bg-blue-700 px-[7rem] py-2 mt-3">
            Explore stores 
          </Link>
        </div> */}
        {/* <LandingVendors /> */}
      </section>
      {/* <img src="/wave2.svg" alt="wave" className="w-screen min-w-[1000px] bg-primary mt-6" /> */}
      <section className="w-full bg-primary flex flex-col items-center   relative padding-section py-10">
        <h1 className="m-auto text-white text-xl md:text-2xl font-semibold mb-8">What we offer</h1>
        <div
          className={`grid grid-cols-2 lg:grid-cols-4 justify-between  md:flex lg:grid w-full max-w-[1380px] gap-10 `}>
          {OFFERS.map(offer => (
            <Link to={offer.href} key={offer.title} className="">
              <div className="flex flex-col gap-2  items-center">
                <div className="w-[80px] h-[80px] md:w-[120px] md:h-[120px] bg-white rounded-[50%] p-4 grid place-content-center">
                  <img src={offer.img} alt="bnpl" />
                </div>
                <span className="text-md text-white font-semibold mb-3">{offer.title}</span>
              </div>
              <p className="text-center max-w-[90%] m-auto md:max-w-full  text-white ">{offer.description}</p>
            </Link>
          ))}
        </div>
        {/* <div className="custom-shape-divider-bottom-1707333217">
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path
              d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
              className="shape-fill"></path>
          </svg>
        </div> */}
      </section>
      <section className="w-full  flex flex-col items-center   relative padding-section py-14 md:py-20 xl:py-24">
        <GetAnythingDelivered exploreclick={scrollToExploreStores} />
      </section>

      {/* <BestPrice /> */}
      <section className="bg-[#F04438] min-h-[200px] lg:min-h-[400px] lg:pl-20 flex px-4 lg:pr-0 py-4 md:py-0 rounded-sm  ">
        <div className=" flex-1 lg:flex-1 lg:my-auto flex items-center gap-2 ">
          <div className="h-[100%] flex flex-col gap-3 px-1 justify-center">
            <h1 className="text-white text-3xl md:text-4xl lg:text-5xl font-bold">
              Enjoy the best prices and deals on madina
            </h1>
            <span className="text-white text-xl lg:text-2xl">Get up to 10% discount on your orders</span>
            {/* <div className="flex flex-col w-full justify-start gap-3 mt-4 md:flex-row">
              <img src={Applelogo} alt="applelogo" className="w-[70%] md:w-48" />
              <img src={Playlogo} alt="playlogo" className="w-[70%] md:w-48" />
              <Image src={Playlogo} />
            </div> */}
          </div>
        </div>
        <div className="lg:flex-[25%] flex relative overflow-hidden">
          <>
            <Imager src={Iphone13} width={450} height={450} className="absolute top-0 left-[20%] lg:block hidden" />
            <Imager
              src={Iphone13c}
              width={400}
              height={450}
              className="absolute bottom-[-20%] right-[0%] lg:block hidden"
            />
            <Imager src={Iphone13b} width={450} height={400} className="absolute top-0 left-[53%] lg:block hidden" />
          </>
          <Imager src={mockup} className="lg:hidden block" />
        </div>
      </section>
      <section className="border-none outline-none padding-section py-14 md:py-20 xl:py-24 ">
        <div className="max-width-section">
          <Reviews />
        </div>
      </section>

      {/* <SupportSection>
        <div className="content">
          <SupportSectionCard>
            <div className="card-icon">
              <SupportCartIcon />
            </div>
            <p className="title">
              <span>How to shop</span>
              <RightPointedArrow />
            </p>
            <p className="desc">Your guide to shopping and placing orders.</p>
          </SupportSectionCard>
          <SupportSectionCard>
            <div className="card-icon">
              <SupportHelpIcon />
            </div>
            <p className="title">
              <span>FAQ</span>
              <RightPointedArrow />
            </p>
            <p className="desc">Your questions answered.</p>
          </SupportSectionCard>
          <SupportSectionCard>
            <div className="card-icon">
              <SupportCartIcon />
              <Image src={HelpIcon} />
            </div>
            <p className="title">
              <span>Need help?</span>
              <RightPointedArrow />
            </p>
            <p className="desc">Contact our customer support team.</p>
          </SupportSectionCard>
        </div>
      </SupportSection> */}
      {/* <div className="fixed bottom-10 right-4 z-[1000]">
        <Link
          to={ASSISTED_SHOPPING}
          title="Shop with Madina-AI"
          className=" bg-white px-4 text-black items-center rounded-3xl flex gap-1 lg:gap-2 p-2 text-sm">
          <Image src={asistedshoping} /> Assisted Shopping
        </Link>
      </div> */}
    </div>
  );
};

export default LandingPage;
