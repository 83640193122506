import { Empty, Scrollable, Spacer } from 'components/atoms';
import { CatergoriesCON } from 'pages/GoodsByCategories/style';
import useGoodsByCategoriesHook from 'pages/GoodsByCategories/useGoodsByCategoriesHook';
import { ProductSkeletons, ProductsGrid } from 'pages/Products';
import React from 'react';
import { SupermarketGoods } from '../Goods/SupermarketGoods';

const ProductCategories = () => {
  const [emptySubcategories, setEmptySubcategories] = React.useState<number[]>([]);
  const { superMarketSubCategories, fetchMoreCategoryProducts, categoryProductsData, categoryProductsFetching } =
    useGoodsByCategoriesHook();
  const validSubCategories = React.useMemo(() => {
    return superMarketSubCategories.filter(({ id }: { id: number }) => !emptySubcategories.includes(id));
  }, [emptySubcategories, superMarketSubCategories]);

  const products = categoryProductsData?.pages.flat() ?? [];
  return (
    <CatergoriesCON>
      {validSubCategories.length > 0 &&
        validSubCategories.map(({ name, id }: any) => (
          <div key={id}>
            <SupermarketGoods
              subCategoryId={id}
              subCategory={name}
              onEmptySubcategory={val => setEmptySubcategories(c => [...c, val])}
            />
            <Spacer height={'3.5rem'} />
          </div>
        ))}
      {(products ?? []).length > 0 && validSubCategories.length < 1 && (
        <Scrollable onScrollBottom={fetchMoreCategoryProducts}>
          <ProductsGrid
            products={products ?? []}
            diva={products ?? []}
            isLoading={categoryProductsFetching}
            emptyMessage="No products in this category"
          />
        </Scrollable>
      )}
      {validSubCategories.length < 1 && (products ?? []).length < 1 && !categoryProductsFetching && (
        <Empty message="Oops! There are no products available in this category at the moment. Please check back later or explore other categories." />
      )}
      {categoryProductsFetching && (
        <div className="grid bg-white rounded-md grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          <ProductSkeletons count={12} />
        </div>
      )}
      <Spacer height={'2.5rem'} />
    </CatergoriesCON>
  );
};

export default ProductCategories;
