import {
  blob,
  fashion as fashionImg,
  health as healthImg,
  home as homeImg,
  supermarket as supermarketImg
} from 'components/molecules/blobs';
import { Link } from 'react-router-dom';
import { Category } from 'TYPES/api.body';

interface Props {
  categories: Category[];
}

const makeSlug = (name: string) => name.toLowerCase().split(' ').join('-');

const BlobGrid: React.FC<Props> = ({ categories }) => {
  const fashion = categories.filter(item => item.name.toLowerCase().includes('fashion')).pop();
  const food = categories.filter(item => item.name.toLowerCase().includes('food')).pop();
  const home = categories.filter(item => item.name.toLowerCase().includes('home')).pop();
  const health = categories.filter(item => item.name.toLowerCase().includes('health')).pop();
  const supermarket = categories.filter(item => item.name.toLowerCase().includes('supermarket')).pop();

  return (
    <div className="py-10 padding-section">
      <div className="flex justify-between px-3">
        <div className="flex flex-col items-center">
          <Link
            className="flex flex-col items-center"
            to={food !== undefined ? `/categories/supermarket/${makeSlug(food.name)}?categoryId=${food?.id}` : '#'}>
            <div className="blobsm blob1 mb-2 grid place-content-center hover:border-violet-800">
              <img src={blob} alt="blob" width={70} height={70} />
            </div>
            <span className="text-[#4D4D4D]">Food</span>
          </Link>
        </div>
        <div className="flex flex-col items-center ">
          <Link
            className="flex flex-col items-center"
            to={
              fashion !== undefined
                ? `/categories/supermarket/${makeSlug(fashion.name)}?categoryId=${fashion?.id}`
                : '#'
            }>
            <div className="blobsm blob2 mb-2 grid place-content-center hover:border-blue-600">
              <img src={fashionImg} alt="blob" width={70} height={70} />
            </div>
            <span className="text-[#4D4D4D]">Fashion</span>
          </Link>
        </div>
      </div>
      <div className="flex justify-center items-center">
        <div className="flex flex-col items-center">
          <Link
            className="flex flex-col items-center"
            to={
              supermarket !== undefined
                ? `/categories/supermarket/${makeSlug(supermarket.name)}?category=${supermarket?.id}`
                : '#'
            }>
            <div className="blobsm blob3 mb-2 grid place-content-center hover:border-amber-800">
              <img src={supermarketImg} alt="blob" width={70} height={70} />
            </div>
            <span className="text-[#4D4D4D]">Supermarket</span>
          </Link>
        </div>
      </div>
      <div className="flex justify-between">
        <div className="flex flex-col items-center">
          <Link
            className="flex flex-col items-center"
            to={home !== undefined ? `/categories/supermarket/${makeSlug(home.name)}?categoryId=${home?.id}` : '#'}>
            <div className="blobsm blob4 mb-2 grid place-content-center  hover:border-orange-600">
              <img src={homeImg} alt="blob" width={70} height={70} />
            </div>
            <span className="text-[#4D4D4D]">Home & Office</span>
          </Link>
        </div>
        <div className="flex flex-col items-center">
          <Link
            className="flex flex-col items-center"
            to={
              health !== undefined ? `/categories/supermarket/${makeSlug(health.name)}?categoryId=${health?.id}` : '#'
            }>
            <div className="blobsm blob5 mb-2 grid place-content-center hover:border-green-400">
              <img src={healthImg} alt="blob" width={150} height={150} />
            </div>
            <span className="text-[#4D4D4D]">Health & Beauty</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BlobGrid;
