import { Modal } from '@mantine/core';
import { InfoIcon } from 'assets/Icons';
import { Button, InputField, Spacer } from 'components/atoms';
import { initiateBVNLookup } from 'network/services/verifyIdentity';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import React, { useState } from 'react';
import { IoClose } from 'react-icons/io5';
import { useMutation } from 'react-query';
import { useRecoilState, useSetRecoilState } from 'recoil';
import bvnVerificationAtom from 'store/atoms/bvnVerificationAtom';
import { bvnLookupAtom } from 'store/atoms/verifyIdentityAtom';
import { checkFormData, validators } from 'utils/validator';
import { DetailsModalCon, InputCon } from './styles';

const INITIALSTATE = {
  bvn: ''
  // phone: ''
};

export const ConnectBVNModal: React.FC = () => {
  const [formData, setFormData] = useState(INITIALSTATE);
  const [formError, setFormError] = useState(INITIALSTATE);
  const [bvnVerification, setBVNState] = useRecoilState(bvnVerificationAtom);
  const setBvnLookup = useSetRecoilState(bvnLookupAtom);

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: initiateBVNLookup,
    onSuccess: data => {
      if (data.message !== 'Sorry, invalid BVN provided. Please check and retry.') {
        enqueueSnackbar({
          message: (
            <div>
              <h4 className="font-semibold text-xl">BVN verifications initiated</h4>
              <ul>
                {data.data.methods
                  ? data.data.methods
                      .filter(method => method.method === 'phone')
                      .map(method => (
                        <li key={method.hint} className="bg-green-600/50 p-2 rounded hey">
                          <p className="font-semibold text-lg">{method.method}</p>
                          <p>{method.hint}</p>
                        </li>
                      ))
                  : ''}
              </ul>
            </div>
          ),
          persist: true,
          action: (
            <button
              className="p-0.5 border border-red-500 rounded-full absolute top-2 right-2 text-red-500"
              onClick={() => closeSnackbar()}>
              <IoClose />
            </button>
          ),
          variant: 'success'
        });
        setBvnLookup(data.data);
        setBVNState({ step: 'confirm' });
      } else {
        enqueueSnackbar({ message: `${data.message}`, variant: 'error' });
        return;
      }
    },
    onError: error => {
      enqueueSnackbar({ message: 'Invalid bvn. Please check and try again', variant: 'error' });
      setBVNState(undefined);
   
    }
  });

  const handleUpdateFormData = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    const error = validators[name](value);

    setFormData(formData => ({ ...formData, [name]: value }));
    setFormError(formError => ({ ...formError, [name]: error }));
  };

  const handleVerify = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const isValid = checkFormData(formData, setFormError);
    if (!isValid) {
      enqueueSnackbar('Failed to Proceed; Please update BVN and try again');
      return;
    }
    mutateAsync({ bvn: formData.bvn });
  };

  return (
    <Modal
      size={500}
      centered
      radius="lg"
      opened={bvnVerification?.step === 'lookup'}
      onClose={() => setBVNState(undefined)}>
      <DetailsModalCon className="p-5">
        <div className="header">
          <h3>Connect BVN</h3>
          <Spacer height="15px" />
        </div>

        <div>
          <InputCon>
            <span>BVN</span>
            <InputField
              name="bvn"
              placeholder="Enter your BVN"
              onChange={handleUpdateFormData}
              value={formData.bvn}
              required={true}
              max={11}
              error={formError.bvn}
            />
          </InputCon>

          <Spacer height="30px" />

          <div className="info">
            <InfoIcon className="icon" />
            <p className="text">
              To enable you use the BNPL, you are required to connect your BVN to Madina. This does not give madina
              access to your bank information or balances. This just enables us to confirm your identity from your bank
            </p>
          </div>

          <Spacer height="30px" />

          <Button
            type="submit"
            isLoading={isLoading}
            onClick={handleVerify}
            disabled={isLoading || formData.bvn.length !== 11}>
            Verify
          </Button>
          <Spacer height="20px" />
        </div>
      </DetailsModalCon>
    </Modal>
  );
};
