import Avvvatars from 'avvvatars-react';
import { GoldStars } from 'components/atoms';
import { Png } from '../../../assets/Png';

type ReviewItemType = {
  id: number;
  author: string;
  title: string;
  desc: string;
  stars: number;
};

const ReviewItems: ReviewItemType[] = [
  {
    id: 1,
    author: 'Uchenna Ogbonnaya',
    title: 'I’d rate them a 5/5!',
    stars: 5,
    desc: 'With Madina Lifestyle, I’ve been able to buy simple groceries, or toiletries just with a click. I must say, their service is top-notch, and I will 10/10 be using this app again.'
  },
  {
    id: 2,
    author: 'Maria Olawale',
    title: 'Great Customer Service, Great Items',
    stars: 4,
    desc: 'Everything I ordered from this website came intact without any complaints on my end. I enjoyed every step of the process, from breezing the catalogues to ordering and receiving. Their customer service system is great.'
  },
  {
    id: 3,
    author: 'Caleb Ogonor',
    title: 'I rate this brand 100%',
    stars: 4,
    desc: 'I’ve done some online shopping in the past, but with this website, nothing lags, and you can track your order in real time. It’s been such a lifesaver for me.'
  }
];

export const Reviews = () => {
  const { ReviewUser } = Png;
  return (
    <div className="grid justify-center w-full  ">
      <h1 className="text-[#22B872] text-[20px] font-semibold text-center">Reviews</h1>
      <p className="title lg:font-semibold lg:text-2xl text-center">We care about your concerns</p>
      <div className="grid md:grid-cols-2 gap-12 lg:grid-cols-3 mt-10 md:mt-16">
        {ReviewItems?.map((review, index) => {
          return (
            <div key={index} className="grid justify-between  border border-[#E2E2E2] p-6 shadow rounded-[0.8px]">
              <div className="flex items-center gap-3">
                <Avvvatars value={review?.author} />
                <p className="font-medium">{review?.author}</p>
              </div>
              <p className=" text-[#3D3D3D] mt-4  text-sm">{review?.desc}</p>
              <div className="grid items-end mt-4">
                <GoldStars stars={review.stars} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Reviews;
